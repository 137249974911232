import { Action, createAction, PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  ActionPattern,
  actionChannel,
  take,
  race,
  flush,
  cancel,
  fork,
  ForkEffect,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";
import {
  BrandsImagesPayload,
  CmsContentPayload,
  ContactInfoPayload,
  CountryOptions,
  GetMenuPayload,
  InstrumentsItem,
  LandingPagePayload,
  LensesColumn,
  LensesColumnEssilor,
  MapUrlPayload,
  PublicCmsContentPayload,
  ShowErrorPayload,
  StarDoorsPerBrand,
  ThirdLevelMenuPayload,
  LandingPageContentsPayload,
} from "./storeInterfaces";
import storeService from "./storeService";
import {
  sliceName,
  saveContent,
  savePageLayout,
  showError,
  saveMenu,
  saveThirdLevelMenu,
  saveStoreConfiguration,
  savePublicStoreConfiguration,
  selectPublicSupportedLanguages,
  selectSupportedLanguages,
  saveRedirectPublicLocale,
  saveRedirectPrivateLocale,
  saveBrandList,
  CMS_ROUTES,
  saveFaq,
  saveNews,
  saveSingleNews,
  saveCountries,
  saveCurrentLang,
  selectCurrentLang,
  saveBrandImages,
  saveOrderedBrandList,
  saveLensesMenu,
  setLoadingSingleNews,
  saveGetLensesMenuStatus,
  saveSearchTermAssociation,
  toggleSapDecouplingPopup,
  saveUrlWithToken,
  saveContactInfo,
  saveRewardsUrlWithToken,
  saveGetMenuStatus,
  saveStarDoorsPerBrand,
  saveLandingPageStatus,
  saveStarDoorsPerBrandStatus,
  setLoadingHp,
  selectUrl360Members,
  selectUrlRewards,
  selectUrlLensSimulator,
  saveUrlLensSimulator,
  saveNavigationTabStatus,
  saveLensesEssilorMenu,
  saveGetInstrumentsMenuStatus,
  saveInstrumentsMenu,
  saveTutorialPills,
  saveTooltipContent,
  savePopupContent,
} from "./storeSlice";
import {
  selectLocale,
  setLanguageId,
  selectIsLogged,
  selectStoreIdentifier,
  selectIsBackOfficeUser,
  selectIsStarsMultidoor,
  selectIsMultidoor,
} from "../user/userSlice";
import { logout } from "../user/userSagas";
import { parseUrlInfo } from "../../routing/routesUtils";
import { initializeGrid } from "../../utils/gridUtils";
import { getDefaultLocale, getSupportedLang, isSetAsLanguage } from "../../utils/languageUtils";
import {
  facetNameForEachMenu,
  GogglesHelmetsType,
  MenuStructureKeys,
} from "../../components/layouts/header/navbar/categories-menu/category-item/menuStructure";
import { cloneDeep, slice } from "lodash";
import { FirstLevelMenu } from "../../interfaces/menuInterfaces";
import { getUrlWithFilters, mapContactInfo } from "../../utils/menuUtils";
import {
  addPriceToProductorVariant,
  mapAttachments,
  mapPriceResponse,
} from "../../utils/catalogueUtils";
import {
  enrichModelAndVariantInfo,
  mapPopup,
  mapTooltip,
  mapTutorialPills,
} from "../../utils/cmsUtils";
import catalogueService from "../catalogue/catalogueService";
import { GetAttachmentsVariantPayload, PDPCmsContents } from "../catalogue/catalogueInterface";
import { getCourses } from "../leonardo/leonardoSagas";
import {
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
} from "../catalogue/catalogueSlice";
import { CallbackPayload } from "../../interfaces/mainInterfaces";
import { Row } from "../../interfaces/gridInterfaces";
// import contactInfoMockData from "./mock/contact_info_mock";

/* ACTIONS */
export const mapUrl = createAction<MapUrlPayload>(sliceName + "/mapUrl");
export const getCmsContent = createAction<CmsContentPayload>(sliceName + "/getCmsContent");
export const getCmsContentTooltip = createAction<CmsContentPayload>(
  sliceName + "/getCmsContentTooltip"
);
export const getCmsContentPopup = createAction<CmsContentPayload>(
  sliceName + "/getCmsContentPopup"
);
export const getLandingPageContent = createAction<LandingPagePayload>(
  sliceName + "/getLandingPageContent"
);
export const getStoreConfiguration = createAction(sliceName + "/storeConfiguration");
export const getPublicCmsContent = createAction<PublicCmsContentPayload>(
  sliceName + "/getPublicCmsContent"
);
export const getMenu = createAction<GetMenuPayload>(sliceName + "/getMenu");
export const getThirdLevelMenu = createAction<ThirdLevelMenuPayload>(
  sliceName + "/getThirdLevelMenu"
);
export const getContactInfo = createAction<ContactInfoPayload>(sliceName + "/getContactInfo");
export const getBrandGroups = createAction(sliceName + "/getBrandGroups");
export const getLensesSubMenu = createAction(sliceName + "/getLenses");
export const getLensesEssilorSubMenu = createAction(sliceName + "/getLensesEssilor");
export const getInstrumentsSubMenu = createAction(sliceName + "/getInstruments");
export const updatePublicLanguage = createAction(sliceName + "/updatePublicLanguage");
export const updatePrivateLanguage = createAction(sliceName + "/updatePrivateLanguage");
export const handleError = createAction<any>(sliceName + "/handleError");
export const showErrorPopup = createAction<ShowErrorPayload>(sliceName + "/showError");
export const getFaq = createAction(sliceName + "/faq");
export const getNews = createAction(sliceName + "/news");
export const getSingleNews = createAction<string>(sliceName + "/singleNews");
export const getCountries = createAction(sliceName + "/getCountries");
export const getBrandImages = createAction<BrandsImagesPayload>(sliceName + "/getBrandImages");
export const getSearchTermAssociation = createAction(sliceName + "/getSearchTermAssociation");
export const getUrlWithTokenEl360 = createAction<CallbackPayload | null>(
  sliceName + "/getUrlWithTokenEl360"
);
export const getUrlWithTokenRewards = createAction<CallbackPayload | null>(
  sliceName + "/getUrlWithTokenRewards"
);
export const getUrlLensSimulator = createAction<CallbackPayload | null>(
  sliceName + "/getUrlLensSimulator"
);

//landing page
export const getLandingPage = createAction<LandingPageContentsPayload>(
  sliceName + "/getLandingPage"
);
// import mockMenu from "../../MOCK-MENU.json";

export const getNavigationTab = createAction<Row[]>(sliceName + "/getNavigationTab");

export const redirectToEssilorPro = createAction<CallbackPayload>(
  sliceName + "/redirectToEssilorPro"
);

export const getEyemed = createAction<CallbackPayload>(sliceName + "/getEyemed");

export const getStarDoorsForBrand = createAction(sliceName + "/getStarDoorsForBrand");
export const getTutorialPills = createAction(sliceName + "/getTutorialPills");

/* SAGAS */

export interface GenericChannelWatcher {
  actionPattern: ActionPattern<Action<any>>;
  saga: (...args: any[]) => SagaIterator;
  cancelActionPattern: ActionPattern<Action<any>> | undefined;
  log?: boolean;
}

/**
 * Generic saga to open a channel for a specified actionPattern,
 * and trigger a saga each time it's dispatched.
 *
 * This way the saga is dispatched sequentially and not concurrently,
 * and no dispatch is lost.
 *
 * @export
 * @param {GenericChannelWatcher} {
 *   actionPattern,
 *   saga,
 * }
 * @return {*}  {SagaIterator}
 */
export function* genericChannelWatcher({
  actionPattern,
  saga,
  cancelActionPattern,
  log,
}: GenericChannelWatcher): SagaIterator {
  log && console.log(`[${String(actionPattern)}] opening channel...`);

  // 1- Create a channel to watch for actionPattern action and queue them
  const requestChan = yield actionChannel(actionPattern);

  while (true) {
    // 2- Pop request from the channel
    const { payload } = yield take(requestChan);
    log && console.log(`[${String(actionPattern)}] found an action w/ payload:`, payload);

    // 3- Race both the task and it's cancellation, meaning we get the first one that resolves
    //    this way if a cancel action is dispatched, the task effect is also cancelled
    const { task, cancel } = yield race({
      task: call(saga, payload),
      ...(cancelActionPattern && { cancel: take(cancelActionPattern) }),
    });

    // if cancel wins, we need to also flush the channel to forget all queued actions
    if (cancel !== undefined) {
      yield flush(requestChan);
      log && console.log(`[${String(actionPattern)}] cancelled for:`, payload);
    } else log && console.log(`[${String(actionPattern)}] finished for:`, payload);
  }
}

/**
 * Custom saga helper that, for each dispatched action it captures, computes a key from its payload,
 * and ensures that no two tasks with the same key are running at the same time.
 * This is helpful to avoid making the same request for a resource multiple times while the previous one is still running,
 * while still being able to have concurrent requests for different resources.
 *
 * In practice:
 * 1) Each time an actionPattern is dispatched, a key is computed from the instance's payload by using the provided getUniqueKey.
 * 2) If an action with the same corresponding key is found to be running, the new action is ignored.
 * 3) For all other actions, the provided saga is forked with the new payload.
 *
 *
 * @export
 * @interface TakeOneForEachKeyArg
 * @template T
 */
export interface TakeOneForEachKeyArg<T> {
  actionPattern: ActionPattern<Action<T>>;
  saga: (...args: any[]) => SagaIterator;
  getUniqueKey: (actionPayload: T) => string;
  log?: boolean;
}

export function takeOneForEachKey<T>({
  actionPattern,
  saga,
  getUniqueKey,
  log,
}: TakeOneForEachKeyArg<T>): ForkEffect<never> {
  return fork(function* () {
    const tasks: { [key: string]: any } = {}; // object to track the sagas running for each unique key

    while (true) {
      const action: any = yield take(actionPattern); // intercept each action dispatched for the given pattern
      const key = getUniqueKey(action.payload); // compute the key from the action's payload

      log && console.log("Current tasks with uniqueKeys", tasks);

      // if a task with the same key exists and its running, cancel it
      if (tasks[key] && tasks[key].isRunning()) {
        // yield cancel(tasks[key]);
        log && console.log("Task with key:", key, "already running. Ignoring this request.");
      } else {
        // fork a new effect by running the provided saga with the intercepted action
        tasks[key] = yield fork(saga, action);
        log && console.log("Forking new task with key:", key, "and payload", action.payload);
      }
    }
  });
}

/**
 * Custom saga helper that, for each dispatched action it captures, computes a key from its payload,
 * and behaves like TakeLatest if it finds a task already running with the same key, otherwise like TakeEvery.
 *
 * Therefore multiple instances of the provided saga can be run concurrently each time an action is dispatched,
 * but only if the key computed from its payload is unique.
 *
 *
 * In practice:
 * 1) Each time an actionPattern is dispatched, a key is computed from the instance's payload by using the provided getUniqueKey.
 * 2) If an actions with the same corresponding key is found to be running, it's canceled.
 * 3) For all actions, the provided saga is forked with the new payload.
 *
 *
 * @export
 * @interface TakeEveryUnlessSameKeyArg
 * @template T
 */
export interface TakeEveryUnlessSameKeyArg<T> {
  actionPattern: ActionPattern<Action<T>>;
  saga: (...args: any[]) => SagaIterator;
  getUniqueKey: (actionPayload: T) => string;
  log?: boolean;
}

export function takeEveryUnlessSameKey<T>({
  actionPattern,
  saga,
  getUniqueKey,
  log,
}: TakeEveryUnlessSameKeyArg<T>): ForkEffect<never> {
  return fork(function* () {
    const tasks: { [key: string]: any } = {}; // object to track the sagas running for each unique key

    while (true) {
      const action: any = yield take(actionPattern); // intercept each action dispatched for the given pattern
      const key = getUniqueKey(action.payload); // compute the key from the action's payload

      log && console.log("Current tasks with uniqueKeys", tasks);

      // if a task with the same key exists and its running, cancel it
      if (tasks[key] && tasks[key].isRunning()) {
        yield cancel(tasks[key]);
        log && console.log("Cancelling previous task with key:", key);
      }

      // fork a new effect by running the provided saga with the intercepted action
      tasks[key] = yield fork(saga, action);
      log && console.log("Forking new task with key:", key, "and payload", action.payload);
    }
  });
}

/**
 * Handle Api Error, such as 401
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* handleErrorSaga(action: PayloadAction<any>): SagaIterator {
  const error = action.payload;

  if (error.response && error.response.status) {
    const isLogged = yield select(selectIsLogged);
    switch (error.response.status) {
      case 401:
        if (isLogged) yield put(logout({}));
        break;
      case 400:
        if (error.response?.data?.message === "Session Error" && isLogged) yield put(logout({}));
        break;
      case 503:
        if (error.response?.data?.errors?.[0]?.subCode === "0001") {
          const errors = error.response?.data?.errors?.[0];
          yield put(toggleSapDecouplingPopup({ open: true, ...errors }));
        }

        break;
      default:
        // yield put(showError(error));
        console.error("[MYL ERROR]", error);
    }
  }
}

/**
 * Show error popup
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* showErrorPopupSaga(action: PayloadAction<ShowErrorPayload>): SagaIterator {
  if (action.payload.status) {
    switch (action.payload.status) {
      case 401:
        break;
      default:
        yield put(
          showError({
            message: action.payload.message,
            showButton: action.payload.showButton,
            descriptionButton: action.payload.descriptionButton,
          })
        );
    }
  }
}

/**
 * Get private cms contents for contentId
 * @param  {PayloadAction<CmsItem>} action
 */
function* getCmsContentSaga(action: PayloadAction<CmsContentPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getCmsContent, action.payload);

    const item = cloneDeep(data.data); //map object if some product or variant is present in the cms content

    if (item.result && Array.isArray(item.result)) {
      const totalPriceIds: string[] = [];

      //if CMS content has product or variant, map the data and get array of all ids to get the price
      item.result = item.result.map((oldSingleContent: any) => {
        const { singleContent, priceIds } = enrichModelAndVariantInfo(oldSingleContent);

        totalPriceIds.push(...priceIds);

        //else save as-is
        return singleContent;
      });

      yield put(
        saveContent({
          item,
          id: action.payload.id,
          ...(action.payload.brand ? { brand: action.payload.brand } : {}),
        })
      );

      //if there are products (or variants), get prices and resave content

      if (totalPriceIds.length > 0) {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const newItem = cloneDeep(item);
          const { data } = yield call(catalogueService.getPriceService, totalPriceIds);
          const priceData = mapPriceResponse(data.data); // map response into a GetPriceResult[] array

          newItem.result = item.result.map((singleContent: any) => {
            const { model, modelColor } = singleContent;
            if (model) {
              const newModel = { ...model };
              return { ...singleContent, model: addPriceToProductorVariant(newModel, priceData) };
            }
            if (modelColor) {
              const newModelColor = { ...modelColor };
              return {
                ...singleContent,
                modelColor: addPriceToProductorVariant(newModelColor, priceData),
              };
            }
            return singleContent;
          });

          yield put(
            saveContent({
              item: newItem,
              id: action.payload.id,
              ...(action.payload.brand ? { brand: action.payload.brand } : {}),
            })
          );
        }
        return;
      }
    } else if (item) {
      const enrichObj = enrichModelAndVariantInfo(item);
      let singleContent = enrichObj.singleContent;
      const priceIds = enrichObj.priceIds;

      yield put(
        saveContent({
          item: singleContent,
          id: action.payload.id,
          ...(action.payload.brand ? { brand: action.payload.brand } : {}),
        })
      );

      // get images for special component
      if (
        item.layoutVariant?.[0]?.name?.includes("Lp-editorial-product-tile") &&
        singleContent.modelColor
      ) {
        const modelColor = singleContent.modelColor;
        if (modelColor.uniqueID) {
          const payload: GetAttachmentsVariantPayload = {
            variantId: modelColor.uniqueID,
            type: "PHOTO",
            attachments: "attachments",
          };
          const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
          const imagesCarousel = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);
          const newModelColor = { ...singleContent.modelColor };
          newModelColor.attachments = imagesCarousel;

          singleContent = {
            ...singleContent,
            modelColor: newModelColor,
          };
        }
      }

      if (priceIds.length > 0) {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const { data } = yield call(catalogueService.getPriceService, priceIds);
          const priceData = mapPriceResponse(data.data); // map response into a GetPriceResult[] array

          const { model, modelColor } = singleContent;
          if (model) {
            const newModel = { ...model };
            singleContent = {
              ...singleContent,
              model: addPriceToProductorVariant(newModel, priceData),
            };
          }
          if (modelColor) {
            const newModelColor = { ...modelColor };
            singleContent = {
              ...singleContent,
              modelColor: addPriceToProductorVariant(newModelColor, priceData),
            };
          }

          yield put(
            saveContent({
              item: singleContent,
              id: action.payload.id,
              ...(action.payload.brand ? { brand: action.payload.brand } : {}),
            })
          );
        }
      }
      return;
    }

    yield put(
      saveContent({
        item,
        id: action.payload.id,
        ...(action.payload.brand ? { brand: action.payload.brand } : {}),
      })
    );
  } catch (error) {
    yield put(handleError(error));
    yield put(
      saveContent({
        item: {},
        id: action.payload.id,
      })
    );
  }
}

/**
 * Get private cms contents for tutorial pills tooltip
 * @param  {PayloadAction<CmsContentPayload>} action
 */
function* getCmsContentTooltipSaga(action: PayloadAction<CmsContentPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getCmsContent, action.payload);

    const item = cloneDeep(data.data); //map object
    if (item) {
      const content = mapTooltip(item);
      if (content)
        yield put(
          saveTooltipContent({
            item: content,
            id: action.payload.id,
          })
        );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get private cms contents for contentId
 * @param  {PayloadAction<CmsContentPayload>} action
 */
function* getCmsContentPopupSaga(action: PayloadAction<CmsContentPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getCmsContent, action.payload);
    const item = cloneDeep(data.data); //map object
    if (item) {
      const content = mapPopup(item);
      if (content)
        yield put(
          savePopupContent({
            item: content,
            id: action.payload.id,
          })
        );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getLandingPageContentSaga(action: PayloadAction<LandingPagePayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getLandingPageContent, action.payload.id);
    const item = cloneDeep(data.data); //map object if some product or variant is present in the cms content

    if (item.result && Array.isArray(item.result)) {
      const totalPriceIds: string[] = [];

      //if CMS content has product or variant, map the data and get array of all ids to get the price
      item.result = item.result.map((oldSingleContent: any) => {
        const { singleContent, priceIds } = enrichModelAndVariantInfo(oldSingleContent);

        totalPriceIds.push(...priceIds);

        //else save as-is
        return singleContent;
      });

      yield put(
        saveContent({
          item,
          id: action.payload.id,
        })
      );

      //if there are products (or variants), get prices and resave content
      if (totalPriceIds.length > 0) {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          const newItem = cloneDeep(item);
          const { data } = yield call(catalogueService.getPriceService, totalPriceIds);
          const priceData = mapPriceResponse(data.data); // map response into a GetPriceResult[] array

          newItem.result = item.result.map((singleContent: any) => {
            const { model, modelColor } = singleContent;
            if (model) {
              const newModel = { ...model };
              return { ...singleContent, model: addPriceToProductorVariant(newModel, priceData) };
            }
            if (modelColor) {
              const newModelColor = { ...modelColor };
              return {
                ...singleContent,
                modelColor: addPriceToProductorVariant(newModelColor, priceData),
              };
            }
            return singleContent;
          });

          yield put(
            saveContent({
              item: newItem,
              id: action.payload.id,
            })
          );
        }
        return;
      }
    } else if (item) {
      const enrichObj = enrichModelAndVariantInfo(item);
      let singleContent = enrichObj.singleContent;
      const priceIds = enrichObj.priceIds;

      yield put(
        saveContent({
          item: singleContent,
          id: action.payload.id,
        })
      );

      //get model for special component: ONLY MODELS
      if (
        item.layoutVariant?.[0]?.name?.includes("LP-title-text-vertical") &&
        singleContent.linkTarget &&
        singleContent.linkTarget.length > 0
      ) {
        const newPriceIds: string[] = []; //array to get prices
        const newLinkTarget = singleContent.linkTarget.map((_: any) => {
          const { singleContent, priceIds } = enrichModelAndVariantInfo(_);

          newPriceIds.push(...priceIds);
          return singleContent;
        });

        let newLinkTargetWithPrice = newLinkTarget;
        if (newPriceIds.length > 0) {
          const isMultidoor = yield select(selectIsMultidoor);
          let hasPricePrivilege = false;
          if (isMultidoor) {
            hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
          } else {
            hasPricePrivilege = yield select(selectHasPricePrivilege);
          }
          if (hasPricePrivilege) {
            try {
              const { data } = yield call(catalogueService.getPriceService, newPriceIds);
              const priceData = mapPriceResponse(data.data);

              newLinkTargetWithPrice = newLinkTarget.map((_: any) => {
                const newModel = { ..._.model };
                return {
                  ..._,
                  model: addPriceToProductorVariant(newModel, priceData),
                };
              });
            } catch (error) {
              yield put(handleError(error));
            }
          }
        }
        const newSingleContent = { ...singleContent, linkTarget: newLinkTargetWithPrice };

        yield put(
          saveContent({
            item: newSingleContent,
            id: action.payload.id,
          })
        );
        return;
      }

      // get images for special component
      if (
        item.layoutVariant?.[0]?.name?.includes("Lp-editorial-product-tile") &&
        singleContent.modelColor
      ) {
        const modelColor = singleContent.modelColor;
        if (modelColor.uniqueID) {
          const payload: GetAttachmentsVariantPayload = {
            variantId: modelColor.uniqueID,
            type: "PHOTO",
            attachments: "attachments",
          };

          try {
            const { data } = yield call(catalogueService.getAttachmentsVariant, payload);
            const imagesCarousel = mapAttachments(data?.data?.catalogEntryView?.[0]?.attachments);
            const newModelColor = { ...singleContent.modelColor };
            newModelColor.attachments = imagesCarousel;

            singleContent = {
              ...singleContent,
              modelColor: newModelColor,
            };
            yield put(
              saveContent({
                item: singleContent,
                id: action.payload.id,
              })
            );
          } catch (error) {
            yield put(handleError(error));
          }
        }
      }

      if (priceIds.length > 0) {
        const isMultidoor = yield select(selectIsMultidoor);
        let hasPricePrivilege = false;
        if (isMultidoor) {
          hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
        } else {
          hasPricePrivilege = yield select(selectHasPricePrivilege);
        }

        if (hasPricePrivilege) {
          try {
            const { data } = yield call(catalogueService.getPriceService, priceIds);
            const priceData = mapPriceResponse(data.data); // map response into a GetPriceResult[] array

            const { model, modelColor } = singleContent;
            if (model) {
              const newModel = { ...model };
              singleContent = {
                ...singleContent,
                model: addPriceToProductorVariant(newModel, priceData),
              };
            }
            if (modelColor) {
              const newModelColor = { ...modelColor };
              singleContent = {
                ...singleContent,
                modelColor: addPriceToProductorVariant(newModelColor, priceData),
              };
            }
            yield put(
              saveContent({
                item: singleContent,
                id: action.payload.id,
              })
            );
          } catch (error) {
            yield put(handleError(error));
          }
        }
        return;
      }
    }

    yield put(
      saveContent({
        item,
        id: action.payload.id,
      })
    );
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get public cms contents for contentId
 * @param  {PayloadAction<CmsItem>} action
 */
function* getPublicCmsContentSaga(action: PayloadAction<PublicCmsContentPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getPublicCmsContent, action.payload.id);
    yield put(saveContent({ item: data.data, id: action.payload.id }));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Seo url mapper
 * Get page info from url and make api call to cms to get layout and contents
 */
function* urlMapperSaga(action: PayloadAction<MapUrlPayload>): SagaIterator {
  //EXTRACT FROM URL
  const siteInfo = parseUrlInfo();
  const pageType = siteInfo.pageType ? siteInfo.pageType : "";
  if (pageType === "homepage") {
    yield put(setLoadingHp("LOADING"));
  }
  let getPageLayoutApi = storeService.getPageLayout;
  const isPublicPage = pageType === CMS_ROUTES.LOGIN_PAGE || pageType === CMS_ROUTES.NEED_HELP;
  //Make cms api call for public content page Login
  if (isPublicPage) {
    getPageLayoutApi = storeService.getPublicPageLayout;
  }

  let pageUrl = pageType;
  switch (pageType) {
    case CMS_ROUTES.NEED_HELP:
      pageUrl = "needhelp";
      break;
  }

  //Get page layout
  try {
    const response = yield call(getPageLayoutApi, { page: pageUrl });

    if (response && response.data) {
      // const rows = response.data.data.grid.rows;
      const pageLayout = initializeGrid(pageType, response.data.data);
      yield put(savePageLayout(pageLayout));

      //Get contents for each item
      const rows = pageLayout.rows ? pageLayout.rows : [];
      for (let i = 0; i < rows.length; i++) {
        const items = rows[i].cols ?? [];

        if (items) {
          for (let j = 0; j < items.length; j++) {
            try {
              const id = items[j].id;

              //--- for PUBLIC PAGES: they have different urls
              if (isPublicPage) {
                /* --------------------------------
                  Don't make api call for specific contents of public pages (Login, Need Help, Forgot Password).
                  For these contents all the info are on frontend. Doesn't need to call the bff.
                  ---------------------------------
                */
                if (rows[i].name === "login-mask" && id) {
                  yield put(saveContent({ item: items[j], id }));
                } else {
                  yield put(getPublicCmsContent({ id: id }));
                }
              } else {
                if (rows[i].name === "best-sellers") break; // do nothing, API call is handled inside component

                if (rows[i].name === "university") {
                  break; //do nothing, API call is handled inside the component
                } else {
                  items[j].name === "Promotions - Default"
                    ? yield put(getCmsContent({ id: id, fallback: true }))
                    : yield put(getCmsContent({ id: id }));
                }
              }
            } catch (err) {
              return err;
            }
          }
        }
      }
    }
  } catch (error) {
    if (pageType && pageType === "homepage") {
      yield put(setLoadingHp("ERROR"));
    }
    yield put(handleError(error));
  }
}

/**
 * Get store configuration, such as supported languages and currencies and store features.
 * @return {*}  {SagaIterator}
 */
function* getStoreConfigurationSaga(): SagaIterator {
  try {
    const storeIdentifier = yield select(selectStoreIdentifier);
    const { data } = yield call(storeService.getStoreConfiguration, storeIdentifier);
    yield put(saveStoreConfiguration(data.data));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get first and second level menu contents
 * @return {*}  {SagaIterator}
 */
function* getMenuSaga(action: PayloadAction<GetMenuPayload>): SagaIterator {
  try {
    yield put(saveGetMenuStatus("LOADING"));
    yield put(saveMenu([]));
    const { data } = yield call(storeService.getMenu, action.payload);
    const menus: FirstLevelMenu[] = cloneDeep(data.data.catalogGroupView ?? []);

    menus.forEach((menu) => {
      if (menu.identifier === "PRODUCTS") {
        menu.catalogGroupView?.forEach((category) => {
          // QUICK FIX FOR CRASH
          const identifier = category.identifier ?? category.filters?.[0].identifier;

          if (identifier === "Sunglasses Kids") {
            category.identifier = "Sunglasses";
          }
          if (identifier === "Eyeglasses Kids") {
            category.identifier = "Eyeglasses";
          }
          if (identifier === "Goggles and Helmets Kids") {
            category.identifier = "GOGGLES_HELMETS";
          }

          if (!category.name && category.filters?.[0].value) {
            const name = category.filters?.[0].value;
            category.name = name;
          }

          //create url to use in menu
          category.completeUrl = getUrlWithFilters(category);
          category.completeUrlAdults = getUrlWithFilters(category, "adults");
          if (
            category.categoryIdentifier === "FRAMES" ||
            category.categoryIdentifier === "GOGGLES_HELMETS"
          )
            category.completeUrlKids = getUrlWithFilters(category, "kids");
        });
      }
    });

    yield put(saveMenu(menus));
    yield put(saveGetMenuStatus("SUCCESS"));
  } catch (error) {
    yield put(saveGetMenuStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 *
 * Get third level menu contents
 * @param  {PayloadAction<string>} action unique category id
 * @return {*}  {SagaIterator}
 */
function* getThirdLevelMenuSaga(action: PayloadAction<ThirdLevelMenuPayload>): SagaIterator {
  try {
    const identifier = action.payload.identifier;
    const facetNamesForCategory =
      facetNameForEachMenu[identifier.toUpperCase() as MenuStructureKeys];

    if (identifier.toUpperCase() === "GOGGLES_HELMETS") {
      const types: GogglesHelmetsType[] = ["G"];

      if (action.payload.canRenderPrivileges.AFA_SECTION) {
        types.push("H");
      }

      for (let i = 0; i < types.length; i++) {
        const type = types[i];

        const filteredFacetNames = facetNamesForCategory.filter((filter) => {
          if (filter?.type?.includes(type)) return true;
          return false;
        });

        const facetNameArray = filteredFacetNames.map((facet) => facet.facetName);

        const queryParams = {
          facetName: facetNameArray,
          starsRequired: [action.payload.starsRequired?.toString() ?? "false"],
        };

        const facets = {
          ...action.payload.params,
        };

        facets["G_H_TYPE"] = [type];

        const { data } = yield call(
          storeService.getThirdLevelMenu,
          action.payload.id,
          queryParams,
          facets
        );

        const facetsViews = data.data?.facetsViewsDTO?.facetView;

        yield put(
          saveThirdLevelMenu({
            customId: action.payload.customId,
            content: facetsViews,
            identifier,
            type: type,
          })
        );
      }
    } else {
      const facetNameArray = facetNamesForCategory.map((facet) => facet.facetName);

      const queryParams = {
        facetName: facetNameArray,
        starsRequired: [action.payload.starsRequired?.toString() ?? "false"],
      };

      const facets = {
        ...action.payload.params,
      };
      const { data } = yield call(
        storeService.getThirdLevelMenu,
        action.payload.id,
        queryParams,
        facets
      );

      const facetsViews = data.data?.facetsViewsDTO?.facetView;

      yield put(
        saveThirdLevelMenu({
          customId: action.payload.customId,
          content: facetsViews,
          identifier,
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getBrandGroupsSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getBrandList);
    yield put(saveBrandList(data.data));
    if (data.data) {
      yield put(saveOrderedBrandList(data.data));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getLensesSubMenuSaga(): SagaIterator {
  try {
    yield put(saveGetLensesMenuStatus("LOADING"));
    const { data } = yield call(storeService.getLensList);

    const row = data.data.contentsDTO?.result?.[0].grid?.rows;
    const menu: LensesColumn[] = [];
    for (let i = 0; i < row.length; i++) {
      const placements = row[i].placements;

      for (let j = 0; j < placements.length; j++) {
        const id = placements[j]?.items?.[0]?.id;

        if (id) {
          const { data } = yield call(storeService.getCmsContent, { id });

          const { collectionTitle, result } = data?.data;
          const singleCol: LensesColumn = {
            collectionTitle,
            links: [],
          };

          result.forEach((singleRow: any) => {
            singleCol.links.push({
              id: singleRow.id,
              title: singleRow.title,
              url: singleRow.segment,
            });
          });

          menu.push(singleCol);
        }
      }
    }

    yield put(saveLensesMenu(menu));
    yield put(saveGetLensesMenuStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveLensesMenu([]));
    yield put(saveGetLensesMenuStatus("ERROR"));
  }
}

function* getLensesEssilorSubMenuSaga(): SagaIterator {
  try {
    yield put(saveGetLensesMenuStatus("LOADING"));
    const { data } = yield call(storeService.getLensList);
    const menu: LensesColumnEssilor[] = [];
    const subcategories = data.data.contentsDTO[0].subCategories;

    for (let i = 0; i < subcategories.length; i++) {
      const subcategory = subcategories[i];
      const singleCol: LensesColumnEssilor = {
        name: subcategory.name,
        identifier: subcategory.identifier,
        displayOrder: (subcategory.displayOrder as number) + 1,
        items: [],
      };

      subcategory.items.forEach((singleItem: any) => {
        singleCol.items.push({
          id: singleItem.identifier,
          name: singleItem.name,
          type: singleItem.type,
          additionalInfo: singleItem.additionalInfo ?? [],
          linkString: singleItem.linkString,
          displayOrder: singleItem.displayOrder,
        });
      });

      singleCol.items.sort((a, b) => a.displayOrder - b.displayOrder);

      menu.push(singleCol);
    }

    yield put(saveLensesEssilorMenu(menu));
    yield put(saveGetLensesMenuStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveLensesEssilorMenu([]));
    yield put(saveGetLensesMenuStatus("ERROR"));
  }
}

function* getInstrumentsSubMenuSaga(): SagaIterator {
  try {
    yield put(saveGetInstrumentsMenuStatus("LOADING"));
    const { data } = yield call(storeService.getInstrumentsList);

    const items = data.data.facetsViewsDTO;
    const menu: InstrumentsItem[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      menu.push({ name: item.name, url: item.url });
    }
    yield put(saveInstrumentsMenu(menu));
    yield put(saveGetInstrumentsMenuStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveInstrumentsMenu([]));
    yield put(saveGetInstrumentsMenuStatus("ERROR"));
  }
}

/**
 * Update language in public pages
 * @return {*}  {SagaIterator}
 */
function* updatePublicLanguageSaga(): SagaIterator {
  let supportedLanguages = yield select(selectPublicSupportedLanguages);

  // get supportedLanguages from backend if not already available
  if (supportedLanguages.length == 0) {
    try {
      const { data } = yield call(storeService.getPublicStoreConfiguration);
      yield put(savePublicStoreConfiguration(data.data));
      supportedLanguages = data.data.supportedLanguages;
    } catch (error) {
      yield put(showError({ message: error?.response?.message }));

      // global defaultSupportedLanguages
      supportedLanguages = globalEnvVariables?.defaultSupportedLanguages
        ? globalEnvVariables?.defaultSupportedLanguages
        : [];
      yield put(
        savePublicStoreConfiguration({
          supportedLanguages: supportedLanguages,
        })
      );
    }
  }

  const site = parseUrlInfo();
  // check if site.locale is among supported languages
  const supportedLang = getSupportedLang(site.locale, supportedLanguages);
  const currentLang = yield select(selectCurrentLang);

  // OLD CHECK if (
  //   (site.locale && !isSetAsLanguage(site.locale)) ||
  //   !isSetLanguageSupported(supportedLanguages)
  // ) {
  // if the locale is not set as language, or the language set is not actually supported

  //se site.locale is supported
  if (supportedLang) {
    if (site.locale && !isSetAsLanguage(currentLang, site.locale)) {
      yield put(saveCurrentLang(supportedLang.locale));
    }
  } else {
    const newLocale = getDefaultLocale(supportedLanguages);

    yield put(saveCurrentLang(newLocale));

    // and save it to redirectPublicLocale in redux to enable page redirect
    yield put(saveRedirectPublicLocale(newLocale));
  }
}

/**
 * Update language in private pages
 * @return {*}  {SagaIterator}
 */
function* updatePrivateLanguageSaga(): SagaIterator {
  const supportedLanguages = yield select(selectSupportedLanguages);
  const currentLocale = yield select(selectLocale);

  const site = parseUrlInfo();
  // check if site.locale is among supported languages
  const supportedLang = getSupportedLang(site.locale, supportedLanguages);

  if (site.locale !== currentLocale) {
    // if the url's locale isn't the store's locale and it's supported

    if (supportedLang) {
      yield put(saveCurrentLang(supportedLang.locale));
      yield put(setLanguageId(supportedLang.languageId));
    } else {
      yield put(saveRedirectPrivateLocale(currentLocale));
    }
  } else {
    const currentLang = yield select(selectCurrentLang);
    if (!isSetAsLanguage(currentLang, currentLocale)) {
      yield put(saveCurrentLang(currentLocale));
    }
  }
}

function* getFaqSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getFaq);
    yield put(saveFaq(data.data.content.result));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get news for News Preview page
 * @return {*}  {SagaIterator}
 */

function* getNewsSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getNewsUrl);
    const result = data?.data?.content?.result;

    if (result && result.length > 0) {
      const newsObj: any = {};
      result.forEach((n: any) => {
        if (newsObj[n.title] && newsObj[n.title].length > 0) {
          newsObj[n.title].push(n);
        } else {
          newsObj[n.title] = [n];
        }
      });
      yield put(saveNews(newsObj));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get news for Single News page
 * @return {*}  {SagaIterator}
 */

function* getSingleNewsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoadingSingleNews(true));
    const { data } = yield call(storeService.getSingleNewsUrl, action.payload);
    const result = data?.data?.content;
    if (result) {
      yield put(saveSingleNews({ content: result, id: action.payload }));
    }
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setLoadingSingleNews(false));
  }
}

function* getCountriesSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getCountries);

    const newOptions = data.data.map((opt: CountryOptions) => {
      return {
        ...opt,
        label: opt.description,
        value: opt.locale,
      };
    });

    yield put(saveCountries(newOptions));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getBrandImagesSaga(action: PayloadAction<BrandsImagesPayload>): SagaIterator {
  try {
    const { brands, isEssilor } = action.payload;
    const params = { brands: brands, isEssilor: isEssilor };
    const { data } = yield call(storeService.getBrandImages, params);
    yield put(saveBrandImages(data.data.result));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getLandingPageSaga(action: PayloadAction<LandingPageContentsPayload>): SagaIterator {
  yield put(saveLandingPageStatus("LOADING"));

  try {
    const params = {
      page: "*",
      pagePath: action.payload.page,
    };

    const response = yield action.payload.page === "contenthub" && action.payload?.params
      ? call(storeService.getPageLayoutContentHub, action.payload.params)
      : call(storeService.getPageLayout, params);

    if (response && response.data) {
      // const rows = response.data.data?.grid?.rows;
      const { data } = response.data;
      const resultData =
        action.payload.page === "contenthub"
          ? data.content.result.length > 0
            ? data.content.result[0]
            : {}
          : response.data.data;
      const pageLayout = initializeGrid(action.payload.page, resultData);
      yield put(savePageLayout(pageLayout));

      const rows = pageLayout.rows ? pageLayout.rows : [];

      //Get contents for each item
      for (let i = 0; i < rows.length; i++) {
        const items = rows[i].cols ?? [];

        if (items.length > 0) {
          for (let j = 0; j < items.length; j++) {
            try {
              const id = items[j].id;
              if (id) yield put(getLandingPageContent({ id: id }));
            } catch (err) {
              return err;
            }
          }
        }
      }
    }
    yield put(saveLandingPageStatus("SUCCESS"));
  } catch (error) {
    yield put(saveLandingPageStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getNavigationTabsData(action: PayloadAction<Row[]>): SagaIterator {
  try {
    yield put(saveNavigationTabStatus("LOADING"));
    for (let i = 0; i < action.payload.length; i++) {
      const items = action.payload[i].cols ?? [];

      if (items.length > 0) {
        for (let j = 0; j < items.length; j++) {
          try {
            const id = items[j].id;
            if (id) yield put(getLandingPageContent({ id: id }));
          } catch (err) {
            return err;
          }
        }
      }
    }
    yield put(saveNavigationTabStatus("SUCCESS"));
  } catch (error) {
    yield put(saveNavigationTabStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getSearchTermAssociationSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getSearchTermAssociation);
    yield put(saveSearchTermAssociation(data?.data));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getUrlWithTokenEl360Saga(action: PayloadAction<CallbackPayload | null>): SagaIterator {
  const callback = action.payload?.callback;

  try {
    const url: string | null = yield select(selectUrl360Members);
    if (url) callback?.(true, url);
    else {
      const { data } = yield call(storeService.getUrlWithTokenEl360);
      callback?.(true, data?.data.urlWithToken);
      yield put(saveUrlWithToken(data?.data.urlWithToken));
    }
  } catch (error) {
    yield put(handleError(error));
    callback?.(false);
  }
}

function* getUrlWithTokenRewardsSaga(action: PayloadAction<CallbackPayload | null>): SagaIterator {
  const callback = action.payload?.callback;

  try {
    const url: string | null = yield select(selectUrlRewards);

    if (url) callback?.(true, url);
    else {
      const { data } = yield call(storeService.getUrlWithTokenRewards);
      callback?.(true, data?.data.urlWithToken);
      yield put(saveRewardsUrlWithToken(data?.data.urlWithToken));
    }
  } catch (error) {
    yield put(handleError(error));
    callback?.(false);
  }
}

function* getUrlLensSimulatorSaga(action: PayloadAction<CallbackPayload | null>): SagaIterator {
  const callback = action.payload?.callback;

  try {
    const url: string | null = yield select(selectUrlLensSimulator);

    if (url) callback?.(true, url);
    else {
      const { data } = yield call(storeService.getLensSimulatorUrl);
      callback?.(true, data?.data.link);
      yield put(saveUrlLensSimulator(data?.data.link));
    }
  } catch (error) {
    yield put(handleError(error));
    callback?.(false);
  }
}

function* getContactInfoSaga(action: PayloadAction<ContactInfoPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getContactInfo);

    const filteredContactInfo = data.data?.resultList?.[0]?.contactInfo?.find(
      (element: any) => element?.languageId === action.payload.languageId?.toString()
    );
    const contactInfo = mapContactInfo(filteredContactInfo);
    yield put(saveContactInfo(contactInfo));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* redirectToEssilorProSaga(action: PayloadAction<CallbackPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.redirectToEssilorPro);
    if (data?.data?.redirectUrl) action.payload.callback?.(true, data?.data?.redirectUrl); // TODO: verify response structure
  } catch (error) {
    yield put(handleError(error));
    action.payload.callback?.(false);
  }
}

function* getEyemedSaga(action: PayloadAction<CallbackPayload>): SagaIterator {
  try {
    const { data } = yield call(storeService.getEyemed);
    if (data?.data?.link) action.payload.callback?.(true, data?.data?.link);
  } catch (error) {
    yield put(handleError(error));
    action.payload.callback?.(false);
  }
}

function* getStarDoorsForBrandSaga(): SagaIterator {
  try {
    const isStarsMultidoor = yield select(selectIsStarsMultidoor);

    if (isStarsMultidoor) {
      yield put(saveStarDoorsPerBrandStatus("LOADING"));
      const { data } = yield call(storeService.getStarDoorsForBrand);

      const starsDoors: StarDoorsPerBrand = {};

      if (data.data?.selectedStarsDoors?.length > 0) {
        data.data?.selectedStarsDoors.forEach((door: any) => {
          const newDoor = {
            orgentityId: door.orgentityId,
            orgentityName: door.orgentityName,
          };

          door.starsBrands.forEach((brand: string) => {
            if (starsDoors[brand]) {
              starsDoors[brand].push(newDoor);
            } else {
              starsDoors[brand] = [newDoor];
            }
          });
        });
      }

      yield put(saveStarDoorsPerBrand(starsDoors));
      yield put(saveStarDoorsPerBrandStatus("SUCCESS"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveStarDoorsPerBrand({}));
    yield put(saveStarDoorsPerBrandStatus("ERROR"));
  }
}

/**
 * Get tutorial pills contents
 * @return {*}  {SagaIterator}
 */

function* getTutorialPillsSaga(): SagaIterator {
  try {
    const { data } = yield call(storeService.getTutorialPillsUrl, {
      type: "CMYLMarketing",
      tagNames: "tutorial-pills",
      qryProfile: "tutorial-pills",
    });

    const items = data?.data?.content?.result?.[0]?.items;
    if (items.length) yield put(saveTutorialPills(mapTutorialPills(items)));
    else yield put(saveTutorialPills(null));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveTutorialPills(null));
  }
}

export function* storeSaga(): SagaIterator {
  yield takeLatest(mapUrl.type, urlMapperSaga);
  yield takeLatest(getMenu.type, getMenuSaga);
  yield takeLatest(getBrandGroups.type, getBrandGroupsSaga);
  yield takeLatest(getLensesSubMenu.type, getLensesSubMenuSaga);
  yield takeLatest(getLensesEssilorSubMenu.type, getLensesEssilorSubMenuSaga);
  yield takeLatest(getInstrumentsSubMenu.type, getInstrumentsSubMenuSaga);
  yield takeOneForEachKey<ThirdLevelMenuPayload>({
    actionPattern: getThirdLevelMenu.type,
    saga: getThirdLevelMenuSaga,
    getUniqueKey: (actionPayload: ThirdLevelMenuPayload) => actionPayload?.identifier,
  });
  yield takeLatest(getContactInfo.type, getContactInfoSaga);
  yield takeEvery(getPublicCmsContent.type, getPublicCmsContentSaga);
  yield takeEvery(getCmsContent.type, getCmsContentSaga);
  yield takeEvery(getCmsContentTooltip.type, getCmsContentTooltipSaga);
  yield takeEvery(getCmsContentPopup.type, getCmsContentPopupSaga);
  yield takeEvery(getLandingPageContent.type, getLandingPageContentSaga);
  yield takeLatest(getStoreConfiguration.type, getStoreConfigurationSaga);
  yield takeLatest(updatePublicLanguage.type, updatePublicLanguageSaga);
  yield takeLatest(updatePrivateLanguage.type, updatePrivateLanguageSaga);
  yield takeLatest(handleError.type, handleErrorSaga);
  yield takeLatest(showErrorPopup.type, showErrorPopupSaga);
  yield takeLatest(getFaq.type, getFaqSaga);
  yield takeEvery(getNews.type, getNewsSaga);
  yield takeEvery(getSingleNews.type, getSingleNewsSaga);
  yield takeLatest(getCountries.type, getCountriesSaga);
  yield takeEvery(getBrandImages.type, getBrandImagesSaga);
  yield takeEvery(getLandingPage.type, getLandingPageSaga);
  yield takeEvery(getNavigationTab.type, getNavigationTabsData);
  yield takeEvery(getSearchTermAssociation.type, getSearchTermAssociationSaga);
  yield takeLatest(getUrlWithTokenRewards.type, getUrlWithTokenRewardsSaga);
  yield takeLatest(getUrlWithTokenEl360.type, getUrlWithTokenEl360Saga);
  yield takeLatest(getUrlLensSimulator.type, getUrlLensSimulatorSaga);
  yield takeLatest(redirectToEssilorPro.type, redirectToEssilorProSaga);
  yield takeLatest(getEyemed.type, getEyemedSaga);
  yield takeLatest(getStarDoorsForBrand.type, getStarDoorsForBrandSaga);
  yield takeLatest(getTutorialPills.type, getTutorialPillsSaga);
}
