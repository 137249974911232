import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import useMenuColumnsInfo, { MenuDivInfo } from "../../../../../hooks/useMenuColumnsInfo";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import { MostUsedCategory, MostUsedOption } from "../../../../../interfaces/mostUsedInterfaces";
import { selectLensesMenu } from "../../../../../store/store/storeSlice";
import {
  checkIfChipAlreadyExists,
  createMostUsedChipFromSelection,
  getMostUsedCategoryFromLensesColumn,
  getMostUsedCategoryFromMenuDivInfo,
} from "../../../../../utils/mostUsedUtils";
import Button from "../../../../styled-UI/Button";
import Checkbox from "../../../../styled-UI/Checkbox";
import { CustomOptions } from "../../../../styled-UI/CustomSelect";
import CustomText from "../../../../styled-UI/CustomText";
import LoaderTooltip from "../../../../styled-UI/loader/most-used/LoaderTooltip";
import RadioButton from "../../../../styled-UI/RadioButton";
import { ChipsHandler } from "../useSelectedMostUsed";

interface Props {
  categoryMenu: SecondLevelMenu;
  chipsHandler: ChipsHandler;
  closeTooltip?: () => void;
}

const ChipsCatalogueTooltip = ({
  categoryMenu,
  chipsHandler,
  closeTooltip,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  // NORMAL CATEGORIES
  const menuContentInfo: MenuDivInfo[] = useMenuColumnsInfo({
    menu: categoryMenu,
    nameCategory: categoryMenu.categoryName,
    considerBrandsAsAdult: true,
  });

  // LENSES CATEGORY
  const lensesMenu = useSelector(selectLensesMenu);
  const isLensesCategory = categoryMenu.identifier === "LENSES";

  // LOADING
  const isCategoryLoading = menuContentInfo.filter((_, i) => i in menuContentInfo).length === 0;
  const isLensesLoading = lensesMenu.length === 0;

  const isLoading = !isLensesCategory ? isCategoryLoading : isLensesLoading; // this check is done b/c there might be "empty slots" in the provided array

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////// CHECKBOXES & RADIO BUTTONS ///////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [selectedFacets, setSelectedFacets] = useState<MostUsedOption[]>([]);

  const onCheckboxChange = (checked: boolean, option: MostUsedOption) => {
    setFeedback(undefined);
    if (checked) !isChecked(option) && setSelectedFacets((old) => [...old, option]);
    else setSelectedFacets((old) => old.filter((_) => _.url !== option.url));
  };

  const onRadioButtonChange = (e: CustomOptions, option: MostUsedOption) => {
    setFeedback(undefined);
    setSelectedFacets((old) => {
      const newSelected = old.filter((_) => _.columnTitle !== option.columnTitle);
      return [...newSelected, option];
    });
  };

  const onAllCategoriesChange = (col: MostUsedCategory) => {
    setFeedback(undefined);
    const isChecked = isAllCategoriesChecked(col);
    col.options.forEach((_) => onCheckboxChange(!isChecked, _));
  };

  const isChecked = (cat: MostUsedOption) => selectedFacets.some((_) => _.url === cat.url);
  const isAllCategoriesChecked = (col: MostUsedCategory) => col.options.every((_) => isChecked(_));
  const isAnyInThisColumnChecked = (col: MostUsedCategory) => col.options.some((_) => isChecked(_));

  const isAnyOtherColumnFacetChecked = (col: MostUsedCategory) => {
    const ignoreFacet = (facet: string | undefined) =>
      facet === undefined || facet === "manufacturer.raw";
    if (ignoreFacet(col.columnFacet)) return false;

    return selectedFacets.some(
      (_) => !ignoreFacet(_.columnFacet) && _.columnFacet !== col.columnFacet
    );
  };

  const resetCheckboxes = () => setSelectedFacets([]);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// SAVING A NEW CHIP ///////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [feedback, setFeedback] = useState<{ text: string | undefined; isSuccess: boolean }>();

  const giveFeedback = (isSuccess: boolean) => {
    const text = isSuccess
      ? "MOST_USED_POPUP_SELECTION_PREFERENCE_SAVED"
      : "MOST_USED_POPUP_SELECTION_ALREADY_SELECTED"; // not success is currently never used
    setFeedback({ text, isSuccess });
    isSuccess && setTimeout(() => setFeedback(undefined), 1000);
  };

  const onConfirm = () => {
    const newChip = createMostUsedChipFromSelection(
      categoryMenu,
      selectedFacets,
      menuContentInfo,
      isLensesCategory
    );

    if (!checkIfChipAlreadyExists(newChip, chipsHandler.selectedChips)) {
      chipsHandler.selectChip(newChip);
      giveFeedback(true);
      resetCheckboxes();
      setTimeout(() => closeTooltip?.(), 1000);
    } else giveFeedback(false);
  };

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// RENDER ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const renderColumns = (): JSX.Element => {
    const categoryContent = getMostUsedCategoryFromMenuDivInfo(menuContentInfo);
    const lensesContent = getMostUsedCategoryFromLensesColumn(lensesMenu);

    const content = isLensesCategory ? lensesContent : categoryContent;

    return (
      <>
        {content.map((col: MostUsedCategory, i: number) => {
          const isCategoryColumn = col.key === "MENU_CATEGORY";

          if (col.options.length === 0) return <></>;
          return (
            <div key={i}>
              <ColumnTitle>
                <CustomText
                  as="span"
                  fontSizePx={14}
                  font="font-medium"
                  marginBottomPx={30}
                  uppercase
                >
                  {translateLabel(col.title)}
                </CustomText>
              </ColumnTitle>

              <OptionsWrapper>
                {isCategoryColumn && (
                  <div key={"ALL_CATEGORIES_CHECKBOX"}>
                    <Checkbox
                      name={"ALL_CATEGORIES_CHECKBOX"}
                      label={translateLabel("MOST_USED_ALL_CATEGORIES_CHECKBOX")}
                      checked={isAllCategoriesChecked(col)}
                      isSelectAll={isAnyInThisColumnChecked(col)}
                      onChange={() => onAllCategoriesChange(col)}
                      controlled
                      alignLabel="start"
                    />
                  </div>
                )}
                {col.isRadio
                  ? col.options.map((option: MostUsedOption) => {
                      return (
                        <div key={option.url}>
                          <RadioButton
                            label={option.label ?? ""}
                            onChange={(e) => onRadioButtonChange(e, option)}
                            checked={isChecked(option)}
                            value={option.facet.facetValue}
                            alignLabel="start"
                            textAlign="left"
                            name="radio"
                            type="radio"
                          />
                        </div>
                      );
                    })
                  : col.options.map((option: MostUsedOption) => {
                      return (
                        <div key={option.url}>
                          <Checkbox
                            name={option.label ?? ""}
                            label={option.label ?? ""}
                            checked={isChecked(option)}
                            onChange={(e) => onCheckboxChange(e.target.checked, option)}
                            disabled={isAnyOtherColumnFacetChecked(col)}
                            controlled
                            alignLabel="start"
                          />
                        </div>
                      );
                    })}
              </OptionsWrapper>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Container>
      <div className="g-0 column-container">{isLoading ? <LoaderTooltip /> : renderColumns()}</div>
      <div className="row g-0">
        <div>
          <ButtonsContainer>
            {feedback && (
              <CustomText
                as="span"
                fontSizePx={12}
                color={feedback.isSuccess ? "primary" : "red"}
                font="font-regular"
              >
                {translateLabel(feedback.text ?? "")}
              </CustomText>
            )}
            <ButtonSpacer>
              <Button type="secondary" onClick={resetCheckboxes} disabled={!!feedback}>
                {translateLabel("MOST_USED_POPUP_CATEGORIES_BUTTON_RESET")}
              </Button>
            </ButtonSpacer>
            <Button
              type="primary"
              onClick={onConfirm}
              isLoading={isLoading}
              disabled={(isLensesCategory && selectedFacets.length === 0) || !!feedback}
            >
              {translateLabel("MOST_USED_POPUP_CATEGORIES_BUTTON_APPLY")}
            </Button>
          </ButtonsContainer>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .column-container {
    display: flex;
    width: auto;
    overflow-x: auto;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2rem 2rem 2rem 2rem 2rem 2rem 2rem;
  grid-auto-columns: minmax(12rem, 1fr);
  column-gap: 0.5rem;

  div {
    max-width: 14rem;
  }
`;

const ColumnTitle = styled.div`
  padding-right: 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
`;

const ButtonSpacer = styled.div`
  margin-right: 1rem;
`;

export default ChipsCatalogueTooltip;
