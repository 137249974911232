import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";

import { capitalizeFirstLetter } from "../../../utils/utils";
import CustomText from "../../styled-UI/CustomText";
import { Route } from "./BreadcrumbSection";

type BreadcrumbProps = {
  routes: Route[];
  style?: "default" | "dark";
  url?: string;
};

export default function Breadcrumb({
  routes,
  style = "default",
  url = "",
}: BreadcrumbProps): JSX.Element {
  const { translateLabel } = useTranslation();
  const digitalCatalogueLabel = "digital-catalog/";

  //handle digital catalogue micro frontend
  //if digital catalogue is open and we are not in the entry page , ex. Design page
  if (
    window.location.pathname.includes("digital-catalog") &&
    url?.includes(digitalCatalogueLabel)
  ) {
    // to avoid double label in breadcrumbs, remove all pages over entry page of catalogue
    if (routes.find((item) => item.pathForLink.includes(digitalCatalogueLabel))) {
      routes = routes.filter((e) => !e.pathForLink.includes(digitalCatalogueLabel));
    }

    // take all levels after entry page and join then manually because they are not linkable (because missed in authRoutes)
    const parts = url?.split(digitalCatalogueLabel);
    const name = parts.pop();
    name &&
      routes.push({
        path: digitalCatalogueLabel,
        name: name
          .split("/")
          .map((label: string) =>
            capitalizeFirstLetter(label.replace(/-/g, " ").replace(/\?.*$/, ""))
          )
          .join(" / "),
        pathForLink: digitalCatalogueLabel,
        disabled: true,
      });
  }

  const renderRoutes = () => {
    return routes.map((route, index) => {
      const isLastElement = index === routes.length - 1;
      let name = decodeURIComponent(route.name);

      if (!window.location.pathname.includes("preplp")) {
        name = capitalizeFirstLetter(name?.replace("-", " "));
      }

      if (window.location.pathname.includes("size-chart") && isLastElement) {
        const nameArr = name.split("-");
        if (nameArr.length > 1) {
          name = `${capitalizeFirstLetter(nameArr[0])} ${translateLabel(
            nameArr[1].toUpperCase() + "_LABEL"
          )}`;
        }
      }

      if (route.fakePath)
        return (
          <CustomText
            key={route.path + route.fakePath}
            as="span"
            fontSizePx={13}
            lineHeightPx={18}
            font="font-regular"
            color={style === "dark" ? "white" : "gray-dark"}
          >
            {route.fakePath + " /"}
            &nbsp;
          </CustomText>
        );
      if (route.disabled || isLastElement)
        return (
          <CustomText
            key={route.path}
            as="span"
            fontSizePx={13}
            color={style === "dark" ? "white" : "gray-dark"}
            lineHeightPx={18}
            font={routes.length === index + 1 ? "font-medium" : "font-regular"}
          >
            {name}
            {index !== routes.length - 1 ? " /" : null}
            &nbsp;
          </CustomText>
        );
      else
        return (
          <StyledRoute
            to={route.pathForLink}
            key={route.path}
            className={clsx(style === "dark" && "route-dark-bg", isLastElement && "selected-route")}
          >
            {name}
            {index !== routes.length - 1 ? " /" : null}
            &nbsp;
          </StyledRoute>
        );
    });
  };
  return <StyledContainer>{renderRoutes()}</StyledContainer>;
}

const StyledContainer = styled.div`
  display: flex;
`;

const StyledRoute = styled(Link)`
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: ${(props) => props.theme.palette.gray.dark};
  font-family: ${(props) => props.theme.fonts.fontRegular};

  &.selected-route {
    font-family: ${(props) => props.theme.fonts.fontMedium};
  }

  &.route-dark-bg {
    color: ${(props) => props.theme.palette.white};
  }
`;
