import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { mapDuplicatedEmailErrorLabel } from "../../utils/utils";
import { handleError } from "../store/storeSagas";
import { delay } from "redux-saga/effects";

import { ApprovedRequestPayload, RejectRequestPayload } from "./leonardoInterface";
import leonardoService from "./leonardoServices";
import {
  saveCourses,
  saveGetCoursesStatus,
  setApproveError,
  setUpdateSubuserStatus,
  setCountSubuserRequest,
  setSubuserApproval,
  setSubuserApprovalStatus,
  sliceName,
} from "./leonardoSlice";
import { mapLeonardoCourses } from "../../utils/leonardoUtils";

/****   ACTIONS   ****/
export const getRequestSubuserApproval = createAction(sliceName + "/getRequestSubuserApproval");
export const postApproveAllRequestSubuser = createAction<ApprovedRequestPayload[]>(
  sliceName + "/postApproveAllRequestSubuser"
);
export const postRejectAllRequestSubuser = createAction(
  sliceName + "/postRejectAllRequestSubuserl"
);
export const postApproveRequestSubuser = createAction<ApprovedRequestPayload>(
  sliceName + "/postApproveRequestSubuser"
);
export const postRejectRequestSubuser = createAction<RejectRequestPayload>(
  sliceName + "/postRejectRequestSubuser"
);

export const getCountSubuser = createAction(sliceName + "/getCountSubuser");

// leonardo carousel
export const getCourses = createAction(sliceName + "/getCourses");

/****    SAGAS   ****/
function* getRequestSubuserApprovalSaga(): SagaIterator {
  try {
    yield put(setSubuserApprovalStatus("LOADING"));
    const { data } = yield call(leonardoService.getRequestSubuserApprovalList);
    yield put(setSubuserApproval(data.data));
    yield put(setSubuserApprovalStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setSubuserApprovalStatus("ERROR"));
  }
}

function* postApproveAllRequestSubuserSaga(
  action: PayloadAction<ApprovedRequestPayload[]>
): SagaIterator {
  try {
    yield put(setUpdateSubuserStatus("all"));
    yield call(leonardoService.postApproveAllRequest, action.payload);
    yield put(getRequestSubuserApproval());
    yield put(getCountSubuser());
    yield put(setUpdateSubuserStatus(null));
  } catch (error) {
    yield put(handleError(error));
    yield put(setUpdateSubuserStatus(null));
  }
}

function* postRejectAllRequestSubuserSaga(): SagaIterator {
  try {
    yield put(setUpdateSubuserStatus("all"));
    yield call(leonardoService.postRejectAllRequest);
    yield put(getRequestSubuserApproval());
    yield put(getCountSubuser());
    yield put(setUpdateSubuserStatus(null));
  } catch (error) {
    yield put(handleError(error));
    yield put(setUpdateSubuserStatus(null));
  }
}

function* postApproveRequestSubuserSaga(
  action: PayloadAction<ApprovedRequestPayload>
): SagaIterator {
  try {
    yield put(setUpdateSubuserStatus(action.payload.requestId.toString()));
    yield call(leonardoService.postApproveRequest, action.payload);
    yield put(getRequestSubuserApproval());
    yield put(getCountSubuser());
    yield put(setUpdateSubuserStatus(null));
  } catch (error) {
    yield put(handleError(error));
    yield put(setUpdateSubuserStatus(null));
    const errorCode =
      error?.response?.data?.errors?.[0]?.responseBody?.error ||
      error?.response?.data?.errors?.[0]?.responseBody?.errors?.[0]?.errorKey;
    if (errorCode) {
      const key = mapDuplicatedEmailErrorLabel(errorCode, "APPROVE_SUBUSER");
      yield put(setApproveError(key));
    }
  }
}

function* postRejectRequestSubuserSaga(action: PayloadAction<RejectRequestPayload>): SagaIterator {
  try {
    yield put(setUpdateSubuserStatus(action.payload.requestId.toString()));
    yield call(leonardoService.postRejectRequest, action.payload);
    yield put(getRequestSubuserApproval());
    yield put(getCountSubuser());
    yield put(setUpdateSubuserStatus(null));
  } catch (error) {
    yield put(handleError(error));
    yield put(setUpdateSubuserStatus(null));
  }
}

function* getCountSubuserSaga(): SagaIterator {
  try {
    const { data } = yield call(leonardoService.getCountSubuserRequest);
    yield put(setCountSubuserRequest(data.data));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getCoursesSaga(): SagaIterator {
  try {
    yield put(saveGetCoursesStatus("LOADING"));

    const { data } = yield call(leonardoService.getCourses);
    const mappedCourses = mapLeonardoCourses(data?.content?.result[0]);

    if (mappedCourses) yield put(saveCourses(mappedCourses));
    else yield put(saveCourses(null));
    yield put(saveGetCoursesStatus("SUCCESS"));
  } catch (error) {
    // yield put(handleError(error));
    yield put(saveGetCoursesStatus("ERROR"));
    yield put(saveCourses(null));
  }
}

export function* leonardoSaga(): SagaIterator {
  yield takeEvery(getRequestSubuserApproval.type, getRequestSubuserApprovalSaga);
  yield takeEvery(postApproveAllRequestSubuser.type, postApproveAllRequestSubuserSaga);
  yield takeEvery(postRejectAllRequestSubuser.type, postRejectAllRequestSubuserSaga);
  yield takeEvery(postApproveRequestSubuser.type, postApproveRequestSubuserSaga);
  yield takeEvery(postRejectRequestSubuser.type, postRejectRequestSubuserSaga);
  yield delay(5000);
  yield takeEvery(getCountSubuser.type, getCountSubuserSaga);
  yield takeEvery(getCourses.type, getCoursesSaga);
}
