import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import { handleError } from "../store/storeSagas";
import accountingService from "./accountingService";

import {
  clearAcccountingError,
  saveCreditDetails,
  saveCreditSummary,
  saveInvoiceOptions,
  saveInvoices,
  saveOrgentityDoor,
  savePinValidated,
  saveTemporaryPin,
  selectTemporaryPin,
  setError,
  setErrorInvoice,
  setMassiveInvoiceDownload,
  setMassiveInvoiceDownloadStatus,
  setPrepareMassiveInvoiceDownloadStatus,
  setRequestStatus,
  sliceName,
  startAccountingLoading,
  stopAccountingLoading,
  toggleEmailSentFlag,
} from "./accountingSlice";
import {
  ConsumerRow,
  Credit,
  DownloadInvocePayload,
  GetInvoicesPayload,
  InvoicePayload,
  PrepareMassiveInvoiceDownloadPayload,
  Summary,
  UpdatePinPayload,
  ValidatePinPayload,
} from "./accountingInterfaces";
import { CLOSED_POPUP_ACCESS_ACCOUNTING, togglePopupAccessAccounting } from "../store/storeSlice";
import { mapPinErrorMessage } from "../../utils/utils";
import { selectPageSize } from "../catalogue/catalogueSlice";

/* ACTIONS */
/////////////////// pin management
export const validatePin = createAction<ValidatePinPayload>(sliceName + "/validatePin");
export const updatePin = createAction<ValidatePinPayload>(sliceName + "/updatePin");
export const resetPin = createAction<string>(sliceName + "/resetPin");

/////////////////// invoices
export const getInvoices = createAction<InvoicePayload>(sliceName + "/getInvoices");
export const downloadInvoice = createAction<DownloadInvocePayload>(sliceName + "/downloadInvoice");
export const getInvoiceOptions = createAction(sliceName + "/getInvoiceOptions");
export const prepareMassiveInvoiceDownload = createAction<PrepareMassiveInvoiceDownloadPayload>(
  sliceName + "/prepareMassiveInvoiceDownload"
);
export const getMassiveInvoiceDownload = createAction<string>(
  sliceName + "/getMassiveInvoiceDownload"
);

/////////////////// credit
export const getCreditSummary = createAction(`${sliceName}/getCreditSummary`);
export const getCreditDetails = createAction<string>(`${sliceName}/getCreditDetails`);

/* SAGAS */

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// PING MANAGEMENT //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Validate PIN to access accounting section
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* validatePinSaga(action: PayloadAction<ValidatePinPayload>): SagaIterator {
  try {
    yield put(startAccountingLoading("accountingPinValidate"));
    yield put(setRequestStatus("LOADING"));
    yield put(saveTemporaryPin(action.payload.pin));

    const { data } = yield call(accountingService.validatePin, action.payload);
    yield put(saveOrgentityDoor(data.data.output?.door));
    yield put(clearAcccountingError("pinError"));
    yield put(clearAcccountingError("paymentSectionError"));

    yield put(saveTemporaryPin(""));
    yield put(savePinValidated(action.payload.type));
    yield put(setRequestStatus("SUCCESS"));
    yield put(togglePopupAccessAccounting(CLOSED_POPUP_ACCESS_ACCOUNTING));
    yield put(stopAccountingLoading("accountingPinValidate"));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(handleError(error));
    } else {
      yield put(
        setError({
          error: {
            type:
              error.response?.data?.errors[0].code == "40009"
                ? "NO_PIN_USER"
                : mapPinErrorMessage(error.response?.data?.errors[0]?.message),
            status: true,
          },
          errorName: "pinError",
        })
      );
    }
    yield put(setRequestStatus("ERROR"));
    yield put(stopAccountingLoading("accountingPinValidate"));
  }
}

/**
 * Update PIN for accessing accounting section
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* updatePinSaga(action: PayloadAction<ValidatePinPayload>): SagaIterator {
  const oldPin = yield select(selectTemporaryPin);
  try {
    const requestBody: UpdatePinPayload = {
      oldPin: oldPin,
      newPin: action.payload.pin,
    };

    yield call(accountingService.updatePin, requestBody);

    //clean errors
    yield put(clearAcccountingError("pinError"));
    yield put(saveTemporaryPin(""));

    //call validate pin
    yield put(validatePin(action.payload));
  } catch (error) {
    yield put(
      setError({
        error: { type: mapPinErrorMessage(error.response?.data?.errors[0]?.message), status: true },
        errorName: "pinError",
      })
    );
  }
}

/**
 * Reset PIN for accessing accounting section
 *
 * @param {PayloadAction<any>} action
 * @return {*}  {SagaIterator}
 */
function* resetPinSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(accountingService.resetPin, action.payload);
    yield put(clearAcccountingError("pinError"));
    yield put(toggleEmailSentFlag(true));
  } catch (error) {
    yield put(toggleEmailSentFlag(false));
    yield put(
      setError({
        error: { type: "emailError", status: true },
        errorName: "pinError",
      })
    );
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// INVOICES /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Checkout selected order items
 *
 * @return {*}  {SagaIterator}
 */
function* getInvoicesSaga(action: PayloadAction<InvoicePayload>): SagaIterator {
  const pageSize = yield select(selectPageSize);
  try {
    const qparams: GetInvoicesPayload = {
      pageSize,
      filters: action.payload,
    };
    yield put(startAccountingLoading("accountingList"));

    //get invoices
    const { data } = yield call(accountingService.getInvoices, qparams);
    if (data.data) yield put(saveInvoices(data.data));

    //clean error and loading
    yield put(stopAccountingLoading("accountingList"));
    yield put(clearAcccountingError("paymentSectionError"));
  } catch (error) {
    yield put(stopAccountingLoading("accountingList"));
    yield put(
      setErrorInvoice({
        error: {
          type: mapPinErrorMessage(error?.response?.data?.errors?.[0]?.message),
          status: true,
          code: error?.response?.status,
        },
        errorName: "paymentSectionError",
      })
    );
  }
}

function* downloadInvoiceSaga(action: PayloadAction<DownloadInvocePayload>): SagaIterator {
  try {
    const { data } = yield call(accountingService.downloadInvoice, action.payload.id);
    data?.data && action.payload.callback(data.data);
  } catch (error) {
    yield put(handleError(error));
  }
}

function* getInvoiceOptionsSaga(): SagaIterator {
  try {
    const { data } = yield call(accountingService.optionsInvoice);
    yield put(saveInvoiceOptions(data.data));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* prepareMassiveInvoiceDownloadSaga({
  payload,
}: PayloadAction<PrepareMassiveInvoiceDownloadPayload>): SagaIterator {
  try {
    yield put(setPrepareMassiveInvoiceDownloadStatus("LOADING"));
    const { data } = yield call(accountingService.prepareMassiveInvoiceDownload, payload);
    if (data.data?.output?.result === "OK") {
      yield put(setPrepareMassiveInvoiceDownloadStatus("SUCCESS"));
      return;
    }
    yield put(setPrepareMassiveInvoiceDownloadStatus("ERROR"));
  } catch (error) {
    yield put(setPrepareMassiveInvoiceDownloadStatus("ERROR"));
    yield put(handleError(error));
  }
}
function* getMassiveInvoiceDownloadSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setMassiveInvoiceDownloadStatus("LOADING"));
    const { data } = yield call(accountingService.massiveInvoiceDownload, payload);
    yield put(setMassiveInvoiceDownload(data?.data));

    if (!!data?.data?.fileUrl) yield put(setMassiveInvoiceDownloadStatus("SUCCESS"));
    else yield put(setMassiveInvoiceDownloadStatus("ERROR"));
  } catch (error) {
    yield put(setMassiveInvoiceDownloadStatus("ERROR"));
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////-/////// CREDIT /////-////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get credit summary (first view when landing in credit)
 *
 * @return {*}  {SagaIterator}
 */
function* getCreditSummarySaga(): SagaIterator {
  try {
    yield put(startAccountingLoading("accountingList"));

    //get credit summary data
    const { data } = yield call(accountingService.getCreditSummary);
    if (data.data && data.data.output) {
      const creditSummary: Credit = {
        totalAmountS: data.data.output.totalAmountS || "0",
        totalAmountM: data.data.output.totalAmountM || "0",
        totalAmountO: data.data.output.totalAmountO || "0",
        totalBalance: data.data.output.totalBalance,
        summary: [],
      };

      const summary: Summary[] = data.data.output.summary.map((_: Summary) => {
        const calculatedAmounts = { amountS: 0, amountO: 0, amountM: 0, amountTot: 0 };
        _.openEntryList.forEach((consumer: ConsumerRow) => {
          calculatedAmounts.amountS += +consumer.amountS;
          calculatedAmounts.amountM += +consumer.amountM;
          calculatedAmounts.amountO += +consumer.amountO;
          calculatedAmounts.amountTot += +consumer.amountTot;
        });
        return {
          company: _.company,
          customer: _.customer,
          customer2: _.customer2,
          amountS: calculatedAmounts.amountS.toString(),
          amountM: calculatedAmounts.amountM.toString(),
          amountO: calculatedAmounts.amountO.toString(),
          amountTot: calculatedAmounts.amountTot.toString(),
          currency: _.currency,
          detail: _.detail,
          openEntryList: _.openEntryList,
        };
      });
      creditSummary.summary = summary;
      yield put(saveCreditSummary(creditSummary));
    }
    //clean errors and loading
    yield put(stopAccountingLoading("accountingList"));
    yield put(clearAcccountingError("paymentSectionError"));
  } catch (error) {
    yield put(handleError(error));
    yield put(stopAccountingLoading("accountingList"));
    // yield put(
    //   setErrorInvoice({
    //     error: {
    //       type: mapPinErrorMessage(error?.response?.data?.errors?.[0]?.message),
    //       status: true,
    //       code: error?.response?.status,
    //     },
    //     errorName: "paymentSectionError",
    //   })
    // );
  }
}

/**
 * Get credit details (viewed by clicking on arrow in credit summary)
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getCreditDetailsSaga(action: PayloadAction<string>): SagaIterator {
  if (action.payload) {
    try {
      yield put(startAccountingLoading("accountingList"));

      const { data } = yield call(accountingService.getCreditDetails, action.payload);
      if (data?.data?.output) yield put(saveCreditDetails(data.data.output));

      yield put(stopAccountingLoading("accountingList"));
    } catch (error) {
      yield put(stopAccountingLoading("accountingList"));
      yield put(handleError(error));
      // if (error?.response?.status === 401) {

      // } else if (error?.response?.status === 403) {
      //   yield put(
      //     setErrorInvoice({
      //       error: {
      //         type: "REDIRECT_TO_CREDIT",
      //         status: true,
      //         code: error.response?.status,
      //       },
      //       errorName: "paymentSectionError",
      //     })
      //   );
      // } else {
      //   console.log(error);
      // }
    }
  }
}

export function* accountingSaga(): SagaIterator {
  /////////////////// pin management
  yield takeLatest(validatePin.type, validatePinSaga);
  yield takeLatest(updatePin.type, updatePinSaga);
  yield takeLatest(resetPin.type, resetPinSaga);

  /////////////////// invoices
  yield takeLatest(getInvoices.type, getInvoicesSaga);
  yield takeLatest(downloadInvoice.type, downloadInvoiceSaga);
  yield takeLatest(getInvoiceOptions.type, getInvoiceOptionsSaga);
  yield takeLatest(prepareMassiveInvoiceDownload.type, prepareMassiveInvoiceDownloadSaga);
  yield takeLatest(getMassiveInvoiceDownload.type, getMassiveInvoiceDownloadSaga);

  /////////////////// credit
  yield takeLatest(getCreditSummary.type, getCreditSummarySaga);
  yield takeLatest(getCreditDetails.type, getCreditDetailsSaga);
}
