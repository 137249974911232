import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { all } from "redux-saga/effects";
import {
  mapNotificationActionToUpdatePayload,
  mapNotificationsList,
  mapNotificationsPreference,
  PRODUCTS_SECTIONS,
  statusToSectionMap,
} from "../../utils/messagesUtils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { cloneDeep } from "lodash";
import { mapPriceResponse } from "../../utils/catalogueUtils";
import { saveBackorderTrackedItems, selectBackorderTrackedItems } from "../cart/cartSlice";
import catalogueService from "../catalogue/catalogueService";
import {
  saveBackorderProductAvailability,
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
  setLoadingAvailability,
} from "../catalogue/catalogueSlice";
import { handleError } from "../store/storeSagas";
import { Door } from "../user/userInterfaces";
import { selectActiveDoor, selectIsMultidoor } from "../user/userSlice";
import {
  AddInventoryTrackingPayload,
  AvailableProduct,
  BackordersAvailability,
  BackordersAvailabilitySection,
  DeleteInventoryTrackingPayload,
  InventoryTrackingPayload,
  DeleteNotificationsPayload,
  MarkAsReadOrUnreadNotificationsPayload,
  NotificationsFiltersPayload,
  UpdateNotificationsPreferencesPayload,
} from "./messagesInterfaces";
import messagesService from "./messagesServices";
import {
  DEFAULT_NOTIFICATIONS,
  saveBackordersAvailability,
  saveNotifications,
  saveNotificationsPreferences,
  saveTotalNumberNotifications,
  selectBackordersAvailability,
  selectTotalNumberNotifications,
  setBackorderPricesStatus,
  setBackordersAvailabilityStatus,
  setMarkedNotificationsStatus,
  setNotificationsPreferencesStatus,
  setNotificationsStatus,
  setUpdateNotificationsPreferencesStatus,
  sliceName,
} from "./messagesSlice";

////////////////// NOTIFICATIONS
export const getNotificationsList = createAction<NotificationsFiltersPayload>(
  sliceName + "/getNotifications"
);
export const markAsReadOrUnreadNotifications = createAction<MarkAsReadOrUnreadNotificationsPayload>(
  sliceName + "/markAsReadOrUnreadNotifications"
);
export const markAsDeletedNotifications = createAction<DeleteNotificationsPayload>(
  sliceName + "/markAsDeletedNotifications"
);
export const getTotalNumberNotifications = createAction<boolean>(
  sliceName + "/getTotalNumberNotifications"
);

////////////////// NOTIFICATIONS PREFERENCES
export const getNotificationsPreferences = createAction<boolean | undefined>(
  sliceName + "/getNotificationsPreferences"
);
export const updateNotificationsPreferences = createAction<UpdateNotificationsPreferencesPayload>(
  sliceName + "/updateNotificationsPreferences"
);

////////////////// GREEN IS BACK
export const getInventoryTracking = createAction<InventoryTrackingPayload>(
  sliceName + "/getInventoryTracking"
);
export const deleteInventoryTracking = createAction<DeleteInventoryTrackingPayload>(
  sliceName + "/deleteInventoryTracking"
);
export const deleteInventoryTrackingLine = createAction<DeleteInventoryTrackingPayload>(
  sliceName + "/deleteInventoryTrackingLine"
);
export const addInventoryTracking = createAction<AddInventoryTrackingPayload>(
  sliceName + "/addInventoryTracking"
);
export const areProductsTracked = createAction<string[]>(sliceName + "/areProductsTracked");

/* SAGAS */

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// NOTIFICATIONS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getNotificationsListSaga({
  payload,
}: PayloadAction<NotificationsFiltersPayload>): SagaIterator {
  try {
    yield put(setNotificationsStatus("LOADING"));

    const { data } = yield call(messagesService.getAllNotificationsList, {
      ...payload,
      typeId: 3,
      pageSize: 11,
    });

    const mappedNotificationsList = mapNotificationsList(data?.data);
    yield put(saveNotifications(mappedNotificationsList));

    yield put(setNotificationsStatus("SUCCESS"));
  } catch (error) {
    yield put(setNotificationsStatus("ERROR"));
    yield put(saveNotifications(DEFAULT_NOTIFICATIONS));
    yield put(handleError(error));
  }
}

function* markAsReadOrUnreadNotificationsSaga(
  action: PayloadAction<MarkAsReadOrUnreadNotificationsPayload>
): SagaIterator {
  const { callback, ...payload } = action.payload;

  try {
    yield put(setMarkedNotificationsStatus("LOADING"));
    if (payload.notifications.length > 1) yield put(setNotificationsStatus("LOADING"));
    yield call(messagesService.markAsReadOrUnreadNotifications, payload);

    callback?.(true);
    yield put(getTotalNumberNotifications(true));
    yield put(setMarkedNotificationsStatus("SUCCESS"));
  } catch (error) {
    callback?.(false);
    yield put(setMarkedNotificationsStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* markAsDeletedNotificationsSaga(
  action: PayloadAction<DeleteNotificationsPayload>
): SagaIterator {
  const { callback, ...payload } = action.payload;

  try {
    yield put(setMarkedNotificationsStatus("LOADING"));
    yield put(setNotificationsStatus("LOADING"));
    yield call(messagesService.markAsDeletedNotifications, payload);

    callback?.(true);
    yield put(setMarkedNotificationsStatus("SUCCESS"));
  } catch (error) {
    callback?.(false);
    yield put(setMarkedNotificationsStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* getTotalNumberNotificationsSaga({
  payload: forceReload,
}: PayloadAction<boolean>): SagaIterator {
  try {
    const totalNumberNotifications = yield select(selectTotalNumberNotifications);

    if (forceReload || totalNumberNotifications === null) {
      const { data } = yield call(messagesService.getTotalNumberNotifications);
      yield put(saveTotalNumberNotifications(data?.data?.totalAlerts));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// NOTIFICATIONS PREFERENCES ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getNotificationsPreferencesSaga({
  payload: updateLoading,
}: PayloadAction<boolean | undefined>): SagaIterator {
  try {
    if (!updateLoading) yield put(setNotificationsPreferencesStatus("LOADING"));

    const [allNotifications, notificationPreferences] = yield all([
      call(messagesService.getAllNotifications),
      call(messagesService.getAllNotificationsPreferences),
    ]);

    const allNotificationsServices = allNotifications?.data?.data?.services;
    const notificationPreferencesData = notificationPreferences?.data?.data;

    const mappedNotificationPreferences = mapNotificationsPreference(
      allNotificationsServices,
      notificationPreferencesData
    );

    yield put(saveNotificationsPreferences(mappedNotificationPreferences));
    if (!updateLoading) yield put(setNotificationsPreferencesStatus("SUCCESS"));
    else yield put(setUpdateNotificationsPreferencesStatus("SUCCESS"));
  } catch (error) {
    yield put(setNotificationsPreferencesStatus("ERROR"));
    yield put(handleError(error));
  }
}

function* updateNotificationsPreferencesSaga({
  payload,
}: PayloadAction<UpdateNotificationsPreferencesPayload>): SagaIterator {
  const { updatedNotifications, callback } = payload;

  try {
    yield put(setUpdateNotificationsPreferencesStatus("LOADING"));
    yield call(
      messagesService.updateNotificationsPreferences,
      mapNotificationActionToUpdatePayload(updatedNotifications)
    );

    callback?.(true);
  } catch (error) {
    yield put(setUpdateNotificationsPreferencesStatus("ERROR"));
    callback?.(false);

    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// GREEN IS BACK /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getInventoryTrackingSaga({
  payload,
}: PayloadAction<InventoryTrackingPayload>): SagaIterator {
  try {
    yield put(
      setBackordersAvailabilityStatus({
        status: "LOADING",
        section: payload.inventoryStatus?.[0] && statusToSectionMap[payload.inventoryStatus[0]],
      })
    );
    const currentDoor: Door = yield select(selectActiveDoor);
    const { data } = yield call(messagesService.getBackordersAvailability, {
      ...payload,
      doorId: currentDoor?.orgentityId,
    });
    const backordersAvailability: BackordersAvailability = data.data;
    yield put(saveBackordersAvailability(backordersAvailability));
    yield put(setBackordersAvailabilityStatus({ status: "SUCCESS" }));

    // # get prices
    const productIds: string[] = []; // collect ids
    Object.values(backordersAvailability).forEach((section: BackordersAvailabilitySection) => {
      if (section.content) {
        section.content.forEach((product) => {
          product.catentryId && productIds.push(product.catentryId);
        });
      }
    });

    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }
    if (hasPricePrivilege && productIds.length > 0) {
      yield put(setBackorderPricesStatus("LOADING"));
      const { data: price } = yield call(catalogueService.getPriceService, productIds);
      const priceData = mapPriceResponse(price?.data); // map response into a GetPriceResult[] array

      const backordersAvailabilityCopy = cloneDeep(backordersAvailability);

      PRODUCTS_SECTIONS.forEach((section) => {
        if (backordersAvailability[section]?.content) {
          const sectionContentWithPrice = backordersAvailability[section].content.map((product) => {
            const price = priceData.find((price) => price.id === product.catentryId)?.price;
            return { ...product, price };
          });

          backordersAvailabilityCopy[section].content = sectionContentWithPrice;
        }
      });

      yield put(saveBackordersAvailability(backordersAvailabilityCopy));
      yield put(setBackorderPricesStatus("SUCCESS"));
    }
    // # end get prices
  } catch (error) {
    yield put(setBackordersAvailabilityStatus({ status: "ERROR" }));
    yield put(handleError(error));
    console.log(error);
  }
}

function* deleteInventoryTrackingLineSaga({
  payload,
}: PayloadAction<DeleteInventoryTrackingPayload>): SagaIterator {
  try {
    const { data } = yield call(
      messagesService.deleteInventoryTracking,
      payload.inventoryTrackingId
    );
    const deletedProduct: AvailableProduct = data.data;
    if (deletedProduct?.id && payload.section) {
      const backordersAvailability: BackordersAvailability = cloneDeep(
        yield select(selectBackordersAvailability)
      );
      const filteredBackordersAvailability = backordersAvailability[payload.section].content.filter(
        (product) => product.id !== deletedProduct?.id
      );
      backordersAvailability[payload.section].content = filteredBackordersAvailability;
      yield put(saveBackordersAvailability(backordersAvailability));
    }
  } catch (error) {
    yield put(setBackordersAvailabilityStatus({ status: "ERROR" }));
    yield put(handleError(error));
  }
}

function* deleteInventoryTrackingSaga({
  payload,
}: PayloadAction<DeleteInventoryTrackingPayload>): SagaIterator {
  try {
    yield put(setLoadingAvailability(true));

    const { data } = yield call(
      messagesService.deleteInventoryTracking,
      payload.inventoryTrackingId
    );
    const deletedProduct: AvailableProduct = data.data;
    if (deletedProduct?.id) {
      // const currentDoor: Door = yield select(selectActiveDoor);

      // yield put(
      //   updateProductAvailabilityStatus({
      //     doorId: currentDoor.orgentityName,
      //     availabilityStatus: { [payload.uniqueID as string]: "BACKORDER_NOT_FOLLOWING" },
      //   })
      // );
      if (payload.isCart) {
        const backorderTrackedItems = {
          ...(yield select(selectBackorderTrackedItems)),
        };
        if (backorderTrackedItems[payload.uniqueID as string]) {
          delete backorderTrackedItems[payload.uniqueID as string];
        }
        yield put(saveBackorderTrackedItems(backorderTrackedItems));
      } else {
        yield put(
          saveBackorderProductAvailability({
            [payload.uniqueID as string]: "BACKORDER_NOT_FOLLOWING",
          })
        );
      }
    }
  } catch (error) {
    yield put(setBackordersAvailabilityStatus({ status: "ERROR" }));
    yield put(handleError(error));
  } finally {
    yield put(setLoadingAvailability(false));
  }
}

function* addInventoryTrackingSaga({
  payload,
}: PayloadAction<AddInventoryTrackingPayload>): SagaIterator {
  try {
    yield put(setLoadingAvailability(true));
    const { data } = yield call(messagesService.addInventoryTracking, payload.id);
    const followedProduct: AvailableProduct = data.data;

    if (followedProduct) {
      // const currentDoor: Door = yield select(selectActiveDoor);

      // yield put(
      //   updateProductAvailabilityStatus({
      //     doorId: currentDoor.orgentityName,
      //     availabilityStatus: {
      //       [followedProduct.catentryId]: "BACKORDER_FOLLOWING",
      //       trackingId: followedProduct.id,
      //     },
      //   })
      // );
      if (payload.isCart) {
        const backorderTrackedItems = {
          ...(yield select(selectBackorderTrackedItems)),
        };
        backorderTrackedItems[followedProduct.catentryId] = followedProduct.id;
        yield put(saveBackorderTrackedItems(backorderTrackedItems));
      } else {
        yield put(
          saveBackorderProductAvailability({
            [followedProduct.catentryId]: "BACKORDER_FOLLOWING",
            trackingId: followedProduct.id,
          })
        );
      }
    }
  } catch (error) {
    yield put(setBackordersAvailabilityStatus({ status: "ERROR" }));
    yield put(handleError(error));
  } finally {
    yield put(setLoadingAvailability(false));
  }
}

function* areProductsTrackedSaga({ payload }: PayloadAction<string[]>): SagaIterator {
  try {
    const { data } = yield call(messagesService.areProductsTracked, payload);
  } catch (error) {
    yield put(setBackordersAvailabilityStatus({ status: "ERROR" }));
    yield put(handleError(error));
  }
}

export function* notificationsSaga(): SagaIterator {
  yield takeEvery(getNotificationsList.type, getNotificationsListSaga);
  yield takeEvery(getNotificationsPreferences.type, getNotificationsPreferencesSaga);
  yield takeEvery(updateNotificationsPreferences.type, updateNotificationsPreferencesSaga);
  yield takeEvery(markAsReadOrUnreadNotifications.type, markAsReadOrUnreadNotificationsSaga);
  yield takeEvery(markAsDeletedNotifications.type, markAsDeletedNotificationsSaga);
  yield takeEvery(getTotalNumberNotifications.type, getTotalNumberNotificationsSaga);
  yield takeEvery(getInventoryTracking.type, getInventoryTrackingSaga);
  yield takeEvery(deleteInventoryTracking.type, deleteInventoryTrackingSaga);
  yield takeEvery(deleteInventoryTrackingLine.type, deleteInventoryTrackingLineSaga);
  yield takeEvery(addInventoryTracking.type, addInventoryTrackingSaga);
  yield takeEvery(areProductsTracked.type, areProductsTrackedSaga);
}
