import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../storeConfig";

import {
  ConversationalAftersalesError,
  AftersalesState,
  ConversationalModelResult,
  UpcResult,
  ConversationalVariantResult,
  ConversationalModelOptions,
  SparePartsGroupedFamilies,
  SparePartsVariant,
  SparePartsPdpLoading,
  WarrantyInboxList,
  WarrantyInboxCount,
  WarrantyInboxDetails,
  WarrantyReturn,
  WarrantyReturnNotification,
  FastTrackParcelData,
  ReturnParcelData,
  PrintReturnParcel,
  ReturnParcelRequestStatus,
  WarrantyInboxFiltersStored,
  WarrantyIdentifierList,
} from "./aftersalesInterface";
import { Attachment } from "../catalogue/catalogueInterface";
import { Product, Sku, Variant } from "../../interfaces/productInterface";
import { ErrorObj, RequestStatus } from "../../interfaces/mainInterfaces";

import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import {
  mapConversationalModelOptions,
  mapConversationalVariantOptions,
} from "../../utils/aftersalesUtils";
import { emptyError } from "../../utils/consts";

const defaultAftersalesError = {
  upcResult: { ...emptyError },
  brandOptions: { ...emptyError },
  modelOptions: { ...emptyError },
  variantOptions: { ...emptyError },
};

type DefaultAftersalesErrorKeys = keyof typeof defaultAftersalesError;

const defaultSparePartsPdpLoading = {
  pdpModel: false,
  pdpVariant: false,
  pdpFamilies: false,
};

type DefaultSparePartsPdpLoadingKeys = keyof typeof defaultSparePartsPdpLoading;

const defaultWarrantyInboxFilters = {
  pageNumber: 1,
};

export const sliceName = "aftersales";
const initialState: AftersalesState = {
  /////////////////// conversational

  conversationalUpcResult: null, // results from UPC search
  conversationalPageSize: 1,
  conversationalBrands: [], // list of brands displayed in conversational
  conversationalModelResult: [], // list of models displayed in dropdown
  conversationalSelectedModel: null, // tile info for selected model
  conversationalVariantResult: [], // list of variants displayed in dropdown
  conversationalSelectedVariant: null, // tile info for selected variant
  conversationalError: { ...defaultAftersalesError },

  /////////////////// spare-parts PDP
  sparePartsPdpModel: null, // model info for spare parts PDP
  sparePartsPdpVariant: null, // variant info for spare parts PDP
  sparePartsPdpLoading: { ...defaultSparePartsPdpLoading }, // loading status for spare parts PDP
  // sparePartsPdpImagesCarousel: [], // images for carousel in spare parts PDP // TODO: spare parts carousel img
  sparePartsPdpTechDraft: [], // right-side image in spare parts PDP
  sparePartsPdpFamilies: [], // list of families from which to select part in spare parts PDP
  sparePartsPdpCurrentItems: {}, // list of parts for each family that has been called so far (indexed by familyId)
  sparePartsPdpFamiliesStatus: "IDLE",

  /////////////////// warranty inbox
  warrantyInboxList: null,
  warrantyInboxFilters: defaultWarrantyInboxFilters,
  warrantyInboxIdentifiers: [],
  warrantyInboxDetails: null,
  errorWarrantyInboxList: { ...emptyError },
  warrantyInboxListStatus: "IDLE",
  warrantyInboxDetailsStatus: "IDLE",
  warrantyReturnStatus: "IDLE",
  warrantyReturnNotificationStatus: "IDLE",
  warrantyInboxIdentifiersStatus: "IDLE",
  warrantySendActionStatus: "IDLE",
  warrantyInboxAndRequestServicesCount: null,
  warrantyReturn: null,
  warrantyReturnNotification: null,
  skuDetailByPartNumber: null,
  alternativeDetailByPartNumber: null,
  warrantyInboxCountStatus: "IDLE",

  ///////////////// parcel
  fastTrackParcelData: null,
  fastTrackParcelStatus: "IDLE",
  printReturnParcel: {
    open: false,
    warrantyId: null,
  },
  returnParcelDataStatus: [],
  isReturnParcelLoading: false,
  returnParcelData: null,
  productDetailsReturnParcel: [],
};

export const aftersalesSlice = createSlice({
  name: sliceName,
  initialState,

  reducers: {
    /////////////////// conversational
    saveAftersalesConversationalUpc: (state, action: PayloadAction<UpcResult | null>) => {
      if (action.payload) {
        state.conversationalUpcResult = action.payload;
        state.conversationalError.upcResult = { ...emptyError }; // clean error if present
      } else {
        // if no results were found, show error
        state.conversationalError.upcResult = { type: "no-results", status: true };
        state.conversationalUpcResult = null;
      }
    },

    saveAftersalesConversationalBrands: (state, action: PayloadAction<CustomOptions[]>) => {
      if (action.payload) {
        state.conversationalBrands = action.payload;
        state.conversationalError.brandOptions = { ...emptyError }; // clean error if present
      } else {
        // if no results were found, show error
        state.conversationalError.brandOptions = { type: "no-results", status: true };
        state.conversationalBrands = [];
      }
    },

    saveAftersalesConversationalModelOptions: (
      state,
      action: PayloadAction<ConversationalModelResult[]>
    ) => {
      if (action.payload) {
        state.conversationalModelResult = action.payload;
        state.conversationalError.modelOptions = { ...emptyError }; // clean error if present
      } else {
        // if no results were found, show error
        state.conversationalError.modelOptions = { type: "no-results", status: true };
        state.conversationalModelResult = [];
      }
    },

    saveAftersalesVariantOptions: (state, action: PayloadAction<ConversationalVariantResult[]>) => {
      if (action.payload) {
        state.conversationalVariantResult = action.payload;
        state.conversationalError.variantOptions = { ...emptyError }; // clean error if present

        // if the selected variant is not contained in the current variant options, clear it
        const isInOptions = action.payload.find(
          (_) => _.uniqueID === state.conversationalSelectedVariant?.uniqueID
        );
        if (!isInOptions) state.conversationalSelectedVariant = null;
      } else {
        // if no results were found, show error
        state.conversationalError.variantOptions = { type: "no-results", status: true };
        state.conversationalVariantResult = [];
      }
    },

    saveAftersalesConversationalSelectedModel: (state, action: PayloadAction<Product | null>) => {
      state.conversationalSelectedModel = action.payload;
    },

    saveAftersalesConversationalSelectedVariant: (state, action: PayloadAction<Variant | null>) => {
      state.conversationalSelectedVariant = action.payload;
    },

    updateAftersalesConversationalStep: (state, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 0:
          state.conversationalBrands = [];
          break;
        case 1:
          state.conversationalUpcResult = null;
          state.conversationalModelResult = [];
          state.conversationalVariantResult = [];
          state.conversationalSelectedModel = null;
          state.conversationalSelectedVariant = null;
          state.conversationalError = {
            ...state.conversationalError,
            upcResult: { ...emptyError },
            modelOptions: { ...emptyError },
            variantOptions: { ...emptyError },
          };
          break;
        case 2:
          state.conversationalVariantResult = [];
          state.conversationalSelectedModel = null;
          state.conversationalSelectedVariant = null;
          state.conversationalError = state.conversationalError = {
            ...state.conversationalError,
            upcResult: { ...emptyError },
            variantOptions: { ...emptyError },
          };
          break;
        default:
          break;
      }
    },

    saveAftersalesConversationalError: (
      state,
      action: PayloadAction<{ value: DefaultAftersalesErrorKeys; error: ErrorObj }>
    ) => {
      state.conversationalError = {
        ...state.conversationalError,
        [action.payload.value]: action.payload.error,
      };
    },

    clearAftersalesConversationalError: (state, action: PayloadAction<string>) => {
      state.conversationalError = {
        ...state.conversationalError,
        [action.payload]: { ...emptyError },
      };
    },

    /////////////////// spare-parts PDP
    saveSparePartsPdpModel: (state, action: PayloadAction<Product>) => {
      state.sparePartsPdpModel = action.payload;
      state.sparePartsPdpLoading = { ...state.sparePartsPdpLoading, pdpModel: false };
    },

    saveSparePartsPdpVariant: (state, action: PayloadAction<Variant>) => {
      state.sparePartsPdpVariant = action.payload;
      state.sparePartsPdpLoading = { ...state.sparePartsPdpLoading, pdpVariant: false };
    },

    // saveSparePartsPdpImagesCarousel: (state, action: PayloadAction<Attachment[]>) => { // TODO: spare parts carousel img
    //   state.sparePartsPdpImagesCarousel = action.payload;
    // },

    saveSparePartsPdpTechDraft: (state, action: PayloadAction<Attachment[]>) => {
      state.sparePartsPdpTechDraft = action.payload;
    },

    saveSparePartsPdpFamilies: (state, action: PayloadAction<SparePartsGroupedFamilies[]>) => {
      state.sparePartsPdpFamilies = action.payload;
      state.sparePartsPdpLoading = { ...state.sparePartsPdpLoading, pdpFamilies: false };
    },

    saveSparePartsPdpCurrentItems: (state, action: PayloadAction<SparePartsVariant[] | string>) => {
      // if payload is string it means the call failed, and no results where found
      if (typeof action.payload === "string")
        state.sparePartsPdpCurrentItems = {
          ...state.sparePartsPdpCurrentItems,
          [action.payload]: [],
        };
      else
        state.sparePartsPdpCurrentItems = {
          ...state.sparePartsPdpCurrentItems,
          [action.payload[0].idFamily]: action.payload,
        };
    },

    setSparePartsByFamilyStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.sparePartsPdpFamiliesStatus = action.payload;
    },

    saveSparePartsPdpLoading: (
      state,
      action: PayloadAction<{ type: DefaultSparePartsPdpLoadingKeys; value: boolean }>
    ) => {
      state.sparePartsPdpLoading = {
        ...state.sparePartsPdpLoading,
        [action.payload.type]: action.payload.value,
      };
    },

    resetSparePartsForDoorChange: (state) => {
      state.sparePartsPdpFamilies = [];
      state.sparePartsPdpCurrentItems = {};
      state.sparePartsPdpLoading = {
        ...state.sparePartsPdpLoading,
        pdpFamilies: true,
      };
    },

    resetSparePartsPdp: (state) => {
      state.sparePartsPdpModel = null;
      state.sparePartsPdpVariant = null;
      state.sparePartsPdpLoading = { ...defaultSparePartsPdpLoading };
      // state.sparePartsPdpImagesCarousel = []; // TODO: spare parts carousel img
      state.sparePartsPdpTechDraft = [];
      state.sparePartsPdpFamilies = [];
      state.sparePartsPdpCurrentItems = {};
    },

    /////////////////// warranty inbox & returns

    saveWarrantyInboxList: (state, action: PayloadAction<WarrantyInboxList | null>) => {
      state.warrantyInboxList = action.payload;
    },

    saveWarrantyInboxFilters: (state, action: PayloadAction<WarrantyInboxFiltersStored>) => {
      state.warrantyInboxFilters = action.payload;
    },

    saveWarrantyInboxIdentifiers: (state, action: PayloadAction<WarrantyIdentifierList[]>) => {
      state.warrantyInboxIdentifiers = action.payload;
    },

    saveWarrantyInboxCount: (state, action: PayloadAction<WarrantyInboxCount | null>) => {
      state.warrantyInboxAndRequestServicesCount = action.payload;
    },

    saveWarrantyInboxDetails: (state, action: PayloadAction<WarrantyInboxDetails | null>) => {
      state.warrantyInboxDetails = action.payload;
    },

    saveWarrantyReturn: (state, action: PayloadAction<WarrantyReturn | null>) => {
      state.warrantyReturn = action.payload;
    },

    saveWarrantyReturnNotification: (
      state,
      action: PayloadAction<WarrantyReturnNotification | null>
    ) => {
      state.warrantyReturnNotification = action.payload;
    },

    setErrorWarrantyInboxList: (state, action: PayloadAction<ErrorObj>) => {
      state.errorWarrantyInboxList = action.payload;
    },

    setWarrantyInboxListStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyInboxListStatus = action.payload;
    },

    setWarrantyInboxDetailsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyInboxDetailsStatus = action.payload;
    },

    setWarrantyReturnStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyReturnStatus = action.payload;
    },

    setWarrantyReturnNotificationStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyReturnNotificationStatus = action.payload;
    },

    setWarrantyInboxIdentifiersStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyInboxIdentifiersStatus = action.payload;
    },

    setWarrantyInboxCountStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantyInboxCountStatus = action.payload;
    },

    setWarrantySendActionStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.warrantySendActionStatus = action.payload;
    },

    saveWarrantyProductDetailByPartNumber: (state, action: PayloadAction<Sku | null>) => {
      state.skuDetailByPartNumber = action.payload;
    },

    saveWarrantyAlternativeDetailByPartNumber: (state, action: PayloadAction<Sku | null>) => {
      state.alternativeDetailByPartNumber = action.payload;
    },

    resetWarrantyFilters: (state) => {
      state.warrantyInboxFilters = defaultWarrantyInboxFilters;
    },

    resetWarrantyInbox: (state) => {
      state.warrantyInboxIdentifiers = [];
      state.warrantyInboxIdentifiersStatus = "IDLE";
      state.errorWarrantyInboxList = { status: false, type: "" };
      state.warrantyInboxListStatus = "IDLE";
      state.warrantyInboxList = null;
      state.warrantyInboxCountStatus = "IDLE";
    },

    ///////////////////// parcel
    setFastTrackParcelData: (state, { payload }: PayloadAction<FastTrackParcelData | null>) => {
      state.fastTrackParcelData = payload;
    },

    saveFastTrackParcelStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.fastTrackParcelStatus = payload;
    },

    resetFastTrackParcel: (state) => {
      state.fastTrackParcelStatus = "IDLE";
      state.fastTrackParcelData = null;
    },

    savePrintReturnParcel: (state, { payload }: PayloadAction<PrintReturnParcel>) => {
      state.printReturnParcel = payload;
    },

    saveReturnParcelDataStatus: (state, { payload }: PayloadAction<ReturnParcelRequestStatus>) => {
      const index = state.returnParcelDataStatus.findIndex((_) => payload.id === _.id);
      if (index !== -1) {
        state.returnParcelDataStatus[index] = payload;
      } else {
        state.returnParcelDataStatus.push(payload);
      }
    },

    saveIsReturnParcelLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isReturnParcelLoading = payload;
    },

    setReturnParcelData: (state, { payload }: PayloadAction<ReturnParcelData | null>) => {
      state.returnParcelData = payload;
    },

    saveProductDetailsReturnParcel: (state, { payload }: PayloadAction<Sku>) => {
      state.productDetailsReturnParcel.push(payload);
    },

    resetReturnParcel: (state) => {
      state.productDetailsReturnParcel = [];
      state.returnParcelDataStatus = [];
      state.returnParcelData = null;
      state.isReturnParcelLoading = false;
    },

    resetAftersalesState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  resetAftersalesState,

  /////////////////// conversational
  saveAftersalesConversationalUpc,
  saveAftersalesConversationalBrands,
  saveAftersalesConversationalModelOptions,
  saveAftersalesVariantOptions,
  saveAftersalesConversationalSelectedModel,
  saveAftersalesConversationalSelectedVariant,
  updateAftersalesConversationalStep,
  saveAftersalesConversationalError,
  clearAftersalesConversationalError,

  /////////////////// spare-parts PDP
  saveSparePartsPdpModel,
  saveSparePartsPdpVariant,
  saveSparePartsPdpLoading,
  // saveSparePartsPdpImagesCarousel, // TODO: spare parts carousel img
  saveSparePartsPdpTechDraft,
  saveSparePartsPdpFamilies,
  setSparePartsByFamilyStatus,
  saveSparePartsPdpCurrentItems,
  resetSparePartsForDoorChange,
  resetSparePartsPdp,

  /////////////////// warranty inbox
  saveWarrantyInboxList,
  saveWarrantyInboxFilters,
  saveWarrantyInboxIdentifiers,
  saveWarrantyInboxDetails,
  setErrorWarrantyInboxList,
  saveWarrantyInboxCount,
  saveWarrantyReturn,
  saveWarrantyReturnNotification,
  setWarrantyInboxListStatus,
  setWarrantyInboxDetailsStatus,
  setWarrantyReturnStatus,
  setWarrantyReturnNotificationStatus,
  setWarrantyInboxIdentifiersStatus,
  setWarrantySendActionStatus,
  saveWarrantyProductDetailByPartNumber,
  saveWarrantyAlternativeDetailByPartNumber,
  resetWarrantyInbox,
  setWarrantyInboxCountStatus,
  ///////////////// parcel
  setFastTrackParcelData,
  saveFastTrackParcelStatus,
  savePrintReturnParcel,
  resetFastTrackParcel,
  saveReturnParcelDataStatus,
  setReturnParcelData,
  saveProductDetailsReturnParcel,
  resetReturnParcel,
  resetWarrantyFilters,
  saveIsReturnParcelLoading,
} = aftersalesSlice.actions;

/////////////////// conversational
export const selectConversationalPageSize = (state: RootState): number => {
  return state.aftersales.conversationalPageSize;
};

export const selectConversationalUpcResult = (state: RootState): UpcResult | null => {
  return state.aftersales.conversationalUpcResult;
};

export const selectConversationalBrands = (state: RootState): CustomOptions[] => {
  return state.aftersales.conversationalBrands;
};

export const selectConversationalModelResult = (state: RootState): ConversationalModelResult[] => {
  return state.aftersales.conversationalModelResult;
};

export const selectConversationalModelOptions = createSelector(
  selectConversationalModelResult,
  (modelResult: ConversationalModelResult[]): ConversationalModelOptions[] => {
    return mapConversationalModelOptions(modelResult);
  }
);

export const selectConversationalSelectedModel = (state: RootState): Product | null => {
  return state.aftersales.conversationalSelectedModel;
};

export const selectConversationalVariantResult = (
  state: RootState
): ConversationalVariantResult[] => {
  return state.aftersales.conversationalVariantResult;
};

export const selectConversationalVariantOptions = createSelector(
  selectConversationalVariantResult,
  (variantResult: ConversationalVariantResult[]): CustomOptions[] => {
    return mapConversationalVariantOptions(variantResult);
  }
);

export const selectConversationalSelectedVariant = (state: RootState): Variant | null => {
  return state.aftersales.conversationalSelectedVariant;
};

export const selectConversationalError = (state: RootState): ConversationalAftersalesError => {
  return state.aftersales.conversationalError;
};

/////////////////// spare-parts PDP
export const selectSparePartsPdpModel = (state: RootState): Product | null => {
  return state.aftersales.sparePartsPdpModel;
};

export const selectSparePartsPdpVariant = (state: RootState): Variant | null => {
  return state.aftersales.sparePartsPdpVariant;
};

// export const selectSparePartsPdpImagesCarousel = (state: RootState): Attachment[] => { // TODO: spare parts carousel img
//   return state.aftersales.sparePartsPdpImagesCarousel;
// };

export const selectSparePartsPdpTechDraft = (state: RootState): Attachment[] => {
  return state.aftersales.sparePartsPdpTechDraft;
};

export const selectSparePartsPdpLoading = (state: RootState): SparePartsPdpLoading => {
  return state.aftersales.sparePartsPdpLoading;
};

export const selectSparePartsPdpFamilies = (state: RootState): SparePartsGroupedFamilies[] => {
  return state.aftersales.sparePartsPdpFamilies;
};

export const selectSparePartsPdpCurrentItems = (
  state: RootState
): { [key: string]: SparePartsVariant[] } => {
  return state.aftersales.sparePartsPdpCurrentItems;
};

export const selectSparePartsByFamilyStatus = (state: RootState): RequestStatus => {
  return state.aftersales.sparePartsPdpFamiliesStatus;
};

/////////////////// warranty inbox
export const selectWarrantyInboxList = (state: RootState): WarrantyInboxList | null => {
  return state.aftersales.warrantyInboxList;
};
export const selectWarrantyInboxFilters = (state: RootState): WarrantyInboxFiltersStored => {
  return state.aftersales.warrantyInboxFilters;
};

export const selectWarrantyInboxDetails = (state: RootState): WarrantyInboxDetails | null => {
  return state.aftersales.warrantyInboxDetails;
};

export const selectWarrantyInboxIdentifiers = (state: RootState): WarrantyIdentifierList[] => {
  return state.aftersales.warrantyInboxIdentifiers;
};

export const selectWarrantyInboxListStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyInboxListStatus;
};

export const selectWarrantyInboxIdentifiersStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyInboxIdentifiersStatus;
};

export const selectWarrantyInboxDetailsStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyInboxDetailsStatus;
};

export const selectWarrantyReturnStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyReturnStatus;
};

export const selectWarrantyReturnNotificationStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyReturnNotificationStatus;
};

export const selectWarrantySendActionStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantySendActionStatus;
};

export const selectWarrantyAndRequestServiceCount = (
  state: RootState
): WarrantyInboxCount | null => {
  return state.aftersales.warrantyInboxAndRequestServicesCount;
};

export const selectWarrantyReturn = (state: RootState): WarrantyReturn | null => {
  return state.aftersales.warrantyReturn;
};

export const selectWarrantyReturnNotification = (
  state: RootState
): WarrantyReturnNotification | null => {
  return state.aftersales.warrantyReturnNotification;
};

export const selectWarrantySkuDetailByPartNumber = (state: RootState): Sku | null => {
  return state.aftersales.skuDetailByPartNumber;
};

export const selectWarrantyAlternativeDetailByPartNumber = (state: RootState): Sku | null => {
  return state.aftersales.alternativeDetailByPartNumber;
};

export const selectWarrantyInboxCountStatus = (state: RootState): RequestStatus => {
  return state.aftersales.warrantyInboxCountStatus;
};

///////////////////////// parcel
export const selectFastTrackParcelData = (state: RootState): FastTrackParcelData | null => {
  return state.aftersales.fastTrackParcelData;
};

export const selectFastTrackParcelStatus = (state: RootState): RequestStatus => {
  return state.aftersales.fastTrackParcelStatus;
};

export const selectPrintReturnParcel = (state: RootState): PrintReturnParcel => {
  return state.aftersales.printReturnParcel;
};

export const selectReturnParcelData = (state: RootState): ReturnParcelData | null => {
  return state.aftersales.returnParcelData;
};

export const selectProductDetailsReturnParcel = (state: RootState): Sku[] => {
  return state.aftersales.productDetailsReturnParcel ?? [];
};

export const selectReturnParcelDataStatus = (state: RootState): string[] => {
  return state.aftersales.returnParcelDataStatus.map((_) => _.status);
};

export const selectIsReturnParcelLoading = (state: RootState): boolean => {
  return state.aftersales.isReturnParcelLoading;
};

export default aftersalesSlice.reducer;
