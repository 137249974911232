import { SagaIterator } from "redux-saga";
import { put, takeEvery, call, select } from "@redux-saga/core/effects";
import { createAction, PayloadAction } from "@reduxjs/toolkit";

import {
  saveAlternativeModelsCatalogue,
  saveAlternativeVariants,
  saveAvailableInActiveCatalog,
  saveLoadingAltModelsCatalogue,
  saveLoadingAltVariants,
  saveLoadingWarrantyProductDetails,
  saveModelDetailsWarranty,
  saveWarrantyIdentifier,
  saveWarrantyWizardOptionsStepDamageInfo,
  saveWarrantyWizardOptionsStepDeliveryAddress,
  saveWarrantyWizardProductDetails,
  saveWarrantyWizardRecap,
  saveWarrantyWizardStepStructure,
  selectWarrantyProductPerPage,
  setIsStepLoading,
  sliceName,
} from "./warrantyWizardSlice";
import {
  GetAlternativeModelPayload,
  SubmitWarrantyDamagesPayload,
  WarrantyWizardParameters,
  SubmitNotesPayload,
  WarrantyToCRMPayload,
  WarrantyWizardProductParameters,
  SubmitWarrantySelectAlternativeActionPayload,
  GetWarrantyDamagesPayload,
  SubmitMoreInformationStepPayload,
  GetAlternativeVariantServicePayload,
  WarrantyWizardDeliveryAddressPayload,
} from "./warrantyWizardInterface";
import warrantyWizardService from "./warrantyWizardService";
import profileService from "../profile/profileServices";
import { handleError } from "../store/storeSagas";
import { selectIsMultidoor, selectStoreIdentifier } from "../user/userSlice";

import { mapSkuObj, mapVariantsArray } from "../../utils/productUtils";
import {
  addPricesToPlpVariants,
  mapPlpCatalogueNoAdv,
  mapPriceResponse,
} from "../../utils/catalogueUtils";
import {
  mapModelDetailsWarranty,
  mapSubmitWarrantyDamagesResponse,
  mapWarrantyAddress,
  warrantyWizardOptionsStepDamageInfo,
} from "../../utils/warrantyWizardUtils";
import {
  selectHasPricePrivilege,
  selectMultidoorHasPricePrivilege,
} from "../catalogue/catalogueSlice";
import { Variant } from "../../interfaces/productInterface";
import catalogueService from "../catalogue/catalogueService";

/* ACTIONS */

export const getWarrantyWizardDetails = createAction<WarrantyWizardProductParameters>(
  sliceName + "/getWarrantyWizardDetails"
);

export const getWarrantyWizardDamages = createAction<GetWarrantyDamagesPayload>(
  sliceName + "/getWarrantyWizardDamages"
);

export const submitWarrantyDamages = createAction<SubmitWarrantyDamagesPayload>(
  sliceName + "/submitWarrantyDamages"
);

export const getAlternativeModel = createAction<GetAlternativeModelPayload>(
  sliceName + "/getAlternativeModel"
);

export const getAlternativeVariants = createAction<GetAlternativeVariantServicePayload>(
  sliceName + "/getAlternativeVariants"
);

export const submitWarrantySelectAlternativeAction = createAction<SubmitWarrantySelectAlternativeActionPayload>(
  sliceName + "/submitWarrantySelectAlternativeAction"
);

export const submitWarrantyNoteStep = createAction<SubmitNotesPayload>(
  sliceName + "/submitWarrantyNoteStep"
);

export const getWarrantyShippingAddresses = createAction<string[]>(
  sliceName + "/getWarrantyShippingAddresses"
);

export const submitWarrantyShippingDetails = createAction<WarrantyWizardDeliveryAddressPayload>(
  sliceName + "/submitWarrantyShippingDetails"
);

export const getWarrantyRecap = createAction<WarrantyWizardParameters>(
  sliceName + "/getWarrantyRecap"
);

export const submitWarrantyToCRM = createAction<WarrantyToCRMPayload>(
  sliceName + "/submitWarrantyToCRM"
);

export const getWarrantyInformationStep = createAction<WarrantyWizardParameters>(
  sliceName + "/getWarrantyInformationStep"
);

export const submitWarrantyInformationStep = createAction<SubmitMoreInformationStepPayload>(
  sliceName + "/submitWarrantyInformationStep"
);

export const getWarrantyAlternativeWizard = createAction<WarrantyWizardParameters>(
  sliceName + "/getWarrantyAlternativeWizard"
);

/* SAGAS */

/**
 * Get details regarding the product selected for the warranty request
 *
 * @param {PayloadAction<WarrantyWizardParameters>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyWizardDetailsSaga(
  action: PayloadAction<WarrantyWizardProductParameters>
): SagaIterator {
  try {
    yield put(saveLoadingWarrantyProductDetails("LOADING"));
    const { data } = yield call(warrantyWizardService.getWarrantyDetails, {
      ...action.payload,
      catalogType: "aftersales",
    });
    const productDetails =
      data?.data?.catalogEntryView.length > 0 && mapSkuObj(data.data.catalogEntryView[0]);
    const availableInActiveCatalog =
      data?.data?.catalogEntryView?.[0]?.availableInActiveCatalog === "true";

    if (productDetails) {
      yield put(saveWarrantyWizardProductDetails(productDetails));
      yield put(saveAvailableInActiveCatalog(availableInActiveCatalog));
      yield put(saveLoadingWarrantyProductDetails("SUCCESS"));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardProductDetails(null));
    yield put(saveAvailableInActiveCatalog(null));
    yield put(saveLoadingWarrantyProductDetails("ERROR"));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// DAMAGE INFO STEP ///////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 *  Provides digital warranty damages
 *
 * @param {PayloadAction<WarrantyWizardProductParameters>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyWizardDamagesSaga(
  action: PayloadAction<GetWarrantyDamagesPayload>
): SagaIterator {
  try {
    const { data } = yield call(warrantyWizardService.getWarrantyDamages, action.payload);
    const damageInfo = warrantyWizardOptionsStepDamageInfo(data.output);
    if (damageInfo) yield put(saveWarrantyWizardOptionsStepDamageInfo(damageInfo));
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Submit digital warranty damages
 *
 * @param {PayloadAction<SubmitWarrantyDamagesPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantyDamagesSaga(
  action: PayloadAction<SubmitWarrantyDamagesPayload>
): SagaIterator {
  try {
    yield put(setIsStepLoading(true));

    const callback = action.payload.params.callback; // save callback function
    delete action.payload.params.callback; // delete it from service payload

    const { data } = yield call(warrantyWizardService.submitWarrantyDamages, action.payload);

    if (data?.output?.warrantyIdentifier)
      yield put(saveWarrantyIdentifier(data.output.warrantyIdentifier));

    const stepStructure = mapSubmitWarrantyDamagesResponse(data?.output);

    if (stepStructure) {
      yield put(saveWarrantyWizardStepStructure(stepStructure));
      callback && callback(); // execute callback, if present
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardStepStructure([]));
  } finally {
    yield put(setIsStepLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ALTERNATIVE STEPS /////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get alternative models
 *
 * @param {PayloadAction<GetAlternativeModelPayload>} action
 * @return {*}  {SagaIterator}
 */
function* getAlternativeModelSaga(action: PayloadAction<GetAlternativeModelPayload>): SagaIterator {
  try {
    yield put(saveLoadingAltModelsCatalogue("LOADING"));
    const productPerPage = yield select(selectWarrantyProductPerPage);

    const params = {
      ...action.payload,
      pageSize: productPerPage.toString(),
    };
    const { data } = yield call(warrantyWizardService.getWarrantyAlternativeModel, params);
    const plpCatalogue = data?.data && mapPlpCatalogueNoAdv(data.data);
    if (plpCatalogue) yield put(saveAlternativeModelsCatalogue(plpCatalogue)); // if present, save results (and stop loading)

    yield put(saveLoadingAltModelsCatalogue("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveAlternativeModelsCatalogue(null)); // save empty catalogue
    yield put(saveLoadingAltModelsCatalogue("ERROR"));
  }
}

/**
 * Get variants for single product in expanded tile popup
 * The variants are paginated.
 *
 * @param {PayloadAction<PlpVariantsOptions>} action
 * @return {*}  {SagaIterator}
 */
function* getAlternativeVariantsSaga(
  action: PayloadAction<GetAlternativeVariantServicePayload>
): SagaIterator {
  try {
    ////////////////////////////////////// get PLP variants
    yield put(saveLoadingAltVariants("LOADING"));
    const { data } = yield call(
      warrantyWizardService.getWarrantyAlternativeVariant,
      action.payload
    );

    const variants = mapVariantsArray(data?.data?.catalogEntryView);
    const variantsResults = {
      items: variants,
      recordSetCount: data?.data?.recordSetCount,
      recordSetTotal: data?.data?.recordSetTotal,
    };

    ////////////////////////////////////// get prices
    const isMultidoor = yield select(selectIsMultidoor);
    let hasPricePrivilege = false;
    if (isMultidoor) {
      hasPricePrivilege = yield select(selectMultidoorHasPricePrivilege);
    } else {
      hasPricePrivilege = yield select(selectHasPricePrivilege);
    }
    if (hasPricePrivilege) {
      const priceIds: string[] = []; // get ids
      variants.forEach((variant: Variant) => {
        variant.uniqueID && priceIds.push(variant.uniqueID);
      });

      const { data: price } = yield call(catalogueService.getPriceService, priceIds);
      const priceData = mapPriceResponse(price.data); // map response into a GetPriceResult[] array
      const newResults = addPricesToPlpVariants(variantsResults, priceData); // add prices to current search results
      yield put(saveAlternativeVariants(newResults)); // save results
    } else {
      yield put(saveAlternativeVariants(variantsResults)); // save results
    }
    const modelDetailsWarranty = mapModelDetailsWarranty(data?.data?.catalogEntryView);
    if (modelDetailsWarranty) yield put(saveModelDetailsWarranty(modelDetailsWarranty));
    yield put(saveLoadingAltVariants("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveModelDetailsWarranty(null));
    yield put(saveLoadingAltVariants("ERROR"));
  }
}

/**
 * Select alternative sku
 *
 * @param {PayloadAction<SubmitWarrantySelectAlternativeActionPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantySelectAlternativeActionSaga(
  action: PayloadAction<SubmitWarrantySelectAlternativeActionPayload>
): SagaIterator {
  try {
    yield put(setIsStepLoading(true));

    const callback = action.payload.params.callback; // save callback function
    delete action.payload.params.callback; // delete it from service payload

    const { data } = yield call(
      warrantyWizardService.submitWarrantySelectAlternativeAction,
      action.payload
    );

    callback && callback(data?.output?.actionResult === "OK"); // execute callback, if present
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setIsStepLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// NOTES STEP //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Submit note step
 *
 * @param {PayloadAction<SubmitNotesPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantyNoteStepSaga(action: PayloadAction<SubmitNotesPayload>): SagaIterator {
  try {
    yield put(setIsStepLoading(true));

    const callback = action.payload.params.callback; // save callback function
    delete action.payload.params.callback; // delete it from service payload

    const { data } = yield call(warrantyWizardService.submitWarrantyNoteStep, action.payload);

    callback && callback(data?.output?.submitStatus === "OK"); // execute callback, if present
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setIsStepLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// SHIPPING DETAILS STEP ////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get options for select warranty shipping addresses
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyShippingAddressesSaga(action: PayloadAction<string[]>): SagaIterator {
  try {
    const storeIdentifier = yield select(selectStoreIdentifier);

    const { data } = yield call(profileService.getProfileAddresses, {
      storeIdentifier,
      doorIds: action.payload,
    });

    if (data?.data?.addressList) {
      const addressList = mapWarrantyAddress(data?.data?.addressList);
      if (addressList) yield put(saveWarrantyWizardOptionsStepDeliveryAddress(addressList));
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardOptionsStepDeliveryAddress([]));
  }
}

/**
 * Submit warranty shipping details
 *
 * @param {PayloadAction<WarrantyWizardParameters>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantyShippingDetailsSaga(
  action: PayloadAction<WarrantyWizardDeliveryAddressPayload>
): SagaIterator {
  try {
    yield put(setIsStepLoading(true));

    const callback = action.payload.callback; // save callback function
    delete action.payload.callback; // delete it from service payload

    const { data } = yield call(
      warrantyWizardService.submitWarrantyShippingDetails,
      action.payload
    );

    callback && callback(data?.output?.warrantyShippingDetailsReceived === "OK"); // execute callback, if present
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setIsStepLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// RECAP STEP //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get warranty recap
 *
 * @param {PayloadAction<WarrantyWizardParameters>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyRecapSaga(action: PayloadAction<WarrantyWizardParameters>): SagaIterator {
  try {
    const { data } = yield call(warrantyWizardService.getWarrantyRecap, action.payload);
    if (data?.output) yield put(saveWarrantyWizardRecap(data.output));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardRecap(null));
  }
}

/**
 * Send warranty to CRM
 *
 * @param {PayloadAction<WarrantyToCRMPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantyToCRMSaga(action: PayloadAction<WarrantyToCRMPayload>): SagaIterator {
  yield put(setIsStepLoading(true));

  const callback = action.payload.params.callback; // save callback function
  delete action.payload.params.callback; // delete it from service payload
  try {
    const { data } = yield call(warrantyWizardService.submitWarrantyToCRM, action.payload);

    callback && callback(data?.output?.submitStatus === "OK"); // execute callback, if present
  } catch (error) {
    yield put(handleError(error));
    if (callback) callback(true);
  } finally {
    yield put(setIsStepLoading(false));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// STATUS ACTIONS ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Get step information when calling UPLOAD_NEW_ATTACHMENT status action
 *
 * @param {PayloadAction<WarrantyWizardParameters>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyInformationStepSaga(
  action: PayloadAction<WarrantyWizardParameters>
): SagaIterator {
  try {
    const { data } = yield call(warrantyWizardService.getWarrantyInformationStep, action.payload);
    const stepStructure = mapSubmitWarrantyDamagesResponse(data?.output);

    if (stepStructure) yield put(saveWarrantyWizardStepStructure(stepStructure));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardStepStructure([]));
  }
}

/**
 * Submit note step
 *
 * @param {PayloadAction<SubmitNotesPayload>} action
 * @return {*}  {SagaIterator}
 */
function* submitWarrantyInformationStepSaga(
  action: PayloadAction<SubmitMoreInformationStepPayload>
): SagaIterator {
  try {
    yield put(setIsStepLoading(true));

    const callback = action.payload.params.callback; // save callback function
    delete action.payload.params.callback; // delete it from service payload

    const { data } = yield call(
      warrantyWizardService.submitWarrantyInformationStep,
      action.payload
    );

    callback && callback(data?.output?.submitStatus === "OK"); // execute callback, if present
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setIsStepLoading(false));
  }
}

/**
 * Get step information when calling SELECT_AN_ALTERNATIVE_PRODUCT status action
 *
 * @param {PayloadAction<WarrantyWizardParameters>} action
 * @return {*}  {SagaIterator}
 */
function* getWarrantyAlternativeWizardSaga(
  action: PayloadAction<WarrantyWizardParameters>
): SagaIterator {
  try {
    const { data } = yield call(warrantyWizardService.getWarrantyAlternativeWizard, action.payload);
    const stepStructure = mapSubmitWarrantyDamagesResponse(data?.output);

    if (stepStructure) yield put(saveWarrantyWizardStepStructure(stepStructure));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveWarrantyWizardStepStructure([]));
  }
}

export function* warrantyWizardSaga(): SagaIterator {
  yield takeEvery(getWarrantyWizardDetails.type, getWarrantyWizardDetailsSaga);
  yield takeEvery(getWarrantyWizardDamages.type, getWarrantyWizardDamagesSaga);
  yield takeEvery(submitWarrantyDamages.type, submitWarrantyDamagesSaga);
  yield takeEvery(getAlternativeModel.type, getAlternativeModelSaga);
  yield takeEvery(getAlternativeVariants.type, getAlternativeVariantsSaga);
  yield takeEvery(
    submitWarrantySelectAlternativeAction.type,
    submitWarrantySelectAlternativeActionSaga
  );
  yield takeEvery(submitWarrantyNoteStep.type, submitWarrantyNoteStepSaga);
  yield takeEvery(getWarrantyShippingAddresses.type, getWarrantyShippingAddressesSaga);
  yield takeEvery(submitWarrantyShippingDetails.type, submitWarrantyShippingDetailsSaga);
  yield takeEvery(getWarrantyRecap.type, getWarrantyRecapSaga);
  yield takeEvery(submitWarrantyToCRM.type, submitWarrantyToCRMSaga);
  yield takeEvery(getWarrantyInformationStep.type, getWarrantyInformationStepSaga);
  yield takeEvery(submitWarrantyInformationStep.type, submitWarrantyInformationStepSaga);
  yield takeEvery(getWarrantyAlternativeWizard.type, getWarrantyAlternativeWizardSaga);
}
