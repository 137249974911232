import React from "react";
import { motion } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";
import { selectIsSearchOpen } from "../../../store/search/searchSlice";
import HomepageErrorPopup from "../../pages/homepage/HomepageErrorPopup";
import { MemoizedMostUsed } from "../../pages/homepage/most-used/MostUsed";
import SearchHome from "../../pages/homepage/search-home/SearchHome";

interface Props {
  children: JSX.Element;
}

const HomepageLayout = ({ children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isSearchOpen = useSelector(selectIsSearchOpen);

  const animVar = () => {
    if (isSearchOpen) {
      return hideAnim;
    } else {
      return showAnim;
    }
  };

  return (
    <div>
      <motion.div>
        <SearchHome />
      </motion.div>
      <motion.div variants={animVar()} animate="animate">
        <MemoizedMostUsed />
        {children}
      </motion.div>
      <HomepageErrorPopup />
    </div>
  );
};

//Animations
const hideAnim = {
  animate: {
    display: "none",
    transition: {
      duration: 1.8,
      delay: 0,
    },
  },
};
const showAnim = {
  animate: {
    display: "unset",
    transition: {
      duration: 0.8,
      delay: 0,
    },
  },
};

export default HomepageLayout;
