import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCanRender from "../../../../hooks/useCanRender";
import { getAdtIsFirstLogin } from "../../../../store/adt/adtSagas";
import { selectIsFirstADTLogin } from "../../../../store/adt/adtSlice";
import { LocalStorageUtils } from "../../../../utils/storageUtils";
import ADTLegalDisclaimerPopup from "./ADTLegalDisclaimerPopup";
import ADTPlatformPopup from "./ADTPlatformPopup";
import LoaderOverlay from "../../../styled-UI/LoaderOverlay";

const ADT_PLATFORM_PATHS = [
  "/asset-downloads",
  "/asset-downloads/asset-list/",
  "/asset-downloads/asset-detail/",
  "/asset-downloads/product-images",
  "/account/saved-assets",
  "/account/downloaded-assets",
];

const ADT_LEGAL_DISCLAIMER_PATHS = [
  "/asset-downloads",
  "/asset-downloads/asset-list/",
  "/asset-downloads/asset-detail/",
  "/asset-downloads/product-images",
  "/account/saved-assets",
  "/account/downloaded-assets",
];

const ADTPopup = (): JSX.Element => {
  const canRender = useCanRender();

  const showLegalDisclaimer = ADT_LEGAL_DISCLAIMER_PATHS.some((_) => location.pathname.includes(_));

  const showPlatform = ADT_PLATFORM_PATHS.some((_) => location.pathname.includes(_));

  if (canRender("ADT") && (showLegalDisclaimer || showPlatform))
    return (
      <InsideRouteGuard showLegalDisclaimer={showLegalDisclaimer} showPlatform={showPlatform} />
    );
  else return <></>;
};

const InsideRouteGuard = ({
  showLegalDisclaimer,
  showPlatform,
}: {
  showLegalDisclaimer: boolean;
  showPlatform: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  //////////////// PLATFORM POPUP

  const [isPlatformPopupOpen, setIsPlatformPopupOpen] = useState<boolean>(false);

  const isFirstADTLogin = useSelector(selectIsFirstADTLogin);

  useEffect(() => {
    dispatch(getAdtIsFirstLogin());
  }, []);

  useEffect(() => {
    if (
      showPlatform &&
      (isFirstADTLogin.status === "SUCCESS" || isFirstADTLogin.status === "ERROR") &&
      !isFirstADTLogin.isAccepted
    )
      setIsPlatformPopupOpen(true);
  }, [isFirstADTLogin]);

  const onClosePlatform = () => {
    setIsPlatformPopupOpen(false);
    checkLegalDisclaimer(showLegalDisclaimer);
  };

  //////////////// LEGAL DISCLAIMER POPUP

  const [isLegalPopupOpen, setIsLegalPopupOpen] = useState<boolean>(false);

  const localStorageUtils = new LocalStorageUtils();

  const checkLegalDisclaimer = (showLegalDisclaimer: boolean) => {
    if (!showLegalDisclaimer) return;

    if (!localStorageUtils.get("ADT_SESSION")) setIsLegalPopupOpen(true);
  };

  // first time we open ADT in this session, we show legal disclaimer
  useEffect(() => {
    checkLegalDisclaimer(showLegalDisclaimer);
  }, [showLegalDisclaimer, isFirstADTLogin.status]);

  const onCloseDisclaimer = () => {
    localStorageUtils.set("ADT_SESSION", true);
    setIsLegalPopupOpen(false);
  };

  const showLoader =
    showPlatform &&
    showLegalDisclaimer &&
    isFirstADTLogin.status === "LOADING" &&
    !localStorageUtils.get("ADT_SESSION");

  if (showLoader) return <LoaderOverlay />;
  return (
    <>
      {isPlatformPopupOpen && (
        <ADTPlatformPopup onClose={onClosePlatform} isOpen={isPlatformPopupOpen} />
      )}
      {!isPlatformPopupOpen && isLegalPopupOpen && (
        <ADTLegalDisclaimerPopup onClose={onCloseDisclaimer} isOpen={isLegalPopupOpen} />
      )}
    </>
  );
};

export default ADTPopup;
