import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { getMostUsedContent, updateMostUsedChips } from "../../../../store/user/userSagas";
import { selectMostUsedLoading } from "../../../../store/user/userSlice";
import Button from "../../../styled-UI/Button";
import Chip from "../../../styled-UI/Chip";
import CustomText from "../../../styled-UI/CustomText";
import LoaderSelection from "../../../styled-UI/loader/most-used/LoaderSelection";
import Modal from "../../../styled-UI/Modal";
import ChipsCatalogue from "./Catalogue/ChipsCatalogue";
import ChipsServicesMyAccount from "./Services&MyAccount/ChipsServicesMyAccount";
import useSelectedMostUsed from "./useSelectedMostUsed";

import { useBreakpoint } from "../../../../assets/theme/breakpoint";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const MOST_USED_MODAL_WIDTH = {
  md: "50rem",
  lg: "62rem",
};

const CustomizeHomeModal = ({ isOpen, close }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  ///////////////// modal size
  const breakpoints = useBreakpoint();
  const modalWidth = breakpoints["md"] ? MOST_USED_MODAL_WIDTH["md"] : MOST_USED_MODAL_WIDTH["lg"];

  ///////////////// most used data
  const chipsHandler = useSelectedMostUsed();
  const MAX_SELECTED_CHIPS = 4 + chipsHandler.selectedChips.filter((_) => _.showAsDefault)?.length;

  const isMaxChipsReached = chipsHandler.selectedChips.length === MAX_SELECTED_CHIPS;
  const isNoChipsSelected = chipsHandler.selectedChips.length === 0;

  const mostUsedLoading = useSelector(selectMostUsedLoading);
  const isLoading = mostUsedLoading.get === "LOADING" || chipsHandler.isSelectionLoading;

  useEffect(() => {
    dispatch(getMostUsedContent());
  }, []);

  ///////////////// confirmation / error handling

  const [error, setError] = useState<string>("");

  const handleConfirm = () => {
    if (isNoChipsSelected) {
      // show error: "Select at least one preference to confirm"
      setError("MOST_USED_POPUP_SELECTION_NO_PREFERENCES");
      setTimeout(() => setError(""), 3000);
    } else {
      // save most used
      dispatch(
        updateMostUsedChips({
          bookmarks: chipsHandler.selectedChips.filter((_) => !_.showAsDefault),
        })
      );
      close();
    }
  };

  ///////////////// render

  const renderSelectionLabel = () => {
    const selectionLabel = isNoChipsSelected
      ? translateLabel("MOST_USED_POPUP_SELECTION_NO_PREFERENCES")
      : translateLabel("MOST_USED_POPUP_SELECTION") +
        ` (${chipsHandler.selectedChips.length}/${MAX_SELECTED_CHIPS}):`;

    return (
      <>
        <CustomText
          as="span"
          font="font-bold"
          fontSizePx={18}
          marginBottomPx={isMaxChipsReached ? 5 : 24}
        >
          {selectionLabel}
        </CustomText>
        {isMaxChipsReached && (
          <CustomText
            color="red"
            as="span"
            fontSizePx={14}
            lineHeightPx={24}
            font="font-regular"
            marginBottomPx={24}
          >
            {translateLabel("MOST_USED_POPUP_SELECTION_LIMIT_REACHED")}
          </CustomText>
        )}
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={translateLabel("MOST_USED_POPUP_TITLE")}
      isCentered={false}
    >
      <ModalContent width={modalWidth}>
        <CustomText
          color="gray-dark"
          as="span"
          fontSizePx={14}
          lineHeightPx={24}
          font="font-regular"
          marginBottomPx={40}
        >
          {translateLabel("MOST_USED_POPUP_SUBTITLE")}
        </CustomText>

        {isLoading ? (
          <LoaderSelection />
        ) : (
          <SelectionSection>
            {renderSelectionLabel()}

            {!isNoChipsSelected && (
              <div>
                {chipsHandler.selectedChips.map((chip) => (
                  <ChipContainer key={chip.name}>
                    <Chip
                      text={translateLabel(chip.label)}
                      active={!chip.showAsDefault}
                      letterCase="uppercase"
                      onClick={() => chipsHandler.deselectChip(chip)}
                      disabled={chip.showAsDefault}
                      hideIcon={chip.showAsDefault}
                      showTitle
                      noMaxWidth
                    />
                  </ChipContainer>
                ))}
              </div>
            )}
          </SelectionSection>
        )}

        <ChipsCatalogue
          chipsHandler={chipsHandler}
          isMaxChipsReached={isMaxChipsReached}
          isLoading={isLoading}
        />

        <ChipsServicesMyAccount
          chipsHandler={chipsHandler}
          isMaxChipsReached={isMaxChipsReached}
          isLoading={isLoading}
        />

        <ButtonContainer>
          <Button
            type="primary"
            onClick={handleConfirm}
            disabled={isLoading}
            data-element-id="MostUsed_Settings_Panel"
          >
            {translateLabel("MOST_USED_POPUP_BUTTON_CONFIRM")}
          </Button>
          {error && (
            <CustomText color="red" as="span" fontSizePx={14} font="font-regular">
              {translateLabel(error)}
            </CustomText>
          )}
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div<{ width: string }>`
  max-width: calc(100vw - 12rem);
  min-width: ${(props) => props.width};
`;

const SelectionSection = styled.div`
  padding: 2.5rem 3rem 3rem;
  background-color: ${(props) => props.theme.palette.gray.light};
  margin: 3rem -3rem 3rem -3rem;
  border-radius: 0 0 0.25rem 0.25rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  row-gap: 1rem;
`;

const ChipContainer = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.625rem;
`;

export default CustomizeHomeModal;
