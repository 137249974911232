import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  GetDeliveryNotePayload,
  GetOrderDetailsPayload,
  GetOrderHistoryPayload,
  OrderHistoryFiltersStored,
} from "./orderHistoryInterface";
import orderService from "./orderHistoryService";
import {
  saveOrderHistoryResult,
  setOrderHistoryStatus,
  sliceName,
  setOrderDetailsStatus,
  saveOrderDetailsResult,
  saveOrderDetailsResultSummary,
  saveDeliveryNoteResponse,
  setIsRepeatable,
  saveOrderHistoryFilters,
  saveOrderDetailsEssilor,
  saveOrderDetailsJSON,
} from "./orderHistorySlice";
import { mapOrderDetailsResult, mapOrderDetailsSummary } from "../../utils/ordersHistoryUtils";
import { handleError } from "../store/storeSagas";
import { mapOrderDetailsJSON } from "../../utils/essilorUtils";
import { OrderHistoryDetailsEssilor } from "../checkout/orderConfirmationEssilorInterfaces";

/* ACTIONS */
export const getOrderHistory = createAction<GetOrderHistoryPayload>(sliceName + "/getOrderHistory");
export const getOrderHistoryFilters = createAction<OrderHistoryFiltersStored>(
  sliceName + "/getOrderHistoryFilters"
);
export const getOrderDetails = createAction<GetOrderDetailsPayload>(sliceName + "/getOrderDetails");
export const getDeliveryNote = createAction<GetDeliveryNotePayload>(sliceName + "/getDeliveryNote");

/* SAGAS */

function* getOrderHistorySaga(params: PayloadAction<GetOrderHistoryPayload>): SagaIterator {
  try {
    yield put(setOrderHistoryStatus("LOADING"));
    const { data } = yield call(orderService.getOrderHistory, params.payload);
    yield put(saveOrderHistoryResult(data.data));
    yield put(setOrderHistoryStatus("SUCCESS"));
  } catch (error) {
    yield put(setOrderHistoryStatus("ERROR"));
    yield put(saveOrderHistoryResult(null));
    yield put(handleError(error));
  }
}

function* getOrderHistoryFiltersSaga(
  params: PayloadAction<OrderHistoryFiltersStored>
): SagaIterator {
  yield put(saveOrderHistoryFilters(params.payload));
}

function* getOrderDetailsSaga({ payload }: PayloadAction<GetOrderDetailsPayload>): SagaIterator {
  try {
    yield put(setOrderDetailsStatus("LOADING"));
    if (payload.summary) {
      const { data } = yield call(orderService.getOrderDetails, payload);

      const orderDetailsSummary = mapOrderDetailsSummary(data?.data?.brand_detail);

      if (orderDetailsSummary) yield put(saveOrderDetailsResultSummary(orderDetailsSummary));
      else yield put(saveOrderDetailsResultSummary(null));

      if (data?.data?.onePortalJson) {
        const orderHistoryDetailsEssilor: OrderHistoryDetailsEssilor = mapOrderDetailsJSON(
          JSON.parse(data?.data?.onePortalJson)
        );
        if (orderHistoryDetailsEssilor) {
          yield put(saveOrderDetailsEssilor(orderHistoryDetailsEssilor));
          yield put(saveOrderDetailsJSON(data?.data?.output?.onePortalJson));
        }
      } else {
        yield put(saveOrderDetailsEssilor(null));
        yield put(saveOrderDetailsJSON(""));
      }

      yield put(setOrderDetailsStatus("SUCCESS"));
    } else {
      const { data } = yield call(orderService.getOrderDetailsWithDelivery, payload);

      const orderDetailsResult = mapOrderDetailsResult(data?.data?.output);

      if (data?.data?.output?.onePortalJson) {
        const orderHistoryDetailsEssilor = mapOrderDetailsJSON(
          JSON.parse(data?.data?.output?.onePortalJson)
        );
        if (orderHistoryDetailsEssilor) {
          yield put(saveOrderDetailsEssilor(orderHistoryDetailsEssilor));
          yield put(saveOrderDetailsJSON(data?.data?.output?.onePortalJson));
        }
      } else {
        yield put(saveOrderDetailsEssilor(null));
        yield put(saveOrderDetailsJSON(""));
      }

      if (orderDetailsResult) {
        yield put(saveOrderDetailsResult(orderDetailsResult));
        yield put(setIsRepeatable(data?.data?.repeatable));
      } else yield put(saveOrderDetailsResult(null));

      yield put(setOrderDetailsStatus("SUCCESS"));
    }
  } catch (error) {
    yield put(setOrderDetailsStatus("ERROR"));
    yield put(saveOrderDetailsResultSummary(null));
    yield put(saveOrderDetailsResult(null));
    yield put(handleError(error));
  }
}

function* getDeliveryNoteSaga({ payload }: PayloadAction<GetDeliveryNotePayload>): SagaIterator {
  try {
    const { data } = yield call(orderService.getDeliveryNote, payload);
    if (data?.output?.length) {
      yield put(saveDeliveryNoteResponse(data?.output[0]));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* orderHistorySaga(): SagaIterator {
  yield takeEvery(getOrderHistory.type, getOrderHistorySaga);
  yield takeEvery(getOrderHistoryFilters.type, getOrderHistoryFiltersSaga);
  yield takeEvery(getOrderDetails.type, getOrderDetailsSaga);
  yield takeEvery(getDeliveryNote.type, getDeliveryNoteSaga);
}
