import { BFFApiDomain } from "../serviceUtils";

/////////////////// pin management
export const validatePinUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/user/pin/validate";

export const updatePinUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/user/pin";

export const resetPinUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/user/pin/reset";

/////////////////// invoices
export const getInvoicesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/invoices";

export const downloadInvoiceUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/invoice/{invoiceIdentifier}/download";

export const optionsInvoiceUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/invoice/filters";

export const prepareMassiveInvoiceDownloadUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/invoice/massive-download/prepare";

export const massiveInvoiceDownloadUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/invoice/massive-download/{invoiceRequestId}";

/////////////////// credit
export const creditSummaryUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/credits/summary";

export const creditDetailsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/credits/{branch}/details";
