import React, { InputHTMLAttributes, ReactNode, useState } from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

import Tooltip from "./Tooltip";

import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/icons/eye-icon-2.svg";

export type InputColorStyle = "enabled" | "error" | "warning" | "success";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  colorStyle?: InputColorStyle;
  placeholder?: string;
  type: "password" | "text" | "number";
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string;
  helperTextBold?: boolean;
  required?: boolean;
  id?: string;
  endIcon?: ReactNode;
  onClickEndIcon?: () => void;
  endIconTooltip?: string;
  endIconDisabled?: boolean;
  iconMarginTopPx?: number;
  as?: "input" | "textarea";
  resizableTextarea?: boolean;
  maxSize?: boolean;
  smallLeftBorder?: boolean;
  iconSize?: string;
  uppercase?: boolean;
  widthPx?: number;
}

interface InputInterface {
  ref: any;
  as: string;
}

interface IconProps {
  iconMarginTopPx?: number;
  iconSize?: string;
}

const InputComponent = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      colorStyle = "enabled",
      placeholder,
      type = "text",
      disabled = false,
      readOnly = false,
      smallLeftBorder = false,
      helperText,
      helperTextBold = false,
      required,
      endIcon,
      id = "",
      as = "input",
      resizableTextarea = false,
      onClickEndIcon,
      endIconDisabled = false,
      maxSize = false,
      iconMarginTopPx = 0,
      endIconTooltip,
      iconSize = "1rem",
      uppercase = false,
      widthPx,
      ...rest
    },
    forwardedRef
  ) => {
    const [visibilityText, setVisibilityText] = useState<boolean>(false);

    const helperTextConfig: { [index: string]: any } = {
      enabled: {
        className: "enabled-color",
      },
      error: {
        className: "error-color",
      },
      warning: {
        className: "warning-color",
      },
      success: {
        className: "success-color",
      },
      default: {
        className: "enabled-color",
      },
    };

    const inputClassNames = clsx(
      colorStyle === "enabled" && "input-color-enabled",
      colorStyle === "error" && "input-color-error",
      colorStyle === "success" && "input-color-success",
      colorStyle === "warning" && "input-color-warning",
      readOnly && "input-read-only",
      // (colorStyle !== "enabled" || endIcon || type === "password") && "input-with-icon",
      endIcon && "input-with-icon",
      placeholder && "has-placeholder",
      !resizableTextarea && "non-resizable-textarea",
      as === "textarea" && "input-textarea",
      smallLeftBorder && "small-left-border",
      uppercase && "uppercase"
    );

    const renderIcon = (icon: JSX.Element | ReactNode, tooltip?: string) => {
      if (tooltip)
        return (
          <Tooltip canShow={!!endIconTooltip} content={endIconTooltip}>
            {icon}
          </Tooltip>
        );
      else return icon;
    };

    return (
      <Container width={widthPx} className={clsx(as === "textarea" && "container-textarea")}>
        <InputContainer className={clsx(as === "textarea" && "input-container-textarea")}>
          <Input
            as={as}
            id={id}
            className={inputClassNames}
            type={visibilityText ? "text" : type}
            disabled={disabled || readOnly}
            placeholder={placeholder}
            ref={forwardedRef}
            maxSize={maxSize}
            iconMarginTopPx={iconMarginTopPx}
            {...rest}
          />
          {as !== "textarea" && (
            <>
              {type === "password" && (
                <IconContainer
                  className="cursor-pointer"
                  onClick={() => setVisibilityText(!visibilityText)}
                  iconSize={iconSize}
                >
                  {renderIcon(visibilityText ? <EyeIcon /> : <EyeSlashIcon />, endIconTooltip)}
                </IconContainer>
              )}
              {endIcon && type !== "password" && (
                <IconContainer
                  {...(!endIconDisabled ? { onClick: onClickEndIcon } : {})}
                  className={clsx(
                    onClickEndIcon && !endIconDisabled && "cursor-pointer",
                    endIconDisabled && "icon-disabled"
                  )}
                  onClick={() => onClickEndIcon?.()}
                  iconMarginTopPx={iconMarginTopPx}
                  iconSize={iconSize}
                >
                  {renderIcon(endIcon, endIconTooltip)}
                </IconContainer>
              )}
            </>
          )}
          <Label className={inputClassNames}>
            {placeholder} {required && "*"}
          </Label>
        </InputContainer>
        {helperText && (
          <HelperText
            className={helperTextConfig[colorStyle].className}
            helperTextBold={helperTextBold}
          >
            <span>{helperText}</span>
          </HelperText>
        )}
      </Container>
    );
  }
);

InputComponent.displayName = "Input";

const Container = styled.div<{ width?: number }>`
  &.container-textarea {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${(props) => props.width && `width: ${props.width / props.theme.baseFontSize}rem;`}
`;

const InputContainer = styled.div`
  position: relative;

  &:focus-within ~ label {
    position: absolute;
    top: 0.625rem;
    display: block;
    transition: 0.2s;
    font-size: 1.7rem;
    font-family: ${(props) => props.theme.fonts.fontRegular};
  }

  &.input-container-textarea {
    flex-grow: 1;
  }
`;

type InputProps = InputInterface & Props;
const Input = styled.input<InputProps>`
  color: ${(props) => props.theme.palette.primary};
  border-radius: 0.25rem;
  padding: 0rem 0.75rem 0 1.5rem;
  line-height: 1.125rem;
  font-size: 0.8125rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  width: 100%;
  outline: none;
  box-sizing: border-box;
  height: 3.5rem;

  &.small-left-border {
    padding-left: 0.125rem;
  }

  &.has-placeholder {
    padding-top: ${(props) =>
      props.as === "input" ? "0.75rem" : props.as === "textarea" ? "1.75rem" : "0rem"};
  }

  &:placeholder-shown ~ label {
    color: ${(props) => props.theme.palette.primary};
    font-size: 0.8125rem;
    cursor: text;
    top: 1.4rem;
    font-family: ${(props) => props.theme.fonts.fontMedium};
    background-color: transparent;
    /* height: 100%; */

    &.input-textarea {
      top: 0.8125rem;
    }
  }

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &:active {
    color: ${(props) => props.theme.palette.primary}!important;
  }

  /* hover solo su enabled */
  &:not(.input-color-error, .input-color-warning, .input-color-success):hover {
    border: solid 1px ${(props) => props.theme.palette.gray.dark} !important;
  }

  &:not(.input-color-error, .input-color-warning, .input-color-success):focus {
    border: solid 1px ${(props) => props.theme.palette.primary} !important;
  }

  &:focus {
    ~ label {
      position: absolute;
      top: 0.625rem;
      display: block;
      transition: 0.2s;
      font-size: 0.6875rem;
      color: ${(props) => props.theme.palette.gray.dark};
      font-family: ${(props) => props.theme.fonts.fontRegular};
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      height: 100%;

      &.input-textarea {
        top: 1px;
      }
    }

    &.input-color-error ~ label {
      color: ${(props) => props.theme.palette.feedback.error};
    }
    &.input-color-warning ~ label {
      color: ${(props) => props.theme.palette.feedback.alert};
    }
    &.input-color-success ~ label {
      color: ${(props) => props.theme.palette.feedback.success};
    }
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
    height: 100%;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
    height: 100%;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
    height: 100%;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-family: ${(props) => props.theme.fonts.fontMedium};
    color: transparent;
    height: 100%;
  }

  &.input-color-enabled {
    border: solid 1px ${(props) => props.theme.palette.gray.medium};
  }

  &.input-color-error {
    border: solid 1px ${(props) => props.theme.palette.feedback.error};
  }

  &.input-color-warning {
    border: solid 1px ${(props) => props.theme.palette.feedback.alert};
  }

  &.input-color-success {
    border: solid 1px ${(props) => props.theme.palette.feedback.success};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.gray.light};
    box-shadow: none;
    border: solid 1px ${(props) => props.theme.palette.gray.medium};

    &:hover {
      border: solid 1px ${(props) => props.theme.palette.gray.medium} !important;
    }

    ~ label {
      color: ${(props) => props.theme.palette.gray.dark};
      background-color: ${(props) => props.theme.palette.gray.light};
      /* height: 100%; */
    }

    ~ span svg {
      fill: ${(props) => props.theme.palette.gray.dark} !important;
    }
  }

  &.input-read-only {
    color: ${(props) => props.theme.palette.primary};
    background-color: transparent;

    ~ label {
      color: ${(props) => props.theme.palette.gray.dark};
      background-color: transparent;
    }
  }

  &.input-with-icon {
    padding-right: 3.5625rem;
  }

  &.input-textarea {
    height: 100%;
    max-width: ${(props) => (props.maxSize ? "965px" : "unset")};
    max-height: ${(props) => (props.maxSize ? "136px;" : "unset")};
    min-width: ${(props) => (props.maxSize ? "965px" : "unset")};
    min-height: ${(props) => (props.maxSize ? "67px" : "unset")};

    &.non-resizable-textarea {
      resize: none;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }
`;

const IconContainer = styled.span<IconProps>`
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 1.5625rem;
  margin-top: ${(props) => props.iconMarginTopPx}px;

  svg {
    height: ${(props) => props.iconSize};
    width: ${(props) => props.iconSize};
    fill: ${(props) => props.theme.palette.primary};
  }

  &.error-color svg {
    fill: ${(props) => props.theme.palette.feedback.error};
  }
  &.warning-color svg {
    fill: ${(props) => props.theme.palette.feedback.alert};
  }
  &.success-color svg {
    fill: ${(props) => props.theme.palette.feedback.success};
  }
  &.icon-disabled svg {
    fill: ${(props) => props.theme.palette.gray.dark};
  }
  &.cursor-pointer {
    cursor: pointer;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.6875rem;
  font-weight: 500;
  position: absolute;
  top: 0.625rem;
  letter-spacing: 0.19px;
  /* font-family: ${(props) => props.theme.fonts.fontMedium}; */
  font-family: ${(props) => props.theme.fonts.fontRegular};
  /* padding-bottom: 0.625rem; */
  left: calc(1.5rem + 1px);
  transition: 0.2s;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.gray.dark};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.input-textarea {
    top: 1px;
    left: 1px;
    height: 1.75rem;
    line-height: 1.75rem;
    padding: 0;
    padding-left: 1.5rem;
    background-color: transparent;
    width: calc(100% - 1.5rem);
    border-radius: 0.25rem;
  }

  &.input-color-error {
    color: ${(props) => props.theme.palette.feedback.error};
  }

  &.input-color-warning {
    color: ${(props) => props.theme.palette.feedback.alert};
  }

  &.input-color-success {
    color: ${(props) => props.theme.palette.feedback.success};
  }
`;

const HelperText = styled.p<{ helperTextBold?: boolean }>`
  font-family: ${(props) =>
    props.helperTextBold ? props.theme.fonts.fontBold : props.theme.fonts.fontRegular};
  margin-top: 0.5rem;
  font-size: 0.75rem;
  margin-left: 1.5rem;

  &.enabled-color {
    color: ${(props) => props.theme.palette.gray.dark};
  }
  &.error-color {
    color: ${(props) => props.theme.palette.feedback.error};
  }
  &.warning-color {
    color: ${(props) => props.theme.palette.feedback.alert};
  }
  &.success-color {
    color: ${(props) => props.theme.palette.feedback.success};
  }
`;

export default InputComponent;
