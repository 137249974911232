import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { RootState } from "../storeConfig";
import {
  MessagesState,
  BackordersAvailability,
  BackordersState,
  NotificationPreference,
  NotificationsListRemapped,
  SectionStatus,
  MarkNotificationsAsReadUnread,
} from "./messagesInterfaces";

export const sliceName = "messages";

export const DEFAULT_NOTIFICATIONS = { notifications: [], totalElements: 0, totalPages: 0 };

const initialState: MessagesState = {
  ////////////////// NOTIFICATIONS
  notifications: DEFAULT_NOTIFICATIONS,
  notificationsPreferences: null,
  totalNumberNotifications: null,
  notificationsPreferencesStatus: "IDLE",
  updateNotificationsPreferencesStatus: "IDLE",
  notificationsStatus: "IDLE",
  markedNotificationsStatus: "IDLE",

  ////////////////// GREEN IS BACK
  backordersAvailability: null,
  backordersAvailabilityStatus: { status: "IDLE" },
  backorderPricesStatus: "IDLE",
  backordersState: {
    availableProducts: { currentPage: 1 },
    backorderProducts: { currentPage: 1 },
    outOfStockProducts: { currentPage: 1 },
  },
};

export const messagesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    ////////////////// NOTIFICATIONS
    saveNotifications: (state, { payload }: PayloadAction<NotificationsListRemapped>) => {
      state.notifications = payload;
    },
    saveNotificationsPreferences: (
      state,
      { payload }: PayloadAction<NotificationPreference[] | null>
    ) => {
      state.notificationsPreferences = payload;
    },
    markNotificationsAsReadUnread: (
      state,
      { payload }: PayloadAction<MarkNotificationsAsReadUnread[]>
    ) => {
      payload.forEach((_) => {
        const notificationToBeUpdated = state.notifications.notifications.find(
          (notif) => notif.notificationId === _.notificationId
        );
        if (notificationToBeUpdated) notificationToBeUpdated.read = _.isRead;
      });
    },
    saveTotalNumberNotifications: (state, { payload }: PayloadAction<number | null>) => {
      state.totalNumberNotifications = payload;
    },
    setNotificationsPreferencesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.notificationsPreferencesStatus = payload;
    },
    setUpdateNotificationsPreferencesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.updateNotificationsPreferencesStatus = payload;
    },
    setNotificationsStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.notificationsStatus = payload;
    },
    setMarkedNotificationsStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.markedNotificationsStatus = payload;
    },
    resetNotificationsPreferences: (state) => {
      state.notificationsPreferences = null;
      state.notificationsPreferencesStatus = "IDLE";
      state.updateNotificationsPreferencesStatus = "IDLE";
    },
    resetNotifications: (state) => {
      state.notifications = DEFAULT_NOTIFICATIONS;
      state.notificationsPreferences = null;
      state.notificationsPreferencesStatus = "IDLE";
      state.updateNotificationsPreferencesStatus = "IDLE";
      state.notificationsStatus = "IDLE";
      state.markedNotificationsStatus = "IDLE";
    },

    ////////////////// GREEN IS BACK
    saveBackordersAvailability: (
      state,
      { payload }: PayloadAction<BackordersAvailability | null>
    ) => {
      if (state.backordersAvailability !== null) {
        state.backordersAvailability = { ...state.backordersAvailability, ...payload };
        return;
      }
      state.backordersAvailability = payload;
    },
    setBackordersAvailabilityStatus: (state, { payload }: PayloadAction<SectionStatus>) => {
      state.backordersAvailabilityStatus = payload;
    },
    setBackorderPricesStatus: (state, { payload }: PayloadAction<RequestStatus>) => {
      state.backorderPricesStatus = payload;
    },
    setBackordersState: (state, { payload }: PayloadAction<BackordersState>) => {
      state.backordersState = payload;
    },

    resetMessagesState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  ////////////////// NOTIFICATIONS
  saveNotifications,
  saveNotificationsPreferences,
  markNotificationsAsReadUnread,
  saveTotalNumberNotifications,
  setNotificationsPreferencesStatus,
  setUpdateNotificationsPreferencesStatus,
  setNotificationsStatus,
  setMarkedNotificationsStatus,
  resetNotificationsPreferences,
  resetNotifications,

  ////////////////// GREEN IS BACK
  saveBackordersAvailability,
  setBackordersAvailabilityStatus,
  setBackorderPricesStatus,
  setBackordersState,

  resetMessagesState,
} = messagesSlice.actions;

////////////////// NOTIFICATIONS
export const selectNotifications = (state: RootState): NotificationsListRemapped =>
  state.messages.notifications;

export const selectNotificationsPreferences = (state: RootState): NotificationPreference[] | null =>
  state.messages.notificationsPreferences;

export const selectTotalNumberNotifications = (state: RootState): number | null =>
  state.messages.totalNumberNotifications;

export const selectNotificationsPreferencesStatus = (state: RootState): RequestStatus =>
  state.messages.notificationsPreferencesStatus;

export const selectUpdateNotificationsPreferencesStatus = (state: RootState): RequestStatus =>
  state.messages.updateNotificationsPreferencesStatus;

export const selectNotificationsStatus = (state: RootState): RequestStatus =>
  state.messages.notificationsStatus;

export const selectMarkedNotificationsStatus = (state: RootState): RequestStatus =>
  state.messages.markedNotificationsStatus;

////////////////// GREEN IS BACK
export const selectBackordersAvailability = (state: RootState): BackordersAvailability | null =>
  state.messages.backordersAvailability;

export const selectBackordersAvailabilityStatus = (state: RootState): SectionStatus =>
  state.messages.backordersAvailabilityStatus;

export const selectBackorderPricesStatus = (state: RootState): RequestStatus =>
  state.messages.backorderPricesStatus;

export const selectBackordersState = (state: RootState): BackordersState =>
  state.messages.backordersState;

export default messagesSlice.reducer;
