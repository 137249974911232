import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as Plus } from "../../assets/icons/plus-icon.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin-icon.svg";
import { ReactComponent as FilePreview } from "../../assets/icons/note-icon.svg";

export type ChipStyle = "border" | "bg-gray" | "dark-on-selected" | "no-rounded";

interface ChipProps {
  text: string;
  active?: boolean;
  disabled?: boolean;
  fixed?: boolean;
  onClick?: () => void;
  type?: "light" | "dark";
  letterCase?: "uppercase" | "capitalize";
  size?: "sm" | "md";
  hasMargin?: boolean;
  hideIcon?: boolean;
  "data-element-id"?: string;
  defaultCursor?: boolean;
  style?: ChipStyle;
  customColor?: string;
  noMaxWidth?: boolean;
  showTitle?: boolean;
  maxWidth?: string;
  deleteIcon?: JSX.Element;
  showFileIcon?: boolean;
  spacedContent?: boolean;
}

const Chip = ({
  text,
  active = false,
  disabled = false,
  fixed = false,
  onClick,
  hasMargin = false,
  type = "light",
  letterCase,
  size = "md",
  hideIcon,
  defaultCursor = false,
  style = "border",
  customColor,
  noMaxWidth = false,
  showTitle = false,
  maxWidth = "16.5rem",
  deleteIcon = <XIcon />,
  showFileIcon = false,
  spacedContent = false,
}: ChipProps): JSX.Element => {
  const handleIcon = () => {
    if (fixed && active) {
      return <PinIcon />;
    }
    if (active) {
      return deleteIcon;
    }
    if (!active && !fixed) {
      return <Plus />;
    }
  };

  return (
    <StyledContainer
      className={clsx(
        active && "active",
        fixed && "fixed",
        type === "dark" && "chip-dark",
        style === "bg-gray" && "bg-gray",
        style === "dark-on-selected" && "dark-on-selected",
        style === "no-rounded" && "no-rounded",
        letterCase === "uppercase" && "chip--uppercase",
        letterCase === "capitalize" && "chip--capitalize",
        size === "sm" && "chip-sm",
        hasMargin && "has-margin",
        disabled && "disabled"
      )}
      onClick={() => {
        !disabled && !fixed && onClick?.();
      }}
      defaultCursor={defaultCursor}
      maxWidth={maxWidth}
      noMaxWidth={noMaxWidth}
    >
      <TextContainer className={clsx(spacedContent && "has-space-between")}>
        {showFileIcon && (
          <StyledIcon className={clsx(type === "dark" && "chip-icon-dark")}>
            <FilePreview />
          </StyledIcon>
        )}
        <StyledText
          className={clsx(
            type === "dark" && "chip-text-dark",
            active && "active",
            style === "dark-on-selected" && "dark-on-selected"
          )}
          customColor={customColor && customColor != "No Colour" ? customColor : ""}
          {...(showTitle && { title: text })}
        >
          {text}
        </StyledText>
        {!hideIcon && (
          <StyledIcon className={clsx(type === "dark" && "chip-icon-dark")}>
            {handleIcon()}
          </StyledIcon>
        )}
      </TextContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{
  defaultCursor: boolean;
  maxWidth: string;
  noMaxWidth: boolean;
}>`
  display: inline-flex;
  border-radius: 5rem;
  border: solid 2px ${(props) => props.theme.palette.gray.medium};
  padding: 0.875rem 1.125rem;
  cursor: ${(props) => (props.defaultCursor ? "default" : "pointer")};
  font-size: 0.875rem;
  max-width: ${(props) => (props.noMaxWidth ? "unset" : props.maxWidth)};
  text-align: center;

  &.active {
    border-color: ${(props) => props.theme.palette.primary};
  }

  &.chip-dark {
    border-color: ${(props) => props.theme.palette.bordersBlue};
    background-color: transparent;
  }

  /* no hover con la chip-dark */
  &:not(.chip-dark):hover {
    border-color: ${(props) => props.theme.palette.gray.dark};
  }

  &.chip--uppercase {
    text-transform: uppercase;
  }

  &.chip--capitalize {
    text-transform: capitalize;
  }

  &.chip-sm {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  &.has-margin {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    &:not(.chip-dark):hover {
      border-color: ${(props) => props.theme.palette.gray.medium};
    }
  }

  &.bg-gray {
    background-color: ${(props) => props.theme.palette.gray.medium};
    border-color: ${(props) => props.theme.palette.gray.medium};

    &:hover {
      border-color: ${(props) => props.theme.palette.gray.medium};
    }
  }

  &.dark-on-selected {
    &.active {
      background-color: ${(props) => props.theme.palette.primary};
      border: none;
    }
  }

  &.no-rounded {
    border-radius: 0.25rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.has-space-between {
    display: inline-flex;
    justify-content: space-between;
    gap: 0.25rem;
  }
`;

const StyledText = styled.p<{ customColor?: string }>`
  color: ${(props) => props.theme.palette.primary};
  ${(props) => props.customColor && `color: ${props.customColor}!important`}
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1rem;
  line-height: 1rem;

  &.dark-on-selected.active {
    color: ${(props) => props.theme.palette.white};
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.chip-text-dark {
    color: ${(props) => props.theme.palette.white};
  }
`;

const StyledIcon = styled.span`
  margin-left: 0.625rem;
  width: 1rem;
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }

  &.chip-icon-dark svg {
    fill: ${(props) => props.theme.palette.white};
  }
`;

export default Chip;
