import clsx from "clsx";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import { useHandleCmsLink } from "../../../../utils/cmsUtils";
import CustomText from "../../../styled-UI/CustomText";
import LinkButton from "../../../styled-UI/LinkButton";
import SetInnerHTMLWithSanitize from "../../SetInnerHTMLWithSanitize";

interface Props {
  title?: string;
  description?: string;
  linkString?: string;
  linkLabel?: string;
  openInNewTab?: boolean;
  externalLink?: boolean;
  id?: string;
  slideNumber: number;
  currentPage: number;
  "data-element-id"?: string;
  totalSlide: number;
  nextSlide?: (nextSlide: number) => void;
  showAccountMenu?: (showMenu: boolean) => void;
}

const FavoritesTooltipSection = ({
  title,
  description,
  linkString,
  openInNewTab = false,
  externalLink = false,
  linkLabel,
  slideNumber,
  currentPage,
  "data-element-id": dataElementId,
  id,
  totalSlide,
  nextSlide,
  showAccountMenu,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { handleCmsLink } = useHandleCmsLink();

  useEffect(() => {
    currentPage === slideNumber - 1 &&
      sendAnalyticsData({
        id: "Impression",
        Page_Section1: "Tooltips",
        Page_Section2: id ?? title,
      });
  }, [currentPage]);

  const handleRedirect = (): void => {
    handleCmsLink(linkString, externalLink, openInNewTab);
  };

  return (
    <Section>
      <TitleBox>
        <CustomText as="p" fontSizePx={13} lineHeightPx={18} font="font-bold" uppercase>
          {title}
        </CustomText>
        {slideNumber && totalSlide && (
          <CustomText
            as="p"
            fontSizePx={13}
            fontWeight="bold"
            color="gray-neutral"
            lineHeightPx={17.94}
          >
            {slideNumber}/{totalSlide}
          </CustomText>
        )}
      </TitleBox>
      <Description>
        <CustomText
          as="p"
          font="font-medium"
          fontSizePx={13}
          lineHeightPx={18}
          uppercase
          marginTopPx={8}
        >
          {description && <SetInnerHTMLWithSanitize text={description} />}
        </CustomText>
      </Description>
      <NavigationTooltip
        className={clsx((!linkString || !linkLabel) && slideNumber == totalSlide && "justify-end")}
      >
        {linkString && linkLabel && (
          <CtaContainer>
            <LinkButton
              {...(dataElementId ? { "data-element-id": `${dataElementId}_LearnMore` } : {})}
              bold
              marginTopUnderlinePx={-3.5}
              onClick={() => {
                handleRedirect();
              }}
            >
              {translateLabel(linkLabel)}
            </LinkButton>
          </CtaContainer>
        )}
        <NavigationButtons>
          {slideNumber !== totalSlide && (
            <SkipButton>
              <LinkButton
                {...(dataElementId ? { "data-element-id": `${dataElementId}_Skip` } : {})}
                bold
                underline={false}
                onClick={(e) => {
                  showAccountMenu?.(false);
                  e.stopPropagation();
                }}
              >
                {translateLabel("TUTORIAL_PILLS_TOOLTIP_SKIP")}
              </LinkButton>
            </SkipButton>
          )}
          <SkipButton>
            <LinkButton
              bold
              underline={false}
              onClick={(e) => {
                if (slideNumber !== totalSlide) nextSlide?.(slideNumber);
                else {
                  showAccountMenu?.(false);
                  e.stopPropagation();
                }
              }}
              {...(dataElementId
                ? {
                    "data-element-id":
                      `${dataElementId}_` + (slideNumber === totalSlide ? "Close" : "Next"),
                  }
                : {})}
            >
              {slideNumber === totalSlide
                ? translateLabel("TUTORIAL_PILLS_TOOLTIP_CLOSE")
                : translateLabel("TUTORIAL_PILLS_TOOLTIP_NEXT")}
            </LinkButton>
          </SkipButton>
        </NavigationButtons>
      </NavigationTooltip>
    </Section>
  );
};

const Section = styled.div``;

const CtaContainer = styled.div`
  justify-content: flex-start;
  white-space: nowrap;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SkipButton = styled.div`
  width: 100%;
`;

const Description = styled.div`
  a {
    text-decoration: underline;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const NavigationTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0 0;

  &.justify-end {
    justify-content: end;
  }
`;
export default FavoritesTooltipSection;
