import { AxiosResponse } from "axios";
import { createAndExecuteService } from "../serviceUtils";
import {
  InventoryTrackingPayload,
  NotificationPreferencesUpdatePayload,
  GetAllNotificationsListPayload,
  MarkAsReadOrUnreadNotificationsPayload,
  DeleteNotificationsPayload,
} from "./messagesInterfaces";
import {
  addInventoryTrackingUrl,
  areProductsTrackedUrl,
  deleteInventoryTrackingUrl,
  getAllNotificationsListUrl,
  getAllNotificationsPreferencesUrl,
  getAllNotificationsUrl,
  getInventoryTrackingUrl,
  getTotalNumberNotificationsUrl,
  markAsDeletedNotificationsUrl,
  markAsReadOrUnreadNotificationsUrl,
  updateNotificationsPreferencesUrl,
} from "./messagesUrls";

const messagesService = {
  //NOTIFICATIONS
  getAllNotificationsList: async (
    payload: GetAllNotificationsListPayload
  ): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => qparams.append(key, value));
    return createAndExecuteService(getAllNotificationsListUrl, "GET", qparams);
  },

  getAllNotifications: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getAllNotificationsUrl, "GET");
  },

  getAllNotificationsPreferences: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getAllNotificationsPreferencesUrl, "GET");
  },

  updateNotificationsPreferences: async (
    payload: NotificationPreferencesUpdatePayload[]
  ): Promise<AxiosResponse> => {
    const data = {
      userPreferenceDTOReq: payload,
    };
    return createAndExecuteService(updateNotificationsPreferencesUrl, "POST", null, data);
  },

  markAsReadOrUnreadNotifications: async (
    payload: MarkAsReadOrUnreadNotificationsPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(markAsReadOrUnreadNotificationsUrl, "POST", null, payload);
  },

  markAsDeletedNotifications: async (
    payload: DeleteNotificationsPayload
  ): Promise<AxiosResponse> => {
    const data = {
      notifications: payload.notifications,
    };
    return createAndExecuteService(markAsDeletedNotificationsUrl, "DELETE", null, data);
  },

  getTotalNumberNotifications: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(getTotalNumberNotificationsUrl, "GET");
  },

  getBackordersAvailability: async (payload: InventoryTrackingPayload): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => value && qparams.append(key, value));

    return createAndExecuteService(getInventoryTrackingUrl, "GET", qparams);
  },

  deleteInventoryTracking: async (inventoryTrackingId: string): Promise<AxiosResponse> => {
    const url = deleteInventoryTrackingUrl.replace("{inventoryTrackingId}", inventoryTrackingId);

    return createAndExecuteService(url, "DELETE");
  },

  addInventoryTracking: async (catentryId: string): Promise<AxiosResponse> => {
    const url = addInventoryTrackingUrl.replace("{catentryId}", catentryId);

    return createAndExecuteService(url, "POST");
  },

  areProductsTracked: async (catentryIds: string[]): Promise<AxiosResponse> => {
    const qparams = new URLSearchParams();
    catentryIds.forEach((catentryId) => qparams.append("catentryId", catentryId));

    return createAndExecuteService(areProductsTrackedUrl, "GET", qparams);
  },
};

export default messagesService;
