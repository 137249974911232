import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  OrdersToApproveFilters,
  ApproveRejectOrdersPayload,
  SuborderApprovalIdsPayoad,
  ApproveRejectOrdersSimulate,
  ExportOrderToApproveCSVPayload,
  ApproveRejectOrdersResult,
} from "./ordersToApproveInterface";
import orderService from "./ordersToApproveService";
import {
  saveOrdersToApproveList,
  setOrdersToApproveStatus,
  saveApproveRejectOrdersResult,
  sliceName,
  setApproveRejectOrdersStatus,
  saveSuborderApprovalHeader,
  saveApproveRejectOrdersSimulate,
  saveExportOrderToApproveCSVStatus,
  saveSuborderApprovalDetails,
  saveSuborderApprovalAddress,
  selectSuborderApprovalDetails,
  numberOfOrderItemsNotOutOfStock,
  saveOrderId,
  selectOrderId,
  saveSubOrdersNotesAndPO,
  saveSuborderApprovalTotalPrice,
  setSuborderDetailsStatus,
} from "./ordersToApproveSlice";
import { handleError, showErrorPopup } from "../store/storeSagas";
import { mapSuborderApprovalHeader } from "../../utils/ordersToApproveUtils";
import { downloadURI, partitionArray } from "../../utils/utils";
import {
  getPutPrecartItemsArrayPayload,
  getPutPrecartItemsPayload,
  mapMultidoorAddress,
  mapOrderMultidoorArray,
  mapSubOrdersNotesAndPO,
} from "../../utils/cartUtils";
import {
  DeletePrecartItemsAFAPayload,
  OrderItem,
  OrderMultidoor,
  OrderMultidoorAddress,
  UpdatePrecartItemPayload,
  UpdatePrecartItemsPayload,
} from "../cart/cartInterfaces";
import { startCartLoading, stopCartLoading } from "../cart/cartSlice";

///////// LIST OF ORDERS TO BE APPROVED
export const getOrdersToApprove = createAction<OrdersToApproveFilters>(
  sliceName + "/getOrdersToApprove"
);

//////// APPROVING / REJECTING ORDERS
export const approveRejectOrders = createAction<ApproveRejectOrdersPayload>(
  sliceName + "/approveRejectOrders"
);

//////// ORDER DETAIL
export const getOrderToApproveHeader = createAction<SuborderApprovalIdsPayoad>(
  sliceName + "/getOrderToApproveHeader"
);

export const getOrderToApproveDetails = createAction<string>(
  sliceName + "/getOrderToApproveDetails"
);

export const updateOrderToApproveItem = createAction<UpdatePrecartItemPayload>(
  sliceName + "/updateOrderToApproveItem"
);

export const updateOrderToApproveItems = createAction<UpdatePrecartItemsPayload>(
  sliceName + "/updateOrderToApproveItems"
);

export const deleteOrderToApproveItems = createAction<string>(
  sliceName + "/deleteOrderToApproveItems"
);

export const deleteOrderToApproveAFAItems = createAction<DeletePrecartItemsAFAPayload>(
  sliceName + "/deleteOrderToApproveAFAItems"
);

export const deleteOrderToApproveCategory = createAction<OrderItem[]>(
  sliceName + "/deleteOrderToApproveCategory"
);

//////// ORDER SIMULATION
export const getOrderSimulation = createAction<string>(sliceName + "/getOrderSimulation");

//////// THANK YOU PAGE
export const getApprovalThankYouPage = createAction<string[]>(
  sliceName + "/getApprovalThankYouPage"
);

/////// EXPORT CSV
export const exportOrderToApproveCSV = createAction<ExportOrderToApproveCSVPayload>(
  sliceName + "/exportOrderToApproveCSV"
);

//////////////////////////////////////////////////////////////////////////////////
////////////////////////// LIST OF ORDERS TO BE APPROVED /////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * get list of orders to be approved/rejected
 *
 * @param {PayloadAction<OrdersToApproveFilters>} params
 * @return {*}  {SagaIterator}
 */
function* getOrdersToApproveSaga(params: PayloadAction<OrdersToApproveFilters>): SagaIterator {
  try {
    yield put(setOrdersToApproveStatus("LOADING"));
    const { data } = yield call(orderService.getOrdersToApprove, {
      ...params.payload,
      status: "0",
    });
    yield put(saveOrdersToApproveList(data.data));
    yield put(setOrdersToApproveStatus("SUCCESS"));
  } catch (error) {
    yield put(setOrdersToApproveStatus("ERROR"));
    yield put(saveOrdersToApproveList(null));
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
///////////////////////// APPROVING / REJECTING ORDERS ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * approve/reject selected orders
 *
 * @param {PayloadAction<ApproveRejectOrdersPayload>} params
 * @return {*}  {SagaIterator}
 */
function* approveRejectOrdersSaga(action: PayloadAction<ApproveRejectOrdersPayload>): SagaIterator {
  try {
    yield put(setApproveRejectOrdersStatus("LOADING"));
    yield put(saveApproveRejectOrdersSimulate([]));

    const approveRejectOrdersResult: ApproveRejectOrdersResult = {
      status: action.payload.status,
      successfulSuborders: [],
      failedSuborders: [],
    };

    // simulate order if approving
    if (action.payload.status === 1) {
      const { data } = yield call(orderService.simulateOrdersToApprove, {
        subOrderId: action.payload.subOrderId,
      });

      //save simulate info
      const simulationResult = data?.data?.suborder;
      yield put(saveApproveRejectOrdersSimulate(simulationResult));

      const [successfulSuborders, failedSuborders] = partitionArray<ApproveRejectOrdersSimulate>(
        simulationResult,
        (suborder: ApproveRejectOrdersSimulate) => suborder.subOrderCanBeApproved
      );

      approveRejectOrdersResult.successfulSuborders = successfulSuborders.map((_) => _.subOrderId);
      approveRejectOrdersResult.failedSuborders = failedSuborders.map((_) => _.subOrderId);

      if (approveRejectOrdersResult.successfulSuborders.length === 0)
        throw new Error("SIMULATION_ERROR_CANT_APPROVE");
    } else approveRejectOrdersResult.successfulSuborders = action.payload.subOrderId;

    const { data } = yield call(orderService.updateOrdersToApprove, action.payload);

    if (data?.data?.resultmsg.includes("Failed")) throw new Error("APPROVAL_ERROR_CANT_APPROVE");
    else {
      yield put(saveApproveRejectOrdersResult(approveRejectOrdersResult));
      yield put(setApproveRejectOrdersStatus("SUCCESS"));
      action.payload.redirect?.("/account/orders-to-be-approved/thank-you-page");
    }
  } catch (error) {
    yield put(saveApproveRejectOrdersResult(null));
    yield put(setApproveRejectOrdersStatus("ERROR"));
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// ORDER DETAIL //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * get order details header
 *
 * @param {PayloadAction<SuborderApprovalIdsPayoad>} params
 * @return {*}  {SagaIterator}
 */
function* getOrderToApproveHeaderSaga(
  params: PayloadAction<SuborderApprovalIdsPayoad>
): SagaIterator {
  try {
    const { data } = yield call(orderService.getOrderToApproveHeader, params.payload);
    const suborderApprovalHeader = mapSuborderApprovalHeader(data?.data?.suborder);

    if (suborderApprovalHeader) yield put(saveSuborderApprovalHeader(suborderApprovalHeader));
    else yield put(saveSuborderApprovalHeader([]));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveSuborderApprovalHeader([]));
  }
}

/**
 * get order details main content
 *
 * @param {PayloadAction<string>} params
 * @return {*}  {SagaIterator}
 */
function* getOrderToApproveDetailsSaga(params: PayloadAction<string>): SagaIterator {
  yield put(setSuborderDetailsStatus("LOADING"));

  // call simulate
  try {
    yield call(orderService.simulateOrdersToApprove, {
      subOrderId: [params.payload],
    });
  } catch (error) {
    yield put(handleError(error));
  }

  // get order details
  try {
    const { data } = yield call(orderService.getOrderToApproveDetails, params.payload);

    if (data?.data?.orderStatus !== "W") throw new Error("ORDER_NOT_WAITING_APPROVAL"); // NOTE: this is okay b/c this saga is NOT called for TYP

    yield put(saveOrderId(data?.data?.orderId));

    const multidoorList = mapOrderMultidoorArray(data?.data?.multidoorResponseList);
    const multidoorAddresses = mapMultidoorAddress(data?.data?.multidoorResponseList);

    if (multidoorList && multidoorAddresses) {
      yield put(saveSuborderApprovalDetails(multidoorList));
      yield put(saveSuborderApprovalAddress(multidoorAddresses)); // update checkoutMultidoorAddress
      yield put(setSuborderDetailsStatus("SUCCESS"));
    } else yield put(setSuborderDetailsStatus("ERROR"));

    const subOrdersNotesAndPO = mapSubOrdersNotesAndPO(data?.data?.multidoorResponseList);
    if (subOrdersNotesAndPO) yield put(saveSubOrdersNotesAndPO(subOrdersNotesAndPO)); // update subOrdersNotesAndPO

    const totalPrice = data?.data?.grandTotal;
    if (totalPrice) yield put(saveSuborderApprovalTotalPrice(totalPrice)); // update checkoutTotalPrice
  } catch (error) {
    yield put(handleError(error));
    yield put(setSuborderDetailsStatus("ERROR"));
    yield put(saveSuborderApprovalDetails([]));
    yield put(saveSuborderApprovalAddress([]));
    yield put(saveSubOrdersNotesAndPO([]));
    yield put(saveSuborderApprovalTotalPrice(null));
  }
}

/**
 * Update suborder approval item
 *
 * @param {PayloadAction<UpdatePrecartItemPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updateOrderToApproveItemSaga(
  action: PayloadAction<UpdatePrecartItemPayload>
): SagaIterator {
  const { mainOrderItemId, ...payload } = action.payload;
  const id = mainOrderItemId ?? payload.orderItemId; //mainOrderItemId is used for AFA, for loading logic

  const details: OrderMultidoor[] = yield select(selectSuborderApprovalDetails);
  const suborderId = details?.[0].subOrderId;

  const orderId: string = yield select(selectOrderId);

  try {
    const servicePayload = getPutPrecartItemsPayload(
      action.payload as UpdatePrecartItemPayload,
      orderId
    );

    yield call(orderService.putOrderToApproveItems, servicePayload);
    yield put(getOrderToApproveDetails(suborderId));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    yield put(getOrderToApproveDetails(suborderId));
  } finally {
    yield put(stopCartLoading({ id: id, type: null }));
  }
}

/**
 * Update suborder approval items
 *
 * @param {PayloadAction<UpdatePrecartItemPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updateOrderToApproveItemsSaga(
  action: PayloadAction<UpdatePrecartItemsPayload>
): SagaIterator {
  const { mainOrderItemId, ...payload } = action.payload;
  const id = mainOrderItemId;

  const details: OrderMultidoor[] = yield select(selectSuborderApprovalDetails);
  const suborderId = details?.[0].subOrderId;

  const orderId: string = yield select(selectOrderId);

  try {
    const servicePayload = getPutPrecartItemsArrayPayload(action.payload, orderId);

    // const servicePayload = getPutPrecartItemsPayload(
    //   action.payload as UpdatePrecartItemPayload,
    //   orderId
    // );

    yield call(orderService.putOrderToApproveItems, servicePayload);
    yield put(getOrderToApproveDetails(suborderId));
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
    yield put(getOrderToApproveDetails(suborderId));
  } finally {
    yield put(stopCartLoading({ id: id, type: null }));
  }
}

/**
 * Delete specified item from suborder
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* deleteOrderToApproveItemsSaga(action: PayloadAction<string>): SagaIterator {
  const details: OrderMultidoor[] = yield select(selectSuborderApprovalDetails);
  const suborderId = details?.[0].subOrderId;

  const orderId: string = yield select(selectOrderId);

  const totalItems = yield select(numberOfOrderItemsNotOutOfStock);

  try {
    if (totalItems <= 1)
      yield put(showErrorPopup({ message: "ORDERS_TO_APPROVE_POPUP_CANT_DELETE" }));
    else {
      yield put(startCartLoading({ id: "multiple-delete", type: null }));
      yield call(orderService.deleteOrderToApproveItems, {
        orderId: orderId,
        orderItemId: [action.payload],
      });
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "multiple-delete", type: null }));
    yield put(getOrderToApproveDetails(suborderId));
  }
}

/**
 * Delete specified item from suborder
 *
 * @param {PayloadAction<string>} action
 * @return {*}  {SagaIterator}
 */
function* deleteOrderToApproveAFAItemsSaga(
  action: PayloadAction<DeletePrecartItemsAFAPayload>
): SagaIterator {
  const details: OrderMultidoor[] = yield select(selectSuborderApprovalDetails);
  const suborderId = details?.[0].subOrderId;

  const orderId: string = yield select(selectOrderId);

  const totalItems = yield select(numberOfOrderItemsNotOutOfStock);

  try {
    if (totalItems <= 1)
      yield put(showErrorPopup({ message: "ORDERS_TO_APPROVE_POPUP_CANT_DELETE" }));
    else {
      // yield put(startCartLoading({ id: "multiple-delete", type: null }));
      yield put(startCartLoading({ id: action.payload.mainOrderItemId, type: "delete" }));
      yield call(orderService.deleteOrderToApproveItems, {
        orderId: orderId,
        orderItemId: action.payload.orderItemIds,
      });
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: action.payload.mainOrderItemId, type: null }));
    yield put(getOrderToApproveDetails(suborderId));
  }
}

/**
 * Delete entire category from suborder
 *
 * @param {PayloadAction<OrderItem[]>} action
 * @return {*}  {SagaIterator}
 */
function* deleteOrderToApproveCategorySaga(action: PayloadAction<OrderItem[]>): SagaIterator {
  const details: OrderMultidoor[] = yield select(selectSuborderApprovalDetails);
  const suborderId = details?.[0].subOrderId;

  const orderId: string = yield select(selectOrderId);

  const totalItems = yield select(numberOfOrderItemsNotOutOfStock);

  try {
    if (totalItems - action.payload.length <= 1)
      yield put(showErrorPopup({ message: "ORDERS_TO_APPROVE_POPUP_CANT_DELETE" }));
    else {
      yield put(startCartLoading({ id: "multiple-delete", type: null }));

      const orderItemId: string[] = [];

      action.payload.forEach((orderItem) => {
        if (orderItem?.orders && orderItem?.orders?.length > 0) {
          orderItem?.orders.forEach((_) => {
            //push ids of single afa order item
            orderItemId.push(_.orderItemId);
          });
        } else {
          //normal order item
          orderItemId.push(orderItem.orderItemId);
        }
      });

      yield call(orderService.deleteOrderToApproveItems, {
        orderId: orderId,
        orderItemId: orderItemId,
      });
    }
  } catch (error) {
    yield put(handleError(error));
    yield put(showErrorPopup({ status: error?.response?.status }));
  } finally {
    yield put(stopCartLoading({ id: "multiple-delete", type: null }));
    yield put(getOrderToApproveDetails(suborderId));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// ORDER SIMULATION ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 *
 *
 * @param {PayloadAction<string>} params
 * @return {*}  {SagaIterator}
 */
function* getOrderSimulationSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(getOrderToApproveHeader({ subOrderId: [action.payload] }));
    yield put(getOrderToApproveDetails(action.payload));
  } catch (error) {
    yield put(handleError(error));
  }
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// THANK YOU PAGE ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

function* getApprovalThankYouPageSaga(action: PayloadAction<string[]>): SagaIterator {
  try {
    yield put(setSuborderDetailsStatus("LOADING"));

    const multidoorList: OrderMultidoor[] = [];
    const multidoorAddresses: OrderMultidoorAddress[] = [];

    for (let i = 0; i < action.payload.length; i++) {
      try {
        const { data } = yield call(orderService.getOrderToApproveDetails, action.payload[i]);

        const newOrder = mapOrderMultidoorArray(data?.data?.multidoorResponseList);
        if (newOrder) multidoorList.push(newOrder?.[0]);

        const newAddress = mapMultidoorAddress(data?.data?.multidoorResponseList);
        if (newAddress) multidoorAddresses.push(newAddress?.[0]);
      } catch (error) {
        yield put(handleError(error));
      }
    }

    if (multidoorList && multidoorAddresses) {
      yield put(saveSuborderApprovalDetails(multidoorList));
      yield put(saveSuborderApprovalAddress(multidoorAddresses));
      yield put(setSuborderDetailsStatus("SUCCESS"));
    } else yield put(setSuborderDetailsStatus("ERROR"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setSuborderDetailsStatus("ERROR"));

    yield put(saveSuborderApprovalDetails([]));
    yield put(saveSuborderApprovalAddress([]));
  }
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// EXPORT CSV //////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Export csv for multiple orders
 *
 * @param {PayloadAction<ExportOrderToApproveCSVPayload>} action
 * @return {*}  {SagaIterator}
 */
function* exportOrderToApproveCSVSaga(
  action: PayloadAction<ExportOrderToApproveCSVPayload>
): SagaIterator {
  try {
    yield put(saveExportOrderToApproveCSVStatus("LOADING"));
    const { data } = yield call(orderService.exportOrderToApproveCSV, action.payload);

    if (data) {
      const csv = data;
      const csvContent = csv.replace(new RegExp(",", "g"), ";");
      const encodedUri = "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(csvContent);
      if (csv) downloadURI(encodedUri, `orders-to-be-approved.csv`);
    }
    yield put(saveExportOrderToApproveCSVStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(saveExportOrderToApproveCSVStatus("ERROR"));
  }
}

export function* ordersToApproveSaga(): SagaIterator {
  ///////// LIST OF ORDERS TO BE APPROVED
  yield takeEvery(getOrdersToApprove.type, getOrdersToApproveSaga);

  //////// APPROVING / REJECTING ORDERS
  yield takeEvery(approveRejectOrders.type, approveRejectOrdersSaga);

  //////// ORDER DETAIL
  yield takeEvery(getOrderToApproveHeader.type, getOrderToApproveHeaderSaga);
  yield takeEvery(getOrderToApproveDetails.type, getOrderToApproveDetailsSaga);
  yield takeEvery(updateOrderToApproveItem.type, updateOrderToApproveItemSaga);
  yield takeEvery(updateOrderToApproveItems.type, updateOrderToApproveItemsSaga);
  yield takeEvery(deleteOrderToApproveItems.type, deleteOrderToApproveItemsSaga);
  yield takeEvery(deleteOrderToApproveAFAItems.type, deleteOrderToApproveAFAItemsSaga);
  yield takeEvery(deleteOrderToApproveCategory.type, deleteOrderToApproveCategorySaga);

  //////// ORDER SIMULATION
  yield takeEvery(getOrderSimulation.type, getOrderSimulationSaga);

  //////// THANK YOU PAGE
  yield takeEvery(getApprovalThankYouPage.type, getApprovalThankYouPageSaga);

  /////// EXPORT CSV
  yield takeEvery(exportOrderToApproveCSV.type, exportOrderToApproveCSVSaga);
}
