import { BFFApiDomain } from "../serviceUtils";

export const getInventoryTrackingUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/inventoryTracking";

export const areProductsTrackedUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/inventoryTrackingLine";

export const deleteInventoryTrackingUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/inventoryTracking/{inventoryTrackingId}";

export const addInventoryTrackingUrl =
  BFFApiDomain +
  "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/products/{catentryId}/inventoryTracking";

export const getAllNotificationsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notification/services";

export const getAllNotificationsPreferencesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notification/preferences";

export const updateNotificationsPreferencesUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notification/preferences";

export const markAsReadOrUnreadNotificationsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notifications";

export const markAsDeletedNotificationsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notifications";

export const getAllNotificationsListUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notifications";

export const getTotalNumberNotificationsUrl =
  BFFApiDomain + "/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/notification/count";
