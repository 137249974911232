import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { UploadedFile } from "../../../store/warranty-wizard/warrantyWizardInterface";
import CustomSelect, { CustomOptions } from "../../styled-UI/CustomSelect";
import InputComponent from "../../styled-UI/InputComponent";
import CustomText from "../../styled-UI/CustomText";
import UploadFiles from "../../styled-UI/UploadFiles";
import { useDispatch, useSelector } from "react-redux";
import { Door } from "../../../store/user/userInterfaces";
import {
  getShipmentSupportSR,
  submitShipmentSupportSR,
} from "../../../store/serviceRequests/serviceRequestSagas";
import {
  selectGetShipmentSupportSRStatus,
  selectShipmentSupportSRList,
  selectSubmitShipmentSupportSRStatus,
} from "../../../store/serviceRequests/serviceRequestSlice";
import LoaderSRPopup from "../../styled-UI/loader/service-request/LoaderSRPopup";
import ServiceRequestPopupSubmit from "./ServiceRequestPopupSubmit";
import {
  ShipmentSupportOrderSku,
  ShipmentSupportSRItem,
} from "../../../interfaces/serviceRequests";
import {
  SubmitShipmentSupportSRPayload,
  UploadFilePayload,
} from "../../../store/serviceRequests/serviceRequestInterfaces";
import useUploadFiles from "../../../hooks/useUploadFiles";
import { v4 as uuidv4 } from "uuid";

interface Props {
  selectedDoor: Door;
  serviceRequestIdentifier: string | null;
  orderIdentifier?: string | null;
  shippingCode?: string | null;
  b2bOrderIdentifier?: string | null;
  setHasSubmittedRequest: (val: boolean) => void;
}

const ServiceRequestPopupSR08 = ({
  selectedDoor,
  serviceRequestIdentifier,
  setHasSubmittedRequest,
  orderIdentifier,
  shippingCode,
  b2bOrderIdentifier,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const list = useSelector(selectShipmentSupportSRList);
  const listStatus = useSelector(selectGetShipmentSupportSRStatus);
  const submitStatus = useSelector(selectSubmitShipmentSupportSRStatus);

  //fields to send
  type FieldName = "NOTE" | "ATTACHMENT" | "ITEM";
  const [selectedReason, setSelectedReason] = useState<ShipmentSupportSRItem | undefined>();
  const [reasonList, setReasonList] = useState<ShipmentSupportSRItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<ShipmentSupportOrderSku | undefined>();
  const [itemList, setItemList] = useState<ShipmentSupportOrderSku[]>([]);
  const [note, setNote] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<UploadedFile[]>([]);
  const [errors, setErrors] = useState<FieldName[]>([]);
  const { translateLabel } = useTranslation();

  //error and loading upload request
  const [errorInUploading, setErrorInUploading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const { uploadFiles, uploadStatus } = useUploadFiles();

  //get simple request structure info
  useEffect(() => {
    if (selectedDoor)
      dispatch(
        getShipmentSupportSR({
          doorId: selectedDoor.orgentityId,
          serviceRequestTypeIdentifier: "SR8_SHIPMENT_SUPPORT",
          orderIdentifier: orderIdentifier ?? "",
          b2bOrderIdentifier: b2bOrderIdentifier ?? "",
        })
      );
  }, []);

  //add label to reason list
  useEffect(() => {
    if (list.length > 0) {
      const newList: ShipmentSupportSRItem[] = list.map((item) => {
        return {
          ...item,
          label: translateLabel("SERVICE_REQUEST_POPUP_" + item.requestReasonIdentifier),
        };
      });
      setSelectedReason(newList[0]);

      setReasonList(newList);
    }
  }, [list]);

  //add label and value to item list
  useEffect(() => {
    if (selectedReason && selectedReason.showItemList) {
      const newItemList: ShipmentSupportOrderSku[] = selectedReason?.orderSkuList
        ? selectedReason?.orderSkuList?.map((_) => {
            return { ..._, value: _.itemIdentifier, label: _.itemIdentifier?.replace("__", " ") };
          })
        : [];

      if (newItemList) {
        setItemList(newItemList);
      }
    }
  }, [selectedReason]);

  //clean errors attachments
  useEffect(() => {
    if (uploadedFile.length > 0) {
      const index = errors.indexOf("ATTACHMENT");
      if (index !== -1) {
        const newErrors = [...errors];

        newErrors.splice(index, 1);
        setErrors(newErrors);
      }
    }
    setErrorInUploading(false);
  }, [uploadedFile]);

  //submit request or show error when upload files is finished
  useEffect(() => {
    if (uploadStatus) {
      setIsLoadingUpload(false);

      if (uploadStatus.jwtStatus === "ERROR") {
        setErrorInUploading(true);
      } else if (uploadStatus.uploadedFilesStatus.length > 0) {
        const blobNames: string[] = [];

        for (let i = 0; i < uploadStatus.uploadedFilesStatus.length; i++) {
          const current = uploadStatus.uploadedFilesStatus[i];

          if (current.status === "ERROR") {
            return setErrorInUploading(true);
          }
          if (current.blobName) {
            blobNames.push(current.blobName);
          }
        }

        if (blobNames.length > 0) {
          submit(blobNames);
        }
      }
    }
  }, [uploadStatus]);

  const handleNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);

    const index = errors.indexOf("NOTE");
    if (index !== -1) {
      const newErrors = [...errors];

      newErrors.splice(index, 1);
      setErrors(newErrors);
    }
  };

  const handleItem = (option: CustomOptions) => {
    setSelectedItem(option as ShipmentSupportOrderSku);

    const index = errors.indexOf("ITEM");
    if (index !== -1) {
      const newErrors = [...errors];

      newErrors.splice(index, 1);
      setErrors(newErrors);
    }
  };

  const submit = (blobNames: string[]) => {
    if (serviceRequestIdentifier) {
      const submitObj: SubmitShipmentSupportSRPayload = {
        doorId: selectedDoor.orgentityId,
        serviceRequestIdentifier: serviceRequestIdentifier,
        serviceRequestTypeIdentifier: "SR8_SHIPMENT_SUPPORT",
        orderIdentifier: orderIdentifier ?? "",
        shippingCode: shippingCode ?? "",
        b2bOrderIdentifier: b2bOrderIdentifier ?? "",
      };

      if (selectedReason) {
        submitObj.reasonIdentifier = selectedReason.requestReasonIdentifier;
      }
      if (note) {
        submitObj.textNoteValue = note;
      }
      if (selectedItem) {
        submitObj.itemIdentifier = selectedItem.itemIdentifier;
      }
      if (blobNames.length > 0) {
        submitObj.attachmentBlobNameList = blobNames;
      }
      dispatch(submitShipmentSupportSR(submitObj));
    }
  };

  const submitRequest = () => {
    //check for errors
    const newErrors = [...errors];

    if (!selectedReason) {
    }

    if (!selectedItem) {
      newErrors.push("ITEM");
    }

    if (selectedReason?.isMandatoryAttachment && uploadedFile.length === 0) {
      newErrors.push("ATTACHMENT");
    }

    if (
      selectedReason?.isMandatoryAttachment &&
      uploadedFile.length > 0 &&
      selectedReason?.minAttachmentQuantity !== undefined &&
      uploadedFile.length < selectedReason?.minAttachmentQuantity
    ) {
      newErrors.push("ATTACHMENT");
    }

    setErrors(newErrors);

    //send request
    if (newErrors.length === 0 && serviceRequestIdentifier && orderIdentifier && shippingCode) {
      if (uploadedFile.length > 0) {
        const payload = uploadedFile.map((_) => {
          if (_ && _.file)
            return {
              file: _.file,
              resourceIdentifier: serviceRequestIdentifier,
              resourceType: "servicerequest",
              fileIdentifierKey: "it.luxottica.service.request.attachment",
              fileLabel: "genericfile",
              id: uuidv4(),
            };
        });

        setIsLoadingUpload(true);
        uploadFiles(payload as UploadFilePayload[], {
          doorId: selectedDoor.orgentityId,
          resourceType: "servicerequest",
          resourceIdentifier: serviceRequestIdentifier,
        });
      } else {
        submit([]);
      }
    }
  };

  return (
    <>
      {listStatus === "LOADING" && <LoaderSRPopup />}
      {listStatus === "ERROR" && (
        <CustomText as="p" fontSizePx={13} lineHeightPx={18} marginBottomPx={16} color="red">
          {submitStatus === "ERROR" && translateLabel("ERROR_POPUP_DEFAULT_MESSAGE")}
        </CustomText>
      )}

      {listStatus === "SUCCESS" && list && (
        <div>
          <CustomSelect
            type="white"
            options={reasonList}
            value={selectedReason}
            isSearchable={false}
            required
            onChange={(option) => {
              setSelectedReason(option as ShipmentSupportSRItem);
              setErrors([]);
            }}
          />
          {selectedReason?.showItemList && (
            <div className="textarea-wrapper mt-4">
              <CustomSelect
                type="white"
                required
                options={itemList}
                value={selectedItem}
                isSearchable={false}
                onChange={handleItem}
                helperText={errors.includes("ITEM") ? translateLabel("REQUIRED_FIELD") : ""}
                isError={errors.includes("ITEM")}
              />
            </div>
          )}
          {selectedReason?.showTextNote && (
            <div className="textarea-wrapper mt-4">
              <InputComponent
                type="text"
                as="textarea"
                placeholder={translateLabel("SERVICE_REQUEST_POPUP_NOTE")}
                resizableTextarea
                maxLength={500}
                helperText={errors.includes("NOTE") ? translateLabel("REQUIRED_FIELD") : ""}
                colorStyle={errors.includes("NOTE") ? "error" : "enabled"}
                onChange={handleNote}
                value={note}
              />
            </div>
          )}
          {selectedReason?.showAttachmentSelector && (
            <AttatchmentContainer>
              <CustomText as="div" fontSizePx={13} lineHeightPx={18} marginBottomPx={16}>
                {translateLabel("SERVICE_REQUEST_POPUP_ATTACHMENT_TITLE")}
              </CustomText>
              <div>
                <UploadFiles
                  imageLabel={translateLabel("SERVICE_REQUEST_POPUP_UPLOAD_A_FILE")}
                  uploadedFile={uploadedFile}
                  isMandatory={selectedReason?.isMandatoryAttachment}
                  setUploadedFile={setUploadedFile} //TODO CLEAN ERROR
                  showRequiredError={errors.includes("ATTACHMENT")}
                  hasWhiteBackground
                  maxFileNumber={selectedReason?.maxAttachmentQuantity ?? 1}
                />
              </div>
            </AttatchmentContainer>
          )}
          <ServiceRequestPopupSubmit
            submitRequest={submitRequest}
            submitStatus={submitStatus}
            isLoading={isLoadingUpload}
            errorInUploading={errorInUploading}
            setHasSubmittedRequest={setHasSubmittedRequest}
          />
        </div>
      )}
    </>
  );
};

const AttatchmentContainer = styled.div`
  margin-top: 2rem;
  max-width: 60%;
`;

export default ServiceRequestPopupSR08;
