import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import { MostUsedChip } from "../../../../../interfaces/mostUsedInterfaces";
import Chip from "../../../../styled-UI/Chip";
import CustomText from "../../../../styled-UI/CustomText";
import { LoadeMostUsedServicesMyAccount } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import { ChipsHandler } from "../useSelectedMostUsed";

type Props = {
  chipsHandler: ChipsHandler;
  isMaxChipsReached: boolean;
  isLoading: boolean;
};

const ChipsServicesMyAccount = ({
  chipsHandler,
  isMaxChipsReached,
  isLoading,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const isChipNotSelected = (x: MostUsedChip) =>
    !chipsHandler.selectedChips.find((y) => y.name === x.name);

  if (!isLoading && chipsHandler.availableChips.length === 0) return <></>;
  return (
    <>
      <Wrapper>
        <CustomText as="span" font="font-bold" fontSizePx={18} marginBottomPx={24}>
          {translateLabel("MOST_USED_POPUP_SERVICES_MYACCOUNT")}
        </CustomText>
        <div>
          {isLoading || !chipsHandler.availableChips.length ? (
            <LoadeMostUsedServicesMyAccount />
          ) : (
            chipsHandler.availableChips.map((chip) => (
              <ChipContainer key={chip.name}>
                <Chip
                  text={translateLabel(chip.label)}
                  letterCase="uppercase"
                  active={false}
                  onClick={() => !isMaxChipsReached && chipsHandler.selectChip(chip)}
                  disabled={isMaxChipsReached || !isChipNotSelected(chip)}
                  showTitle
                  noMaxWidth
                />
              </ChipContainer>
            ))
          )}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 2.5rem;
`;

const ChipContainer = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.625rem;
`;

export default ChipsServicesMyAccount;
