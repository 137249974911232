import React from "react";
import LoaderElement from "../LoaderElement";

const LoaderHomepageMostUsed = (): JSX.Element => {
  return (
    <>
      <LoaderElement height={48} width={170} radius={20} style="default" />
      <LoaderElement height={48} width={145} radius={20} style="default" />
      <LoaderElement height={48} width={115} radius={20} style="default" />
      <LoaderElement height={48} width={160} radius={20} style="default" />
      <LoaderElement height={48} width={110} radius={20} style="default" />
      <LoaderElement height={48} width={100} radius={20} style="default" />
    </>
  );
};

export default LoaderHomepageMostUsed;
