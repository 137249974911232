import React from "react";

import LoaderElement from "../LoaderElement";
import LoaderElementCircle from "../LoaderElementCircle";

const SeeAllVariantLoader = (): JSX.Element => {
  return (
    <>
      <LoaderElement height={30} width={60} />
      <LoaderElementCircle height={40} width={40} />
    </>
  );
};

export default SeeAllVariantLoader;
