import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { MostUsedItem } from "../../../../interfaces/cmsInterfaces";
import { ItemsMap, Page } from "../../../../interfaces/gridInterfaces";
import { getPagePath } from "../../../../routing/routesUtils";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import { selectHomePageLayout, selectItemsMap } from "../../../../store/store/storeSlice";
import { useCanUrlBeRendered, useHandleCmsLink } from "../../../../utils/cmsUtils";
import Chip from "../../../styled-UI/Chip";

interface Props {
  preselectedChips: JSX.Element;
}

const CMSMostUsed = ({ preselectedChips }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const { canUrlBeRendered } = useCanUrlBeRendered();

  const itemsMap = useSelector(selectItemsMap);
  const layout = useSelector(selectHomePageLayout);

  const mostUsedContent = useMemo(() => getMostUsedCMS(layout, itemsMap), [layout, itemsMap]);

  const canLinkBeRendered = (linkString?: string) => {
    if (!linkString) return true; // if no link is provided, render (to keep previous behavior)
    return canUrlBeRendered(getPagePath(linkString));
  };

  const handleRedirect = (link?: string, externalLink?: boolean, openInNewTab?: boolean): void => {
    handleCmsLink(link, externalLink, openInNewTab);
    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  return (
    <>
      {preselectedChips}
      {mostUsedContent?.map((mostUsed: MostUsedItem, index: number) => {
        const { linkString, title, titleColour, externalLink, openInNewTab } = mostUsed;

        if (canLinkBeRendered(linkString))
          return (
            <Chip
              key={index}
              letterCase="uppercase"
              customColor={titleColour}
              text={title}
              hideIcon={true}
              onClick={() => {
                handleRedirect(linkString, externalLink, openInNewTab);
              }}
            />
          );
      })}
    </>
  );
};

const getMostUsedCMS = (layout: Page, itemsMap: ItemsMap): MostUsedItem[] => {
  if (layout && layout.rows) {
    let contentPage: MostUsedItem[] = [];
    const mostUsedRow = layout.rows.filter((row) => row.name === "most-used");

    if (mostUsedRow && mostUsedRow[0]?.cols && mostUsedRow[0].cols[0]) {
      const id = mostUsedRow[0].cols[0].id;

      if (id && itemsMap[id]) {
        contentPage = itemsMap[id]?.result?.map((obj: MostUsedItem) => {
          return obj;
        });
      }
    }
    return contentPage;
  }

  return [];
};

export default CMSMostUsed;
