import React from "react";
import styled from "styled-components";
import LoaderElement from "../LoaderElement";

const LoaderCheckbox = () => (
  <Checkbox>
    <LoaderElement height={25} radius={8} />
    <LoaderElement height={25} />
  </Checkbox>
);

const LoaderTooltip = (): JSX.Element => {
  return (
    <SelectionSection>
      <Columns>
        <Rows>
          <LoaderElement height={25} width={150} />
          {Array.from({ length: 10 }, (v, i) => i).map((_) => (
            <LoaderCheckbox key={_} />
          ))}
        </Rows>
        <Rows>
          <LoaderElement height={25} width={100} />
          {Array.from({ length: 5 }, (v, i) => i).map((_) => (
            <LoaderCheckbox key={_} />
          ))}
        </Rows>
        <Rows>
          <LoaderElement height={25} width={130} />
          {Array.from({ length: 8 }, (v, i) => i).map((_) => (
            <LoaderCheckbox key={_} />
          ))}
        </Rows>
      </Columns>
    </SelectionSection>
  );
};

export default LoaderTooltip;

const SelectionSection = styled.div``;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: space-between;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Checkbox = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  column-gap: 1rem;
`;
