import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";

import {
  MostUsedChip,
  MostUsedChipCatalogueLocal,
  MostUsedChipLocal,
} from "../../../../interfaces/mostUsedInterfaces";
import {
  selectBrandLabels,
  selectLensesMenu,
  selectMenu,
  selectThirdLevelMenu,
} from "../../../../store/store/storeSlice";
import {
  selectActiveDoor,
  selectIsSubuser,
  selectMostUsedContent,
  selectMostUsedLoading,
} from "../../../../store/user/userSlice";
import {
  generateServicesAndMyAccountChips,
  getCatalogueChipFromMenu,
  getSelectedChips,
  getServicesMyAccountChipFromId,
} from "../../../../utils/mostUsedUtils";
import useCategoriesData, { getCategoriesInfo } from "./useCategoriesData";

export interface ChipsHandler {
  availableChips: MostUsedChipLocal[];
  selectedChips: MostUsedChipLocal[];
  selectChip: (chip: MostUsedChip) => void;
  deselectChip: (chip: MostUsedChip) => void;
  isSelectionLoading: boolean;
}

const useSelectedMostUsed = (): ChipsHandler => {
  const { labels, translateLabel } = useTranslation();
  const canRender = useCanRender();

  const mostUsedChips = useSelector(selectMostUsedContent).bookmarks;
  const { get: mostUsedLoading } = useSelector(selectMostUsedLoading);

  const menuData = useSelector(selectMenu)?.find((_) => _.identifier === "PRODUCTS");
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const lensesMenu = useSelector(selectLensesMenu);
  const brandLabels = useSelector(selectBrandLabels);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////// AVAILABLE SERVICES&MYACCOUNT CHIPS ///////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  /////////////////// Services&MyAccount chips available for selection in the popup
  /////////////////// (only those for which the privileges are active)
  const [availableServicesMyAccountChips, setAvailableServicesMyAccountChips] = useState<
    MostUsedChipLocal[]
  >([]);

  useEffect(() => {
    const availableChips = generateServicesAndMyAccountChips(canRender, isSubuser, mainDoor);
    setAvailableServicesMyAccountChips(availableChips);
  }, [labels]);

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// SELECTED CHIPS /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // selected chips displayed in HP and in the selection area of the popup
  const [selectedChips, setSelectedChips] = useState<MostUsedChipLocal[]>([]);

  // get third-level/lenses menu data for categories present in most used's bookmarks
  const categoriesData = useCategoriesData();

  useEffect(() => {
    if (
      mostUsedLoading !== "LOADING" && // got selected data from API
      availableServicesMyAccountChips.length > 0 && // computed possible services&myaccount bookmarks
      !categoriesData.isCategoriesLoading // downloaded third-level menu for all relevant categories
    ) {
      // generate local chips from the API's data by:
      //  - adding urls + labels for Services&MyAccount bookmarks
      //  - reverse-engineering the labels for Catalogue bookmarks from the third-level menu
      const newSelectedChips = getSelectedChips(
        mostUsedChips,
        categoriesData,
        availableServicesMyAccountChips,
        thirdLevelMenu,
        brandLabels,
        lensesMenu
      );

      //TODO: STANDARDIZE THIS INSTEAD OF HARD-CODING IT
      if (canRender("LENS_DIGITAL_CATALOGUE")) {
        const digitalCatalogChip: MostUsedChipCatalogueLocal = {
          label: translateLabel("MOST_USED_DIGITAL_CATALOG"),
          showAsDefault: true,
          name: "",
          type: "Catalogue",
          url: "/digital-catalog",
          catalogueInfo: {
            menuCategory: "LENSES",
            facets: [{ facetName: "lenses", facetValue: "lenses" }],
          },
        };
        newSelectedChips.unshift(digitalCatalogChip);
      }

      if (canRender("RX") || canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")) {
        const newPrescriptionChip: MostUsedChipCatalogueLocal = {
          label: translateLabel("MOST_USED_NEW_PRESCRIPTION"),
          showAsDefault: true,
          name: "",
          type: "Catalogue",
          url: canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")
            ? "/rx-prescription?rxFlow=Essilor"
            : canRender("RX")
            ? "/rx-prescription?rxFlow=Authentics"
            : "",
          catalogueInfo: {
            menuCategory: "LENSES",
            facets: [{ facetName: "lenses", facetValue: "lenses" }],
          },
        };
        newSelectedChips.unshift(newPrescriptionChip);
      }

      setSelectedChips(newSelectedChips);
    }
  }, [mostUsedChips, mostUsedLoading, availableServicesMyAccountChips, categoriesData]);

  /////////////////// exported helper functions
  const selectChip = (chip: MostUsedChip) => {
    const newChip =
      chip.type === "Catalogue"
        ? getCatalogueChipFromMenu(
            chip,
            [getCategoriesInfo(chip, menuData, "SUCCESS")],
            thirdLevelMenu,
            brandLabels,
            lensesMenu
          )
        : getServicesMyAccountChipFromId(chip.name, availableServicesMyAccountChips);
    if (newChip) setSelectedChips([...selectedChips, newChip]);
  };

  const deselectChip = (chip: MostUsedChip) =>
    setSelectedChips((old) => old.filter((_) => _.name !== chip.name));

  return useMemo(() => {
    return {
      availableChips: availableServicesMyAccountChips,
      selectedChips,
      selectChip,
      deselectChip,
      isSelectionLoading: categoriesData.isCategoriesLoading,
    };
  }, [
    mostUsedChips,
    availableServicesMyAccountChips,
    selectedChips,
    selectChip,
    deselectChip,
    categoriesData.isCategoriesLoading,
  ]);
};

export default useSelectedMostUsed;
