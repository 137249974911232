import { AxiosResponse } from "axios";
import { SubOrderPayload } from "../cart/cartInterfaces";

import { createAndExecuteService } from "../serviceUtils";
import {
  CarnetMultidoor,
  CheckoutItems,
  SubmitEssilorOrderPayload,
  SubmitOrderPayload,
} from "./checkoutInterfaces";
import {
  cartUrl,
  checkoutUrl,
  orderRecapUrl,
  updateCartUrl,
  exportCSVTYPUrl,
  carnetCheckUrl,
  repeatOrderUrl,
} from "./checkoutUrls";

export default {
  /////////////// CHECKOUT

  // adding items to cart (ie. to order confirmation page)
  postCart: async (data: CheckoutItems, isEssilor?: boolean): Promise<AxiosResponse> => {
    if (isEssilor) {
      const queryParams = new URLSearchParams();
      queryParams.append("type", "ONE_PORTAL");
      return createAndExecuteService(cartUrl, "POST", queryParams, data);
    }
    return createAndExecuteService(cartUrl, "POST", null, data);
  },

  // getting order confirmation page content
  getCart: async (isEssilor?: boolean): Promise<AxiosResponse> => {
    if (isEssilor) {
      const queryParams = new URLSearchParams();
      queryParams.append("type", "ONE_PORTAL");
      return createAndExecuteService(cartUrl, "GET", queryParams);
    }
    return createAndExecuteService(cartUrl, "GET");
  },

  // submitting order (ie. to thank you page)
  postCheckout: async (payload: SubmitOrderPayload): Promise<AxiosResponse> => {
    return createAndExecuteService(checkoutUrl, "POST", null, payload);
  },

  postEssilorCheckout: async (payload: SubmitOrderPayload): Promise<AxiosResponse> => {
    const queryParams = new URLSearchParams();
    queryParams.append("type", "ONE_PORTAL");
    return createAndExecuteService(checkoutUrl, "POST", queryParams, payload);
  },

  // setting PO number and notes for each suborder
  updateCart: async (payload: SubOrderPayload, orderId: string): Promise<AxiosResponse> => {
    return createAndExecuteService(
      updateCartUrl.replace("{subOrderId}", payload.subOrderId).replace("{orderId}", orderId),
      "PATCH",
      null,
      payload.payload
    );
  },

  updateCartEssilor: async (
    payload: SubmitEssilorOrderPayload,
    orderId: string
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(
      updateCartUrl.replace("{subOrderId}", payload.subOrderId).replace("{orderId}", orderId),
      "PATCH",
      null,
      payload
    );
  },

  /////////////// carnet RX

  // check carnet
  postCarnetCheck: async (payload: CarnetMultidoor): Promise<AxiosResponse> => {
    const { doorId, ...body } = payload;
    const searchParams = new URLSearchParams();
    searchParams.append("doorId", doorId);
    return createAndExecuteService(carnetCheckUrl, "POST", searchParams, body);
  },

  /////////////// THANK YOU PAGE

  // getting thank you page content
  getOrderRecap: async (orderId: string): Promise<AxiosResponse> => {
    return createAndExecuteService(orderRecapUrl.replace("{checkoutOrderId}", orderId), "GET");
  },

  // print csv in typ
  exportCSV: async (orderId: string): Promise<AxiosResponse> => {
    return createAndExecuteService(exportCSVTYPUrl.replace("{id}", orderId), "GET");
  },

  // repeatOrder
  repeatOrder: async (orderId: string): Promise<AxiosResponse> => {
    return createAndExecuteService(repeatOrderUrl.replace("{orderId}", orderId), "POST");
  },
};
