import React from "react";
import styled from "styled-components";
import CustomText from "../../CustomText";
import LoaderElement from "../LoaderElement";

const LoaderSelection = (): JSX.Element => {
  return (
    <SelectionSection>
      <CustomText as="span" font="font-bold" fontSizePx={18} marginBottomPx={24}>
        Your selection:
      </CustomText>
      <ChipTooltipContainer>
        <LoaderElement height={46} width={160} radius={20} style="dark" />
        <LoaderElement height={46} width={130} radius={20} style="dark" />
        <LoaderElement height={46} width={250} radius={20} style="dark" />
        <LoaderElement height={46} width={210} radius={20} style="dark" />
        <LoaderElement height={46} width={170} radius={20} style="dark" />
      </ChipTooltipContainer>
    </SelectionSection>
  );
};

export default LoaderSelection;

const SelectionSection = styled.div`
  padding: 2.5rem 3rem 3rem;
  background-color: ${(props) => props.theme.palette.gray.light};
  margin: 3rem -3rem 3rem -3rem;
  border-radius: 0 0 0.25rem 0.25rem;
`;

const ChipTooltipContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;
