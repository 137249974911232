import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import useGetThirdLevelMenu from "../../../../../hooks/useGetThirdLevelMenu";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import {
  selectGetMenuStatus,
  selectLxConfigurations,
  selectMenu,
} from "../../../../../store/store/storeSlice";
import ChipWithTooltip from "../../../../styled-UI/ChipWithTooltip";
import CustomText from "../../../../styled-UI/CustomText";
import { LoaderMostUsedCatalogue } from "../../../../styled-UI/loader/most-used/LoaderCategoriesAndServices";
import { ChipsHandler } from "../useSelectedMostUsed";
import ChipsCatalogueTooltip from "./ChipsCatalogueTooltip";
import { MOST_USED_MODAL_WIDTH } from "../CustomizeHomeModal";

import { useBreakpoint } from "../../../../../assets/theme/breakpoint";

interface Props {
  chipsHandler: ChipsHandler;
  isMaxChipsReached: boolean;
  isLoading: boolean;
}

const ChipsCatalogue = ({ chipsHandler, isMaxChipsReached, isLoading }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const LXConfiguration = useSelector(selectLxConfigurations);
  const isEssilorMode =
    LXConfiguration?.find((element) => element.key === "com.luxottica.oneportal.lenses.menu.enable")
      ?.value === "Y";

  const menuStatus = useSelector(selectGetMenuStatus);
  const showLoader = menuStatus === "LOADING" || isLoading;

  const breakpoints = useBreakpoint();

  const tooltipWidth = breakpoints["md"]
    ? MOST_USED_MODAL_WIDTH["md"]
    : MOST_USED_MODAL_WIDTH["lg"];

  const menuData = useSelector(selectMenu);
  const getThirdLevelMenu = useGetThirdLevelMenu();

  const [secondLevelMenus, setSecondLevelMenus] = useState<SecondLevelMenu[]>([]);

  useEffect(() => {
    // get second level menu for products
    if (menuData) {
      //TODO: Manage this for future development - when Essilor Lenses are implemented
      const secondLevelMenus = isEssilorMode
        ? menuData
            ?.find((_) => _.identifier === "PRODUCTS")
            ?.catalogGroupView?.filter(
              (_) =>
                _.identifier !== "Lenses" &&
                _.identifier !== "LENSES" &&
                _.identifier !== "Instruments" &&
                _.identifier !== "INSTRUMENTS"
            )
        : menuData?.find((_) => _.identifier === "PRODUCTS")?.catalogGroupView;
      secondLevelMenus && setSecondLevelMenus(secondLevelMenus);
    }
  }, [menuData]);

  if (!showLoader && secondLevelMenus.length === 0) return <></>;
  return (
    <CategorySection>
      <CustomText as="span" font="font-bold" fontSizePx={18} marginBottomPx={24}>
        {translateLabel("MOST_USED_POPUP_CATEGORIES")}
      </CustomText>
      <ChipTooltipContainer>
        {showLoader ? (
          <LoaderMostUsedCatalogue />
        ) : (
          secondLevelMenus?.map((selectedCategoryMenu: SecondLevelMenu) => (
            <ChipWithTooltip
              key={selectedCategoryMenu.identifier}
              text={selectedCategoryMenu.name}
              disabled={isMaxChipsReached}
              onClick={() => getThirdLevelMenu(true, selectedCategoryMenu)}
              tooltipFunctionality={"onClick"}
              width={tooltipWidth}
            >
              <ChipsCatalogueTooltip
                categoryMenu={selectedCategoryMenu}
                chipsHandler={chipsHandler}
              />
            </ChipWithTooltip>
          ))
        )}
      </ChipTooltipContainer>
    </CategorySection>
  );
};

const CategorySection = styled.div`
  padding-bottom: 2.5rem;
`;

const ChipTooltipContainer = styled.div`
  position: relative;
`;

export default ChipsCatalogue;
