import React, { useState } from "react";
import styled from "styled-components/macro";
import clsx from "clsx";

interface IconButtonProps {
  type?:
    | "primary"
    | "white-no-border"
    | "white-with-border"
    | "outline-light"
    | "no-background-white"
    | "no-background-primary"
    | "no-background-border-primary";

  size: "xl" | "lg" | "md" | "sm" | "xs";
  disabled?: boolean;
  outOfStock?: boolean;
  isCarousel?: boolean;
  // header?: boolean;
  icon: any; //React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  upsideDown?: boolean;
  isPlaylist?: boolean;
  borderRadius?: number;
  hoverType?: "white-no-border";
  "data-element-id"?: string | null;
  "data-description"?: string | null;
}

const IconButton = ({
  type = "primary",
  size,
  disabled = false,
  onClick,
  icon,
  outOfStock = false,
  upsideDown,
  isCarousel = false,
  isPlaylist = false,
  borderRadius,
  hoverType,
  "data-element-id": dataElementId,
  "data-description": dataDescrption,
}: IconButtonProps): JSX.Element => {
  const classNameIcon = clsx(
    (outOfStock || disabled) && "button-out-of-stock",
    size === "xl" && "button-extra-large",
    size === "lg" && "button-large",
    size === "md" && "button-medium",
    size === "sm" && "button-small",
    size === "xs" && "button-extra-small",
    type === "primary" && "button-primary",
    type === "white-no-border" && "button-white-no-border",
    type === "white-with-border" && "button-white-with-border",
    type === "outline-light" && "button-outline-light",
    type === "no-background-white" && "button-white-no-background",
    type === "no-background-primary" && "button-primary-no-background",
    type === "no-background-border-primary" && "button-no-background-border-primary",
    upsideDown && "upside-down",
    hoverType && "hover-white-no-border"
  );

  // const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!outOfStock && !disabled) {
      onClick && onClick(e);
      // isCarousel && setTriggerAnimation(true);
      // isCarousel &&
      //   setTimeout(() => {
      //     setTriggerAnimation(false);
      //   }, 1000);
    }
  };

  return (
    <Container>
      {/* {isCarousel && (
        <LoadingAnimation className={triggerAnimation ? "animation" : ""}>
          <div className="circle__box">
            <div className="circle__wrapper circle__wrapper--right">
              <div className="circle__whole circle__right"></div>
            </div>
            <div className="circle__wrapper circle__wrapper--left">
              <div className="circle__whole circle__left"></div>
            </div>
          </div>
        </LoadingAnimation>
      )} */}
      <StyledIcon
        onClick={(e) => handleClick(e)}
        className={classNameIcon}
        disabled={disabled}
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
        {...(dataDescrption ? { "data-description": dataDescrption } : {})}
        isPlaylist={isPlaylist}
        borderRadius={borderRadius}
      >
        {icon}
      </StyledIcon>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledIcon = styled.button<{ isPlaylist: boolean; borderRadius?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  border-width: 0px;
  border-radius: 50%;
  outline: 0;

  &.upside-down {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    fill: ${(props) => props.theme.palette.primary};
  }

  //SIZES
  &.button-extra-large {
    width: 4.25rem;
    height: 4.25rem;
    svg {
      width: 1.8125rem;
      height: 1.8125rem;
    }
  }

  &.button-large {
    width: ${(props) => (props.isPlaylist ? "1rem" : "3rem")};
    height: ${(props) => (props.isPlaylist ? "1rem" : "3rem")};
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.button-medium {
    width: 2.5rem;
    height: 2.5rem;
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &.button-small {
    width: 2rem;
    height: 2rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &.button-extra-small {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.button-header {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  //COLORI
  &.button-primary {
    background-color: ${(props) => props.theme.palette.primary};
    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  &.button-white-no-border {
    background-color: ${(props) => props.theme.palette.white};
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }

    &.hover-white-no-border:hover {
      background-color: ${(props) => props.theme.palette.primary};
      svg,
      svg path {
        fill: ${(props) => props.theme.palette.white};
      }
    }
  }

  &.button-white-with-border {
    background-color: ${(props) => props.theme.palette.white};
    border: 2px solid ${(props) => props.theme.palette.gray.medium};
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  &.button-outline-light {
    border: 2px solid ${(props) => props.theme.palette.white};
    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  &.button-white-no-background {
    background-color: transparent;
    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  &.button-primary-no-background {
    background-color: transparent;
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }

    &.button-out-of-stock {
      background-color: transparent;
      svg {
        fill: ${(props) => props.theme.palette.gray.dark};
      }
    }
  }

  &.button-no-background-border-primary {
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.palette.primary};
    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  /* &.button-disabled {
    border-color: ${(props) => props.theme.palette.gray.medium};
    background-color: ${(props) => props.theme.palette.gray.light};
    svg {
      fill: ${(props) => props.theme.palette.gray.dark};
    }
  } */

  &.button-out-of-stock {
    border-color: ${(props) => props.theme.palette.gray.dark};
    background-color: ${(props) => props.theme.palette.gray.dark};
    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}px`};
  
}
`;

//Animation
// const LoadingAnimation = styled.div`
//   position: absolute;
//   display: none;

//   &.animation {
//     display: unset;
//     bottom: -0.375rem;
//     left: -0.375rem;
//   }

//   .circle__box {
//     width: 3.75rem;
//     height: 3.75rem;
//     position: relative;
//   }

//   .circle__wrapper {
//     width: 1.875rem;
//     height: 3.75rem;
//     position: absolute;
//     top: 0;
//     overflow: hidden;
//   }

//   .circle__wrapper--right {
//     right: 0;
//   }

//   .circle__wrapper--left {
//     left: 0;
//   }

//   .circle__whole {
//     width: 3.625rem;
//     height: 3.625rem;
//     border: 1px solid transparent;
//     border-radius: 50%;
//     position: absolute;
//     top: 0;
//     transform: rotate(-135deg);
//   }

//   .circle__right {
//     border-top: 1px solid ${(props) => props.theme.palette.gray.dark};
//     border-right: 1px solid ${(props) => props.theme.palette.gray.dark};
//     right: 1px;
//     animation: circleRight 1s linear forwards;
//   }

//   .circle__left {
//     border-bottom: 1px solid ${(props) => props.theme.palette.gray.dark};
//     border-left: 1px solid ${(props) => props.theme.palette.gray.dark};
//     left: 1px;
//     animation: circleLeft 1s linear forwards;
//   }

//   @keyframes circleRight {
//     0% {
//       transform: rotate(-135deg);
//     }
//     50%,
//     99% {
//       transform: rotate(45deg);
//     }
//   }

//   @keyframes circleLeft {
//     0%,
//     50% {
//       transform: rotate(-135deg);
//     }
//     99% {
//       -webkit-transform: rotate(45deg);
//     }
//   }
// `;

IconButton.displayName = "IconButton";
export default IconButton;
