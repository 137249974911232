import { MutableRefObject, useEffect } from "react";

/**
 * This Hook can be used for detecting clicks outside a passed ref
 */
const useOnClickOutside = <T extends Element>(
  ref: MutableRefObject<T | null> | null,
  onClickOutside: () => void
): void => {
  useEffect(() => {
    // Invoke Function onClick outside of element
    const handleClickOutside = (event: MouseEvent) => {
      if (ref?.current && event?.target instanceof Element && !ref.current.contains(event.target))
        onClickOutside();
    };

    document.addEventListener("mousedown", handleClickOutside); // bind

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // dispose
    };
  }, [ref, onClickOutside]);
};

export default useOnClickOutside;
