import React from "react";
import styled from "styled-components/macro";
import clsx from "clsx";
import { ReactComponent as LoaderIcon } from "../../assets/icons/loader.svg";
import { FontFamilies } from "../../store/store/storeInterfaces";

interface Props {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  type:
    | "primary"
    | "secondary"
    | "tertiary"
    | "primary-dark"
    | "primary-light"
    | "transparent"
    | "tertiary-bordered";
  shape?: "square" | "rounded";
  size?: "xs" | "sm" | "md";
  children?: any;
  disabled?: boolean;
  active?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  buttonType?: "button" | "submit" | "reset";
  isLoading?: boolean;
  width?: string;
  height?: string;
  noPadding?: boolean;
  noTextTransform?: boolean;
  underline?: boolean;
  customFont?: FontFamilies | null;

  "data-element-id"?: string | null;
  "data-description"?: string;
}

const Button = ({
  type = "primary",
  shape = "square",
  size = "md",
  children,
  disabled = false,
  active = false,
  startIcon,
  endIcon,
  onClick,
  fullWidth = false,
  buttonType,
  isLoading = false,
  width = "unset",
  height = "3.5rem",
  noPadding = false,
  customFont = null,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  noTextTransform = false,
  underline = false,
}: Props): JSX.Element => {
  const classNames = clsx(
    type === "primary" && "button-primary",
    type === "secondary" && "button-secondary",
    type === "tertiary" && "button-tertiary",
    type === "primary-dark" && "button-primary-dark",
    type === "primary-light" && "button-primary-light",
    type === "transparent" && "button-transparent",
    type === "tertiary-bordered" && "button-tertiary-bordered",
    size === "sm" && "button-size-small",
    size === "xs" && "button-size-xs",
    isLoading && "button-loading",
    active && type === "primary-light" && "button-active",
    (disabled || isLoading) && "button-disabled",
    fullWidth && "button-full-width",
    shape === "rounded" && "button-rounded",
    noPadding && "button-no-padding",
    underline && "underline"
  );

  return (
    <StyledButton
      width={width}
      height={height}
      noTextTransform={noTextTransform}
      className={classNames}
      disabled={disabled || isLoading}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        disabled || onClick?.(e);
      }}
      type={buttonType}
      customFont={customFont}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
    >
      {!isLoading && startIcon && (
        <span className="button-icon button-start-icon">{startIcon}</span>
      )}
      {isLoading ? <LoaderIcon /> : children}
      {!isLoading && endIcon && <span className="button-icon button-end-icon">{endIcon}</span>}
    </StyledButton>
  );
};

const StyledButton = styled.button<{
  width: string;
  height: string;
  noTextTransform: boolean;
  customFont: FontFamilies | null;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 1.5rem;
  width: ${(props) => props.width};
  font-size: 0.8125rem;
  height: ${(props) => props.height};
  ${(props) => !props.noTextTransform && "text-transform: uppercase;"}

  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid transparent;
  font-family: ${(props) =>
    props.customFont
      ? props.customFont + " " + props.theme.fonts.customBold
      : props.theme.fonts.fontBold};

  &.underline {
    text-decoration: underline;
  }

  &.button-full-width {
    width: 100%;
  }

  &.button-size-small {
    height: 2.938rem;
  }

  &.button-size-xs {
    height: 2rem;
  }

  &.button-no-padding {
    padding: 0;
  }

  // WITH ICON
  .button-icon {
    height: 0.875rem;
    width: 0.875rem;
    line-height: 0;

    svg {
      height: 0.875rem;
      width: 0.875rem;
      fill: currentColor;
    }

    &.button-start-icon {
      margin-right: 1rem;
    }
    &.button-end-icon {
      margin-left: 1rem;
    }
  }

  //TRANSPARENT
  &.button-transparent {
    color: ${(props) => props.theme.palette.primary};

    svg {
      fill: ${(props) => props.theme.palette.primary};
    }
  }

  // PRIMARY
  &.button-primary {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};

    &:not(.button-disabled) {
      &:hover {
        background-color: ${(props) => props.theme.palette.secondary};
      }
    }

    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  // SECONDARY
  &.button-secondary {
    background-color: ${(props) => props.theme.palette.gray.light};
    color: ${(props) => props.theme.palette.primary};

    &:not(.button-disabled) {
      &:hover {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.white};
      }
    }
  }

  // TERTIARY
  &.button-tertiary {
    color: ${(props) => props.theme.palette.primary};

    &.button-disabled {
      background-color: ${(props) => props.theme.palette.white};
      color: ${(props) => props.theme.palette.gray.dark};
    }
  }

  // TERTIARY-BORDERED
  &.button-tertiary-bordered {
    color: ${(props) => props.theme.palette.primary};
    border-radius: 0.25rem;
    border: 0.094rem solid ${(props) => props.theme.palette.primary};

    &.button-disabled {
      background-color: ${(props) => props.theme.palette.white};
      color: ${(props) => props.theme.palette.gray.dark};
    }
  }

  //OUTLINE
  &.button-primary-dark {
    background-color: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.primary};

    &:not(.button-disabled) {
      &:hover {
        color: ${(props) => props.theme.palette.white};
        border-color: ${(props) => props.theme.palette.white};
        background-color: transparent;
      }
    }
  }

  &.button-primary-light {
    color: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.white};
    background-color: transparent;

    &.button-active {
      background-color: ${(props) => props.theme.palette.white};
      color: ${(props) => props.theme.palette.primary};
    }
  }

  &.button-rounded {
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  // DISABLED
  &.button-disabled {
    background-color: ${(props) => props.theme.palette.gray.medium};
    color: ${(props) => props.theme.palette.gray.dark};
    cursor: context-menu;
    &.button-loading {
      background-color: ${(props) => props.theme.palette.gray.dark};
      svg {
        height: 2.25rem;
      }
    }
  }
`;

export default Button;
