import { GenericCustomObject } from "../interfaces/mainInterfaces";
import { OrderItemExtendAttribute } from "../store/cart/cartInterfaces";
import {
  CarnetMultidoor,
  CarnetOrderApplicationSequenceMap,
  CheckCarnetOutcome,
  CheckCarnetOutcomeOrderItem,
} from "../store/checkout/checkoutInterfaces";
import { formatDate } from "./dateUtils";

export const carnetErrorCodes: GenericCustomObject = {
  CARNET_NOT_ACTIVATED: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_NOT_ACTIVATED", // Carnet functionality not activated
  CARNET_NOT_FOUND: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_NOT_FOUND", // Carnet not found
  CARNET_NOT_LINKED: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_NOT_LINKED", // Carnet not linked to customer
  CARNET_NOT_INVOICED: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_NOT_INVOICED", // Carnet created but not invoiced
  CARNET_NOT_VALID: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_NOT_VALID", // Campaign not valid
  DATE_NOT_VALID: "ORDER_CONFIRMATION_CARNET_ERROR_DATE_NOT_VALID", // Date not valid
  CARNET_EXPIRED: "ORDER_CONFIRMATION_CARNET_ERROR_CARNET_EXPIRED", // Carnet expired
  LENS_NOT_IN_SCOPE: "ORDER_CONFIRMATION_CARNET_ERROR_LENS_NOT_IN_SCOPE", // Lens not in scope
  NO_RESIDUAL: "ORDER_CONFIRMATION_CARNET_ERROR_NO_RESIDUAL", // Residual value not enough
  CUSTOMER_NOT_OPEN: "ORDER_CONFIRMATION_CARNET_ERROR_GENERIC", // Generic error
  NO_RXO_PLTYP: "ORDER_CONFIRMATION_CARNET_ERROR_GENERIC", // Generic error
  NO_LENSE_MVKE: "ORDER_CONFIRMATION_CARNET_ERROR_GENERIC", // Generic error
  NO_LENSE_ZOTC: "ORDER_CONFIRMATION_CARNET_ERROR_GENERIC", // Generic error
};

export const getCarnetErrorLabel = (code?: string): string => {
  if (code && code in carnetErrorCodes) return carnetErrorCodes[code];
  else return "ORDER_CONFIRMATION_CARNET_ERROR_GENERIC"; // Generic error
};

/**
 * Uses a map {carnetCode: last orderApplicationSequence value used} to:
 *  - figure out the next orderApplicationSequence value for a given carnet (they are counted indipendently for each carnetCode)
 *  - update the map, either by ++ the existing value, or by creating a new entry for the carnetCode
 *
 * @param {string} carnetCode
 * @param {CarnetOrderApplicationSequenceMap} map
 * @return {*}  {{ order: number; map: CarnetOrderApplicationSequenceMap }}
 */
export const getCarnetOrderApplicationSequence = (
  carnetCode: string,
  map: CarnetOrderApplicationSequenceMap
): { order: number; map: CarnetOrderApplicationSequenceMap } => {
  const orderSequence = map[carnetCode];

  if (orderSequence)
    return {
      order: orderSequence + 1,
      map: { ...map, [carnetCode]: orderSequence + 1 },
    };
  else
    return {
      order: 1,
      map: { ...map, [carnetCode]: 1 },
    };
};

/**
 * Returns updated list of applied carnets organized by doors by adding the given newCarnet
 * It also updates the helper property keeping track of how many times a certain carnetCode has been applied
 * to allow the server to figure out which was the last carnet added chronologically (through getCarnetOrderApplicationSequence())
 *
 * @param {CarnetMultidoor[]} currentCarnetMultidoor
 * @param {CarnetMultidoor} newCarnet
 * @param {string} currency
 * @param {string} storeId
 * @param {string} locale
 * @return {*}  {CarnetMultidoor[]}
 */
export const updateCarnetMultidoor = (
  currentCarnetMultidoor: CarnetMultidoor[],
  newCarnet: CarnetMultidoor,
  currency: string,
  storeId: string,
  locale: string
): CarnetMultidoor[] => {
  // create new CarnetOrderItem by adding currency and orderCreationDate
  const newCarnetOrderItem = {
    ...newCarnet.orderItems?.[0],
    currency,
    orderCreationDate: formatDate(new Date(), "yyyy-MM-dd", locale),
    carnetOrderApplicationSequence: 1,
  };

  // look for doors w/ same customerIdentifier
  const carnetDoorToModify = currentCarnetMultidoor.find((_) => _.doorId === newCarnet.doorId);

  //////// if found
  if (carnetDoorToModify) {
    // remove older carnets applied to same order item (should not happen, it's just in case)
    const carnetOrderItems = carnetDoorToModify.orderItems.filter(
      (_) => _.orderItemIdentifier !== newCarnetOrderItem.orderItemIdentifier
    );

    // get the carnet order
    const carnetOrder = getCarnetOrderApplicationSequence(
      newCarnetOrderItem.carnetCode,
      carnetDoorToModify.orderAppSeqMap ?? {} // fallback should not actually happen, but the interface is reused where this field isn't needed
    );

    // add new carnet
    const updatedCarnetOrderItems = [
      ...carnetOrderItems,
      {
        ...newCarnetOrderItem,
        carnetOrderApplicationSequence: carnetOrder.order,
      },
    ];

    carnetDoorToModify.orderItems = updatedCarnetOrderItems;
    carnetDoorToModify.orderAppSeqMap = carnetOrder.map; // update map with new carnet
  }
  //////// otherwise
  else {
    // create new door
    const newCarnetDoor = {
      customerIdentifier: newCarnet.doorId,
      doorId: newCarnet.doorId,
      storeIdentifier: storeId,
      orderAppSeqMap: {
        [newCarnetOrderItem.carnetCode]: 1,
      },
      orderItems: [{ ...newCarnetOrderItem, carnetOrderApplicationSequence: 1 }],
    };

    // push new carnet
    currentCarnetMultidoor.push(newCarnetDoor);
  }

  return currentCarnetMultidoor;
};

/**
 * Returns service request body based on the last carnet added, following the rules:
 * - only carnets applied to the same door (customerIdentifier) are relevant
 * - only carnets with the same carnetCode are relevant
 *
 * @param {CarnetMultidoor[]} carnetMultidoor
 * @param {string} customerIdentifier
 * @param {string} carnetCode
 * @return {*}  {CarnetMultidoor}
 */
export const getAddCarnetRequestBody = (
  carnetMultidoor: CarnetMultidoor[],
  doorId: string,
  carnetCode: string
): CarnetMultidoor => {
  const filteredDoor = carnetMultidoor.filter((_) => _.doorId === doorId)?.[0];

  const filteredOrderItems = filteredDoor?.orderItems.filter((_) => _.carnetCode === carnetCode);

  return {
    doorId: filteredDoor.doorId,
    orderItems: filteredOrderItems,
  };
};

/**
 *  Returns updated carnet outcome by adding/replacing the current order items' info
 *
 * @param {CheckCarnetOutcome[]} currentCarnetOutcome
 * @param {*} newOutcome
 * @return {*}  {CheckCarnetOutcome[]}
 */
export const updateCarnetOutcome = (
  currentCarnetOutcome: CheckCarnetOutcome[],
  newOutcome: any,
  orderItemIdentifier: string,
  carnetCode: string
): { newCarnetOutcome: CheckCarnetOutcome[]; outcomeStatus: "success" | "error" } => {
  const orderItemResult = newOutcome.filter(
    (_: any) => _.orderItemIdentifier === orderItemIdentifier
  )?.[0];

  const orderItemToModify = currentCarnetOutcome.find(
    (_) => _.orderItemIdentifier === orderItemIdentifier
  );

  const carnetOutcome = mapCarnetOutcome(orderItemResult, carnetCode);
  const outcomeStatus = carnetOutcome?.sapErrorCode ? "error" : "success";
  const outcomeText =
    outcomeStatus === "success"
      ? "ORDER_CONFIRMATION_CARNET_SUCCESS" // "Carnet code applied"
      : getCarnetErrorLabel(carnetOutcome?.sapErrorCode);

  if (orderItemToModify) {
    orderItemToModify.checkCarnetOutcome = carnetOutcome;
    orderItemToModify.outcomeStatus = outcomeStatus;
    orderItemToModify.outcomeText = outcomeText;
  } else {
    currentCarnetOutcome.push({
      orderItemIdentifier: orderItemResult?.orderItemIdentifier,
      checkCarnetOutcome: carnetOutcome,
      outcomeStatus: outcomeStatus,
      outcomeText: outcomeText,
    });
  }
  return { newCarnetOutcome: currentCarnetOutcome, outcomeStatus };
};

/**
 * Maps carnet outcome order item, normalizing the numbers returned (eg. "30.00-")
 *
 * @param {*} payload
 * @return {*}  {CheckCarnetOutcomeOrderItem}
 */
export const mapCarnetOutcome = (payload: any, carnetCode: string): CheckCarnetOutcomeOrderItem => {
  const normalizeNumber = (price?: string): number => {
    return price ? Number(price.replace(/-/g, "")) : 0;
  };

  return {
    carnetCode: carnetCode,
    carnetNumber: payload?.checkCarnetOutcome?.carnetNumber,
    carnetDescription: payload?.checkCarnetOutcome?.carnetDescription,
    carnetTotalValue: normalizeNumber(payload?.checkCarnetOutcome?.carnetTotalValue),
    carnetResidualValue: normalizeNumber(payload?.checkCarnetOutcome?.carnetResidualValue),
    lensPrice: normalizeNumber(payload?.checkCarnetOutcome?.lensPrice),
    lensDiscountPercentage: normalizeNumber(payload?.checkCarnetOutcome?.lensDiscountPercentage),
    lensDiscountValue: normalizeNumber(payload?.checkCarnetOutcome?.lensDiscountValue),
    lensDiscountedPrice: normalizeNumber(payload?.checkCarnetOutcome?.lensDiscountedPrice),
    sapErrorCode: payload?.checkCarnetOutcome?.sapErrorCode,
    sapErrorMessage: payload?.checkCarnetOutcome?.sapErrorMessage,
    currency: payload?.checkCarnetOutcome?.currency, // TODO: we don't actually receive this field from the API at this point
  };
};

/**
 * Get extended attributes related to carnets to add to SubmitOrderPayload
 *
 * @param {CheckCarnetOutcomeOrderItem} [carnet]
 * @return {*}  {OrderItemExtendAttribute[]}
 */
export const getCarnetExtendedAttributes = (
  carnet?: CheckCarnetOutcomeOrderItem
): OrderItemExtendAttribute[] => {
  if (!carnet) return [];

  const CARNET_EXTENDED_ATTRS: { fieldName: string; attributeName: string }[] = [
    { fieldName: "carnetCode", attributeName: "RXcarnetCode" },
    { fieldName: "carnetNumber", attributeName: "RXcarnetNumber" },
    { fieldName: "carnetDescription", attributeName: "RXcarnetDescription" },
    { fieldName: "carnetTotalValue", attributeName: "RXcarnetTotalValue" },
    { fieldName: "carnetResidualValue", attributeName: "RXcarnetResidualValue" },
    { fieldName: "lensPrice", attributeName: "RXcarnetLensPrice" },
    { fieldName: "lensDiscountPercentage", attributeName: "RXcarnetLensDiscountPercentage" },
    { fieldName: "lensDiscountedPrice", attributeName: "RXcarnetLensDiscountedPrice" },
    { fieldName: "lensDiscountValue", attributeName: "RXcarnetLensDiscountValue" },
  ];

  const extendedAttr: OrderItemExtendAttribute[] = [];

  CARNET_EXTENDED_ATTRS.forEach((attr) => {
    const attributeValue = carnet[attr.fieldName as keyof CheckCarnetOutcomeOrderItem];

    if (attributeValue) {
      extendedAttr.push({
        attributeName: attr.attributeName,
        attributeValue: String(attributeValue),
      });
    }
  });

  return extendedAttr;
};
