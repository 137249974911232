import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import useTranslation from "../../../../hooks/useTranslation";
import clsx from "clsx";
import { selectSuggestionsMax3, saveSuggestions } from "../../../../store/search/searchSlice";
import { getPagePath } from "../../../../routing/routesUtils";
import { getSuggestionString } from "../../../../utils/utils";
import { getSuggestionResults } from "../../../../utils/searchUtils";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-icon.svg";
import { ReactComponent as XIcon } from "../../../../assets/icons/x-icon.svg";
import useCanRender from "../../../../hooks/useCanRender";
import { Suggestion } from "../../../../store/search/searchInterfaces";

const SearchHome = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const canRender = useCanRender();

  const [searchInput, setSearchInput] = useState("InputNotSelected");

  const [currentText, setCurrentText] = useState<string>("");
  const [isSuggestionsVisibile, setIsSuggestionsVisibile] = useState<boolean>(false);
  const [timeoutSuggestion, setTimeoutSuggestion] = useState<NodeJS.Timeout>();
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

  const suggestions = useSelector(selectSuggestionsMax3);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (elementRef.current) {
        const target = (event.target as Node) || null;
        elementRef.current.contains(target) ? null : setIsSuggestionsVisibile(false);
        elementRef.current.contains(target)
          ? setSearchInput("InputSelected")
          : setSearchInput("InputNotSelected");
      }
      if (searchRef.current) {
        const target = (event.target as Node) || null;
        searchRef.current.contains(target)
          ? setSearchInput("InputSelected")
          : setSearchInput("InputNotSelected");
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);
    if (e.target.value.length >= 3) {
      if (timeoutSuggestion) {
        clearTimeout(timeoutSuggestion);
      }
      const newTimeoutSuggestion = setTimeout(() => {
        getSuggestionResults(e.target.value.toLowerCase().trim(), dispatch);
        setIsSuggestionsVisibile(true);
      }, 500);
      setTimeoutSuggestion(newTimeoutSuggestion);
    } else {
      setIsSuggestionsVisibile(false);
      dispatch(saveSuggestions([]));
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      const search =
        selectedSuggestionIndex > 0 && suggestions[selectedSuggestionIndex]
          ? suggestions[selectedSuggestionIndex]?.term
          : currentText;
      if (search.trim() !== "") {
        history.push(getPagePath(`/search-results/${encodeURIComponent(search.trim())}`));
        cleanInput();
      }
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
    if (suggestions?.length > 0) {
      let index = selectedSuggestionIndex;
      if (e.key === "ArrowUp") {
        if (selectedSuggestionIndex === 0) {
          index = suggestions.length - 1;
        } else {
          index -= 1;
        }
      } else if (e.key === "ArrowDown") {
        if (selectedSuggestionIndex === suggestions.length - 1) {
          index = 0;
        } else {
          index += 1;
        }
      }
      setSelectedSuggestionIndex(index);
    }
  };

  const cleanInput = () => {
    setCurrentText("");
    dispatch(saveSuggestions([]));
    setIsSuggestionsVisibile(false);
  };

  const handleOnClickSuggestion = (suggestion: string) => {
    history.push(getPagePath(`/search-results/${encodeURIComponent(suggestion.toLowerCase())}`));
    cleanInput();
  };

  const renderSuggestion = () => {
    if (suggestions?.length > 0 && canRender("CATALOGUE")) {
      return suggestions.map((suggestion: Suggestion, index: number) => {
        const autocomplete = getSuggestionString(suggestions, currentText.toLowerCase(), index);
        const selected = selectedSuggestionIndex === index;

        if (autocomplete)
          return (
            <div
              className={clsx("result-line", { selected })}
              key={index}
              onClick={() => handleOnClickSuggestion(suggestion.term)}
              onMouseEnter={() => !selected && setSelectedSuggestionIndex(index)}
            >
              <span>{currentText}</span>
              <SuggestValue>{autocomplete}</SuggestValue>
            </div>
          );
      });
    }
  };

  return (
    <Search>
      <SearchContent className={searchInput}>
        <InputContainer ref={searchRef}>
          <SearchInput
            value={currentText}
            currentText={currentText}
            placeholder={translateLabel("HOMEPAGE_SEARCH_SECONDARY")}
            onChange={handleChange}
            onKeyPress={handleKeypress}
            onKeyDown={handleKeyDown}
          />
          {isSuggestionsVisibile && (
            <Autocomplete>
              <SpaceSpan>{currentText}</SpaceSpan>
              {currentText.length > 2 &&
                suggestions &&
                suggestions[0] &&
                getSuggestionString(suggestions, currentText, 0)}
            </Autocomplete>
          )}
        </InputContainer>

        <IconContainer>
          <StyledButton
            onClick={(e) => (currentText.length > 0 ? cleanInput() : e.preventDefault())}
          >
            {currentText.length > 0 ? <XIcon /> : <SearchIcon />}
          </StyledButton>
        </IconContainer>
      </SearchContent>
      <SuggestionsContent ref={elementRef}>
        {suggestions.length > 0 && isSuggestionsVisibile && (
          <StyledDropdown>{renderSuggestion()}</StyledDropdown>
        )}
      </SuggestionsContent>
    </Search>
  );
};

const Search = styled(motion.div)`
  background: rgb(3, 20, 52);
  background: -moz-linear-gradient(
    153deg,
    rgba(3, 20, 52, 1) 20%,
    rgba(1, 27, 87, 1) 50%,
    rgba(6, 43, 120, 1) 100%
  );
  background: -webkit-linear-gradient(
    153deg,
    rgba(3, 20, 52, 1) 20%,
    rgba(1, 27, 87, 1) 50%,
    rgba(6, 43, 120, 1) 100%
  );
  background: linear-gradient(
    153deg,
    rgba(3, 20, 52, 1) 20%,
    rgba(1, 27, 87, 1) 50%,
    rgba(6, 43, 120, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#031434",endColorstr="#062b78",GradientType=1);
  height: 8.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const SearchContent = styled(motion.div)`
  padding: 1rem 7rem 1rem 8.75rem;
  height: 3.5rem;
  width: 50rem;
  /* box-sizing: border-box; */
  margin: 2.625rem 20rem 2.625rem 20rem;
  padding: 1rem;
  border-radius: 4px;
  display: flex;

  &.InputSelected {
    border: 1px solid ${(props) => props.theme.palette.white};
  }
  &.InputNotSelected {
    border: 1px solid ${(props) => props.theme.palette.textBlue};
  }
`;

const InputContainer = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const SearchInput = styled.input<any>`
  background-color: transparent;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-size: 1.125rem;
  font-family: ${(props) => props.theme.fonts.fontBold};
  color: ${(props) => props.theme.palette.white};
  z-index: 2;
  position: absolute;
  padding: 0px;

  &::placeholder {
    color: ${(props) => props.theme.palette.white};
    font-family: ${(props) => props.theme.fonts.fontBold};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
  height: 1.25rem;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.palette.white};
    width: 1.25rem;
  }
`;

const SpaceSpan = styled.span`
  user-select: none;
  color: transparent;
  display: inline-block;
  content: "";
`;

const Autocomplete = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fonts.fontRegular};
  font-size: 1.25rem;
  line-height: 1.13;
  position: relative;
  z-index: 1;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
`;
const SuggestionsContent = styled.div`
  position: relative;
  z-index: 102;
  width: 50rem;
  margin: -2rem 20rem 0 20rem;
`;

const StyledDropdown = styled.div`
  background-color: ${(props) => props.theme.palette.primary};
  position: relative;
  z-index: 102;
  opacity: 1;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
  transition: all 500ms ease-out;
  /* margin-left: 3.125rem; */
  text-transform: capitalize;

  &.animated {
    opacity: 1;
    transform: translateX(-50%) translateY(-36rem);
  }

  .result-line {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    font-size: 0.813;
    line-height: 2;
    font-family: ${(props) => props.theme.fonts.fontRegular};
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;

    &.selected {
      border-radius: 4px;
      background-color: ${(props) => props.theme.palette.secondary};
    }
  }
`;

const SuggestValue = styled.span`
  margin-top: 0.25rem;
  color: ${(props) => props.theme.palette.white};
  font-family: ${(props) => props.theme.fonts.fontLight};

  &:first-child {
    margin-top: unset;
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  svg {
    fill: ${(props) => props.theme.palette.white};
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export default SearchHome;
