import React from "react";
import styled from "styled-components/macro";

interface Props {
  text: string;
  onClick: (e: any) => void;
  icon: any;
  "data-element-id"?: string;
  "data-description"?: string;
  isInstagram?: boolean;
  disabled?: boolean;
}

const LinkButtonIcon = ({
  text,
  icon,
  onClick,
  "data-element-id": dataElementId,
  "data-description": dataDescrption,
  isInstagram = false,
  disabled,
}: Props): JSX.Element => {
  return (
    <StyledButton
      onClick={onClick}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescrption ? { "data-description": dataDescrption } : {})}
      className={isInstagram ? "see-on-instagram" : ""}
      disabled={disabled}
    >
      {icon} {text}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.fontBold};
  color: ${(props) => props.theme.palette.primary};

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.125rem;
    width: 1.125rem;
    margin-right: 1rem;
  }

  &.see-on-instagram {
  }
`;

export default LinkButtonIcon;
