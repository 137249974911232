import { isEqual, union, xorWith } from "lodash";
import {
  MenuFacet,
  menuStructure,
  MenuStructureKeys,
  MenuStructureKeysWithLenses,
} from "../components/layouts/header/navbar/categories-menu/category-item/menuStructure";
import { serviceMenu } from "../components/layouts/header/navbar/services-menu/serviceSections";
import {
  CategoriesDataHandler,
  CategoriesInfo,
} from "../components/pages/homepage/most-used/useCategoriesData";
import { navbarSections } from "../components/pages/my-account/account-navbar/navbarSections";
import { CategoryColumnInfo, MenuDivInfo } from "../hooks/useMenuColumnsInfo";
import { FacetView } from "../interfaces/facetInterfaces";
import { SecondLevelMenu, ThirdLevelMenuObj } from "../interfaces/menuInterfaces";
import {
  instanceOfMostUsedChipCatalogue,
  MostUsedCatalogueInfo,
  MostUsedCatalogueInfoFacets,
  MostUsedCategory,
  MostUsedChip,
  MostUsedChipCatalogue,
  MostUsedChipLocal,
  MostUsedOption,
} from "../interfaces/mostUsedInterfaces";
import { QueryParams } from "../store/search/searchInterfaces";
import { LensesColumn, LensLink } from "../store/store/storeInterfaces";
import { Door } from "../store/user/userInterfaces";
import { appendFiltersToURLSearchParams, getFiltersFromParams } from "./filterUtils";
import { ColBrandGroup, LENSES_COLUMN_BASE_URL } from "./menuUtils";

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////// MISC UTILS //////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const formatMostUsedIdentifier = (id?: string): string =>
  id ? id.toUpperCase().replace(/\s/g, "-").replace(/,/g, "") : "";

export const getCategoryFromCatalogueBookmark = (
  bookmark: MostUsedChipCatalogue
): MenuStructureKeysWithLenses =>
  bookmark.catalogueInfo.menuCategory?.toUpperCase() as MenuStructureKeysWithLenses;

//////////////////////////////////////////////////////////////////////////////////
////////////////////// LOCAL CHIP GENERATION FROM API RESULT /////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate local chips from the API's data by:
 *    - adding urls + labels for Services&MyAccount bookmarks
 *    - reverse-engineering the labels for Catalogue bookmarks from the third-level menu
 *
 * @param {MostUsedChip[]} mostUsedChips
 * @param {CategoriesDataHandler} categoriesData
 * @param {MostUsedChipLocal[]} availableServicesMyAccountChips
 * @param {ThirdLevelMenuObj} thirdLevelMenu
 * @param {{
 *     [brand: string]: string;
 *   }} brandLabels
 * @param {LensesColumn[]} lensesMenu
 * @return {*}  {MostUsedChipLocal[]}
 */
export const getSelectedChips = (
  mostUsedChips: MostUsedChip[],
  categoriesData: CategoriesDataHandler,
  availableServicesMyAccountChips: MostUsedChipLocal[],
  thirdLevelMenu: ThirdLevelMenuObj,
  brandLabels: {
    [brand: string]: string;
  },
  lensesMenu: LensesColumn[]
): MostUsedChipLocal[] => {
  const newSelectedChips: MostUsedChipLocal[] = availableServicesMyAccountChips.filter(
    (_) => _.showAsDefault
  );

  mostUsedChips.forEach((bookmark) => {
    let matchedChip: MostUsedChipLocal | undefined;

    switch (bookmark.type) {
      case "Catalogue":
        matchedChip = getCatalogueChipFromMenu(
          bookmark,
          categoriesData.categoriesInfo,
          thirdLevelMenu,
          brandLabels,
          lensesMenu
        );

        break;

      case "Services&MyAccount":
        matchedChip = getServicesMyAccountChipFromId(
          bookmark.name,
          availableServicesMyAccountChips
        );
        break;
    }
    if (matchedChip) newSelectedChips.push(matchedChip);
  });

  return newSelectedChips;
};

/**
 * Generate local chips from the API's data for Catalogue by:
 *    - reverse-engineering the labels from the third-level/lenses menu
 *    - skipping chips whose category or facets are no longer present in the current menu
 *      (to ensure the user won't fall in a PLP with "No results found")
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @param {CategoriesInfo[]} categoriesInfo
 * @param {ThirdLevelMenuObj} thirdLevelMenu
 * @param {{
 *     [brand: string]: string;
 *   }} brandLabels
 * @param {LensesColumn[]} lensesMenu
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getCatalogueChipFromMenu = (
  bookmark: MostUsedChipCatalogue,
  categoriesInfo: CategoriesInfo[],
  thirdLevelMenu: ThirdLevelMenuObj,
  brandLabels: {
    [brand: string]: string;
  },
  lensesMenu: LensesColumn[]
): MostUsedChipLocal | undefined => {
  const currentCategory = categoriesInfo.find(
    (_) => _.category === getCategoryFromCatalogueBookmark(bookmark)
  );

  if (!currentCategory || currentCategory.status === "ERROR") return;

  const { category, id: categoryID, label: categoryLabel } = currentCategory;
  const currentCategoryFacets = (categoryID && thirdLevelMenu[categoryID]) || [];

  const label =
    category === "LENSES"
      ? getMostUsedLabelFromLensesMenu(categoryLabel, bookmark.catalogueInfo.facets, lensesMenu)
      : getMostUsedLabelFromCategoryMenu(
          categoryLabel,
          bookmark.catalogueInfo.facets,
          currentCategoryFacets,
          brandLabels
        );

  if (!label) return; // something was missing when trying to build the chip; skip it
  return {
    ...bookmark,
    label: formatMostUsedIdentifier(label),
  };
};

/**
 * Generate local chips from the API's data for Services&MyAccount by:
 *    - matching them to the chips available for selection, to ensure only those for which
 *      the corresponding privilege is active are displayed
 *    - retrieving the labels and urls from the menu structure
 *      (this is done indirectly, through the chips available for selection, which are built from the menu structure)
 *
 * @param {string} id
 * @param {MostUsedChipLocal[]} availableChips
 * @return {*}  {(MostUsedChipLocal | undefined)}
 */
export const getServicesMyAccountChipFromId = (
  id: string,
  availableChips: MostUsedChipLocal[]
): MostUsedChipLocal | undefined => {
  const matchFromAvailable = availableChips.find((_) => _.name === id);
  if (matchFromAvailable)
    return {
      name: id,
      type: "Services&MyAccount",
      label: matchFromAvailable.label,
      url: matchFromAvailable.url,
      showAsDefault: matchFromAvailable.showAsDefault,
    };
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////// CATALOGUE LABELS GENERATION //////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate labels for Catalogue Chips of all categories except "Lenses"
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @param {FacetView[]} currentCategoryFacets
 * @param {{
 *     [brand: string]: string;
 *   }} brandLabels
 * @return {*}  {(string | undefined)}
 */
const getMostUsedLabelFromCategoryMenu = (
  categoryLabel: string,
  facets: MostUsedCatalogueInfoFacets[],
  currentCategoryFacets: FacetView[],
  brandLabels: {
    [brand: string]: string;
  }
): string | undefined => {
  const labels: string[] = [];
  try {
    facets?.forEach((facet) => {
      const menuFacet = currentCategoryFacets?.find(
        (_) => _.extendedData?.propertyvalue === facet.facetName
      );
      const entry = menuFacet?.entry?.find((_) => _.identifier === facet.facetValue);

      if (menuFacet && entry) {
        if (facet.facetName === "manufacturer.raw") labels.push(brandLabels[facet.facetValue]);
        else labels.push(facet.facetValue === "TRUE" ? menuFacet.name : entry.label);
      } else {
        // if facet was not found we should interrupt and skip the chip
        throw new Error("Facet not found");
      }
    });
  } catch (error) {
    return;
  }

  return categoryLabel + (labels.length > 0 ? "-" + labels.join("-") : "");
};

/**
 * Generate labels for Catalogue Chips of "Lenses" category
 *
 * @param {MostUsedChipCatalogue} bookmark
 * @param {LensesColumn[]} lensesMenu
 * @return {*}  {(string | undefined)}
 */
const getMostUsedLabelFromLensesMenu = (
  categoryLabel: string,
  facets: MostUsedCatalogueInfoFacets[],
  lensesMenu: LensesColumn[]
): string | undefined => {
  const labels: string[] = []; // should actually be just one

  try {
    facets?.forEach((facet) => {
      const links = lensesMenu?.map((_) => _.links).flat();
      const currentLink = links.find((_) => _.id === facet.facetName);
      if (currentLink) labels.push(currentLink?.title);
      else throw new Error("Facet not found"); // if facet was not found we should interrupt and skip the chip
    });
  } catch (error) {
    return;
  }

  return categoryLabel + (labels.length > 0 ? "-" + labels.join("-") : "");
};

//////////////////////////////////////////////////////////////////////////////////
////////////////////// SERVICES&MYACCOUNT OPTIONS GENERATION /////////////////////
//////////////////////////////////////////////////////////////////////////////////

/**
 * Generate all chips for Services & MyAccount available for selection, by:
 *    - retrieving all relevant links from the serviceMenu
 *    - retrieving all relevant links form the navbarSections
 *    - filtering those whose associated privilege is active
 *    - mapping them into a MostUsedChipLocal object
 *
 * @param {((
 *     privilege?: string | null | undefined,
 *     door?: Door | null | undefined,
 *     onlySubuser?: boolean | undefined
 *   ) => boolean)} canRender
 * @return {*}  {MostUsedChipLocal[]}
 */
export const generateServicesAndMyAccountChips = (
  canRender: (
    privilege?: string | null | undefined,
    door?: Door | null | undefined,
    onlySubuser?: boolean | undefined
  ) => boolean,
  isSubuser?: boolean | null | string,
  mainDoor?: Door | null
): MostUsedChipLocal[] => {
  const serviceMenuChips = serviceMenu.map((x) => x.links).reduce((x, y) => x.concat(y));
  const navbarSectionsChips = navbarSections.map((x) => x.links).reduce((x, y) => x.concat(y));
  const allChips = [...serviceMenuChips, ...navbarSectionsChips];

  return allChips
    .filter(
      (x) =>
        x.showInMostUsed && (isSubuser ? canRender(x.privilege, mainDoor) : canRender(x.privilege))
    )
    .map((x) => {
      return {
        name: x.identifier,
        type: "Services&MyAccount",
        label: x.title,
        url: x.url,
        showAsDefault: x.showAsDefaultInMostUsed,
      };
    });
};

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////// CATALOGUE OPTIONS GENERATION /////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/////////////////// helper functions to map the two different types of catalogue columns (generic categories and lenses)
/////////////////// into a common type that can be managed inside ChipsCatalogueTooltip

/**
 * CATEGORIES CHIPS
 * Generate object used to render checkboxes and radiobuttons in ChipsCatalogueTooltip
 * from the data provided by the useMenuColumnsInfo hook
 *
 * @param {MenuDivInfo[]} menuContentInfo
 * @return {*}  {MostUsedCategory[]}
 */
export const getMostUsedCategoryFromMenuDivInfo = (
  menuContentInfo: MenuDivInfo[]
): MostUsedCategory[] => {
  return menuContentInfo.map((column) => {
    const content: (ColBrandGroup | CategoryColumnInfo)[] = column.isBrandColumn
      ? column.brandColumns
      : column.categoryColumns;

    const columnFacet = column.columnStructure?.facet?.facetName;

    return {
      title: column.title,
      key: column.title,
      isRadio: column.isBrandColumn,
      columnFacet,
      options: content.map((_) =>
        getMostUsedOptionFromGenericColumnInfo(_, column.title, columnFacet)
      ),
    };
  });
};

const getMostUsedOptionFromGenericColumnInfo = (
  col: ColBrandGroup | CategoryColumnInfo,
  columnTitle: string,
  columnFacet: string | undefined
): MostUsedOption => {
  return {
    label: col.label ?? "",
    url: col.link,
    columnTitle,
    columnFacet,
    facet: { facetName: col.facetName ?? "", facetValue: col.facetValue ?? "" },
  };
};

/**
 * LENSES CHIPS
 * Generate object used to render checkboxes and radiobuttons in ChipsCatalogueTooltip
 * from the data provided by the selectLensesMenu selector
 *
 * @param {LensesColumn[]} lensesColumns
 * @return {*}  {MostUsedCategory[]}
 */
export const getMostUsedCategoryFromLensesColumn = (
  lensesColumns: LensesColumn[]
): MostUsedCategory[] => {
  return lensesColumns.map((column) => {
    return {
      title: column.collectionTitle,
      key: column.collectionTitle,
      isRadio: true,
      columnFacet: undefined,
      options: column.links.map((_) => getMostUsedOptionFromLensesLink(_, column.collectionTitle)),
    };
  });
};

const getMostUsedOptionFromLensesLink = (
  lensLink: LensLink,
  columnTitle: string
): MostUsedOption => {
  return {
    label: lensLink.title,
    url: lensLink.url,
    columnTitle,
    columnFacet: undefined,
    facet: { facetName: lensLink.id, facetValue: lensLink.title },
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// CATALOGUE CHIP GENERATION ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

/////////////////// helper functions to create the Most Used chips to be saved from the filters selected

/**
 * Generate new Catalogue Chip based on the current selection of facets in ChipsCatalogueTooltip
 *
 * @param {SecondLevelMenu} categoryMenu
 * @param {MostUsedOption[]} selectedFacets
 * @param {MenuDivInfo[]} menuContentInfo
 * @param {boolean} isLensesCategory
 * @return {*}  {MostUsedChipCatalogueLocal}
 */
export const createMostUsedChipFromSelection = (
  categoryMenu: SecondLevelMenu,
  selectedFacets: MostUsedOption[],
  menuContentInfo: MenuDivInfo[],
  isLensesCategory: boolean
): MostUsedChip => {
  const sortedFacets = selectedFacets.sort((a) => (a.columnTitle === "MENU_BRAND" ? 1 : -1));
  const facets = sortedFacets.map((_) => _.facet);

  const catalogueInfo: MostUsedCatalogueInfo = {
    menuCategory: categoryMenu.identifier.toUpperCase() as MenuStructureKeys,
    facets,
  };

  const url = getMostUsedCategoryUrl(
    selectedFacets,
    menuContentInfo?.[0]?.baseUrl,
    isLensesCategory
  );

  const newChip: MostUsedChip = {
    name: getMostUsedCatalogueIdentifier(catalogueInfo, isLensesCategory),
    type: "Catalogue",
    url,
    catalogueInfo,
  };

  return newChip;
};

/**
 * Compute identifier for the chip, with the following structure:
 * [category's identifier]-[facet1]-[facet2]-...
 *
 * eg: SUNGLASSES-BESTSELLER-WOMAN-RAYBAN
 *
 * Usual rules re: whether displaying facet name or facet value apply
 * (facetName for facets w/ "TRUE" or "FALSE" values, facetValue otherwise)
 *
 * @param {MostUsedCatalogueInfo} catalogueInfo
 * @param {boolean} isLensesCategory
 * @return {*}  {string}
 */
export const getMostUsedCatalogueIdentifier = (
  catalogueInfo: MostUsedCatalogueInfo,
  isLensesCategory: boolean
): string => {
  if (isLensesCategory)
    return formatMostUsedIdentifier(
      catalogueInfo.menuCategory + "-" + catalogueInfo?.facets?.[0]?.facetValue
    );

  const potentialMatches: MenuFacet[] = [];
  menuStructure[catalogueInfo.menuCategory]?.forEach((column) => {
    if (column.content.length) potentialMatches.push(...column.content);
    if (column.facet) potentialMatches.push(column.facet);
  });

  const facets = catalogueInfo.facets.map((facet) => {
    const facetMatch = potentialMatches.find((_) => _.facetName === facet.facetName);

    if (facetMatch?.facetEntry) return facet.facetValue;
    if (facetMatch?.value) return facet.facetName;
    return facet.facetValue;
  });

  return formatMostUsedIdentifier(
    catalogueInfo.menuCategory + (facets.length ? "-" : "") + facets.join("-")
  );
};

/**
 * Compute url for the chip
 *
 * Note that not only the facets specified in the identifier/label will be applied,
 * but also all facets that depend on the column (eg. CATEGORY_FILTER = SUNGLASSES)
 * and all facets that are tacitely added in the menu (eg. GENDER=UNISEX when selecting GENDER=WOMAN)
 *
 * @param {MostUsedOption[]} selectedFacets
 * @param {string} baseUrl
 * @param {boolean} isLensesCategory
 * @return {*}  {string}
 */
export const getMostUsedCategoryUrl = (
  selectedFacets: MostUsedOption[],
  baseUrl: string,
  isLensesCategory: boolean
): string => {
  if (isLensesCategory) return LENSES_COLUMN_BASE_URL + selectedFacets?.[0]?.url;

  const facetsUrls = selectedFacets.map((_) => _.url);
  const [page, params] = baseUrl?.split("?") ?? [facetsUrls?.[0]?.split("?")?.[0]];

  const filtersArray = getArrayOfFilters([...facetsUrls, params]);
  const filters = getUnionOfFilters(filtersArray);

  const urlSearchParams = appendFiltersToURLSearchParams(filters, undefined);
  const queryParams = decodeURIComponent(urlSearchParams.toString());

  return page + (queryParams.length ? "?" : "") + queryParams;
};

const getArrayOfFilters = (urls: string[]): QueryParams[] => {
  return urls.map((_) => {
    const param = getParamsFromUrls(_);
    return getFiltersFromParams(param);
  });
};

const getParamsFromUrls = (url: string) => {
  const stringParams = url?.split("?")?.[1] ?? url;
  return new URLSearchParams(stringParams) as URLSearchParams;
};

const getUnionOfFilters = (filtersArray: QueryParams[]) => {
  return filtersArray.reduce((previousValue: QueryParams, currentValue: QueryParams) => {
    const previousKeys = Object.keys(previousValue);
    const currentKeys = Object.keys(currentValue);
    const newKeys = [...previousKeys, ...currentKeys];

    const newQueryParams: QueryParams = {};

    newKeys.forEach((key) => {
      newQueryParams[key] = union(previousValue?.[key] ?? [], currentValue?.[key] ?? []);
    });

    return newQueryParams;
  }, {});
};

export const checkIfChipAlreadyExists = (
  newChip: MostUsedChip,
  selectedChips: MostUsedChipLocal[]
): boolean => {
  return selectedChips.some((_) => areCatalogueChipsTheSame(newChip, _));
};

const areCatalogueChipsTheSame = (newChip: MostUsedChip, oldChip: MostUsedChip) => {
  if (!instanceOfMostUsedChipCatalogue(newChip) || !instanceOfMostUsedChipCatalogue(oldChip))
    return false;

  if (newChip.catalogueInfo.menuCategory !== oldChip.catalogueInfo.menuCategory) return false;
  if (newChip.catalogueInfo.facets.length !== oldChip.catalogueInfo.facets.length) return false;

  return xorWith(newChip.catalogueInfo.facets, oldChip.catalogueInfo.facets, isEqual).length === 0;
};
