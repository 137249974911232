import { paletteLight } from "./palette";

export const breakpoints = {
  sm: 1024,
  md: 1440,
  minLaptop: 1279,
};

export default {
  palette: paletteLight,
  fonts: {
    fontLight: "Gilmer Light",
    fontRegular: "Gilmer",
    fontBold: "Gilmer Bold",
    fontMedium: "Gilmer Medium",
    fontHeavy: "Gilmer Heavy",
    fontOutline: "Gilmer Outline",
    customLight: "Light",
    customBold: "Bold",
    customMedium: "Medium",
    customRegular: "Regular",
  },
  baseFontSize: 16,
  // minWrapperWidth: "1366px",
  minWrapperWidth: "1024px",
  maxWrapperWidth: "1440px",
  maxWrapperWithMD: "1280px",

  // headerHeightPx: 85,
  // multidoorBannerHeightPx: 50,
  headerHeightRem: 4,
  multidoorBannerHeightRem: 2,
  smallScreenNavbarHeightRem: 3,

  tileHeight: "40.5rem",
  tileAFAHeight: "43rem",
  breakpoints: {
    sm: breakpoints.sm + "px",
    md: breakpoints.md + "px",
    minLaptop: breakpoints.minLaptop + "px",
  },
  queries: {
    sm: `(max-width: ${breakpoints.sm}px)`,
    md: `(max-width: ${breakpoints.md}px)`,
    minLaptop: `(max-width: ${breakpoints.minLaptop}px)`,
    portrait: "(orientation: portrait)",
  },
};
