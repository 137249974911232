import {
  OrderConfirmationHeaderData,
  OrderConfirmationLensData,
  OrderDetailsPrescriptionAdditionalParams,
  OrderHistoryDetailsEssilor,
  OrderTYPEssilorPayload,
} from "../store/checkout/orderConfirmationEssilorInterfaces";
import {
  ItemLeft,
  ItemRight,
  OrderPrescription,
} from "../store/order-history/orderHistoryInterface";
import { FocalTypePrescription } from "../store/rx/rxInterface";

export const mapOrderJsonLensDetails = (orderJSON: any): OrderConfirmationLensData => {
  const mappedData: OrderConfirmationLensData = {
    lensShape: {
      imageUri: orderJSON?.Shape?.Thumbnail,
      caliber: {
        OD: orderJSON?.Shape?.AboxR,
        OS: orderJSON?.Shape?.AboxL,
      },
      height: {
        OD: orderJSON?.Shape?.BboxR,
        OS: orderJSON?.Shape?.BboxL,
      },
      bridge: {
        OD: orderJSON?.Shape?.Dbox,
        OS: orderJSON?.Shape?.Dbox,
      },
      frameType: orderJSON?.Frame?.TypeName,
      shapeMethod: orderJSON?.Shape?.shapeOriginText,
      fileName: orderJSON?.Shape?.FilePath,
    },
    prescriptionDetails: getPrescriptionDetails(orderJSON),
    lensDetails: {
      OD: {
        lensName: orderJSON?.Patient?.RightEye?.Lens?.LensName,
        lensPrice: orderJSON?.Patient?.RightEye?.Lens?.PriceFormated,
        lensTreatment:
          orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map((_: any) => _.Value).join(",") ||
          "-",
        lensTreatmentPrice: orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map(
          (_: any) => _.PriceFormated
        ).join(","),
        precal: orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Precal
          ? "ORDER_CONFIRMATION_YES"
          : "ORDER_CONFIRMATION_NO",
        easyFit:
          orderJSON?.Patient?.RightEye?.EdgeFinish?.JobTypeCode === "EDG"
            ? "ORDER_CONFIRMATION_YES"
            : "ORDER_CONFIRMATION_NO",
        edgeType: orderJSON?.Shape?.FrameType?.EdgeType?.ResourceName,
        edgeFinish: orderJSON?.Patient?.RightEye?.EdgeFinish?.ResourceName,
        internalChamfer: orderJSON?.Patient?.RightEye?.Chamfer?.Back?.ResourceName,
        externalChamfer: orderJSON?.Patient?.RightEye?.Chamfer?.Front?.ResourceName,
      },
      OS: {
        lensName: orderJSON?.Patient?.LeftEye?.Lens?.LensName,
        lensPrice: orderJSON?.Patient?.LeftEye?.Lens?.PriceFormated,
        lensTreatment:
          orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map((_: any) => _.Value).join(",") ||
          "-",
        lensTreatmentPrice: orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map(
          (_: any) => _.PriceFormated
        ).join(","),
        precal: orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Precal
          ? "ORDER_CONFIRMATION_YES"
          : "ORDER_CONFIRMATION_NO",
        easyFit:
          orderJSON?.Patient?.LeftEye?.EdgeFinish?.JobTypeCode === "EDG"
            ? "ORDER_CONFIRMATION_YES"
            : "ORDER_CONFIRMATION_NO",
        edgeType: orderJSON?.Shape?.FrameType?.EdgeType?.ResourceName,
        edgeFinish: orderJSON?.Patient?.LeftEye?.EdgeFinish?.ResourceName,
        internalChamfer: orderJSON?.Patient?.LeftEye?.Chamfer?.Back?.ResourceName,
        externalChamfer: orderJSON?.Patient?.LeftEye?.Chamfer?.Front?.ResourceName,
      },
    },
    frameDetails: {
      frameImage: orderJSON?.Frame?.ImageBigName,
      sku: orderJSON?.Frame?.SKU,
      model: orderJSON?.Frame?.ModelName,
      color: orderJSON?.Frame?.ColorName,
      brand: orderJSON?.Frame?.BrandName,
      frameType: orderJSON?.Frame?.TypeName,
      caliber: orderJSON?.Frame?.SizeName,
    },
  };
  return mappedData;
};

export const mapOrderJsonHeaderDetails = (orderJSON: any): OrderConfirmationHeaderData => {
  const mappedData: OrderConfirmationHeaderData = {
    fields: {
      shippingAccount: [],
      clientName: (orderJSON?.Patient?.FirstName + " " + orderJSON?.Patient?.LastName).trim(),
      poNumber: orderJSON?.ExternalOrderID,
      orderRef: orderJSON?.OrderReference,
      comments: orderJSON?.OrderComments,
    },
    summary: {
      isMultidoor: false,
      totalNumber: orderJSON?.Frame?.ShippedData ? 2 : 1,
      totalPrice:
        +orderJSON?.Lens?.PriceFormated +
        +orderJSON?.LensDetail?.Treatment?.map((_: any) => _.PriceFormated).reduce(
          (previous: number, current: number) => (previous += current),
          0
        ) +
        +orderJSON?.LensDetail?.Tint?.PriceFormated +
        +orderJSON?.ShowProcess?.PriceFormated +
        +orderJSON?.JobTypePrice,
    },
  };
  return mappedData;
};

export const mapOrderJsonThankYouPayload = (orderJSON: any): OrderTYPEssilorPayload => {
  const mappedData: OrderTYPEssilorPayload = {
    size: orderJSON?.Frame?.SizeName,
    lensName: orderJSON?.Patient?.RightEye?.Lens?.LensName,
    lensBrand: orderJSON?.Patient?.RightEye?.Lens?.BrandName,
    frameName: orderJSON?.Frame?.ModelName,
    frameBrand: orderJSON?.Frame?.BrandName,
    lensData: {
      design: orderJSON?.Patient?.LeftEye?.Lens?.DesignNameValue,
      material: orderJSON?.Patient?.LeftEye?.Lens?.MaterialNameValue,
      massTint: orderJSON?.Patient?.LeftEye?.LensDetail.Tint.map((_: any) => _.Value).join(", "),
      treatments:
        orderJSON?.Patient?.LeftEye?.LensDetail.Treatment.map((_: any) => _.Value).join(", ") ||
        "-" ||
        "-",
      shapePreviewUrl: orderJSON.Shape.Thumbnail,
    },
    frameData: {
      kindOfFrame: "-",
      model: orderJSON.Frame.ModelName,
      color: orderJSON.Frame.ColorName,
      framePreviewUrl: orderJSON.Frame.ImageBigName,
    },
    orderRef: {
      orderReference: orderJSON.OrderReference,
      jobType: orderJSON.JobType.ResourceName,
    },
    orderJSON: orderJSON,
  };
  return mappedData;
};

export const getPDFTechnicalDetailsParams = (orderJSON: any): any => {
  return {
    creationDate: orderJSON?.CreationDate?.split(".")[0].replace(/{-,:}/g, "-"),
    orderId: orderJSON?.ReusedOrderIdBase64,
    jobTypeCode: orderJSON?.JobType?.Code,
    billingName: orderJSON?.BillingAccount?.FirstName,
    billingSurname: orderJSON?.BillingAccount?.LastName,
    billingAddress: orderJSON?.BillingAccount?.Address?.AddressName, //FULL ADDRESS HERE
    shippingName: orderJSON?.ShippingAccount?.FirstName,
    shippingSurname: orderJSON?.ShippingAccount?.LastName,
    shippingAddress: orderJSON?.ShippingAccount?.Address?.AddressName, //FULL ADDRESS HERE
    calculationImage: orderJSON?.Shape?.Thumbnail,
    sphereOD: orderJSON?.Patient?.RightEye?.Prescription?.Sphere,
    cylinderOD: orderJSON?.Patient?.RightEye?.Prescription?.Cylinder,
    axisOD: orderJSON?.Patient?.RightEye?.Prescription?.Axis,
    additionOD: orderJSON?.Patient?.RightEye?.Prescription?.Addition,
    prismDiopInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1,
    prismDirInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis1,
    prismDiopUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue2,
    prismDirUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis2,
    decentDiopInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationValue1,
    decentDirInOutOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis1,
    decentDiopUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationValue2,
    decentDirUpDownOD: orderJSON?.Patient?.RightEye?.Prescription?.DecentrationAxis2,
    pdOD: orderJSON?.Patient?.RightEye?.Measurements?.PD?.toString(),
    heightOD: (
      orderJSON?.Patient?.RightEye?.Measurements?.Height ||
      orderJSON?.Patient?.RightEye?.Measurements?.OC
    )?.toString(),
    vertexFittedOD: (orderJSON?.Patient?.RightEye?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "REVertex"
    ) as any)?.Value,
    faceformTiltOD: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "WrapAngle"
    ) as any)?.Value,
    pantoScopicOD: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "PantoAngle"
    ) as any)?.Value,
    sphereOS: orderJSON?.Patient?.LeftEye?.Prescription?.Sphere,
    cylinderOS: orderJSON?.Patient?.LeftEye?.Prescription?.Cylinder,
    axisOS: orderJSON?.Patient?.LeftEye?.Prescription?.Axis,
    additionOS: orderJSON?.Patient?.LeftEye?.Prescription?.Addition,
    prismDiopInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1,
    prismDirInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis1,
    prismDiopUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue2,
    prismDirUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis2,
    decentDiopInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationValue1,
    decentDirInOutOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationAxis1,
    decentDiopUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationValue2,
    decentDirUpDownOS: orderJSON?.Patient?.LeftEye?.Prescription?.DecentrationAxis2,
    pdOS: orderJSON?.Patient?.LeftEye?.Measurements?.PD?.toString(),
    heightOS: (
      orderJSON?.Patient?.LeftEye?.Measurements?.Height ||
      orderJSON?.Patient?.LeftEye?.Measurements?.OC
    )?.toString(),
    vertexFittedOS: (orderJSON?.Patient?.LeftEye?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "REVertex"
    ) as any)?.Value,
    faceformTiltOS: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "WrapAngle"
    ) as any)?.Value,
    pantoScopicOS: (orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "PantoAngle"
    ) as any)?.Value,
    eyecode: orderJSON?.Patient?.HasEyeCodeSelected ? "DIALOG_YES" : "DIALOG_NO",
    dominationEye: orderJSON?.Patient?.SpecialParameters?.find(
      (_: any) => _?.JavascriptID === "DominantEye"
    ), //FIX THIS
    lensIdOD: orderJSON?.Patient?.RightEye?.Lens?.LensId?.toString(),
    lensTypeOD: orderJSON?.Patient?.RightEye?.Lens?.DesignTypeNameValue,
    designOD: orderJSON?.Patient?.RightEye?.Lens?.DesignNameValue,
    materialOD: orderJSON?.Patient?.RightEye?.Lens?.MaterialNameValue,
    tintOD: orderJSON?.Patient?.RightEye?.LensDetail?.Tint?.map((_: any) => _?.Value)?.join(","),
    treatmentsOD: orderJSON?.Patient?.RightEye?.LensDetail?.Treatment?.map(
      (_: any) => _?.Value
    )?.join(","),
    lensIdOS: orderJSON?.Patient?.LeftEye?.Lens?.LensId?.toString(),
    lensTypeOS: orderJSON?.Patient?.LeftEye?.Lens?.DesignTypeNameValue,
    designOS: orderJSON?.Patient?.LeftEye?.Lens?.DesignNameValue,
    materialOS: orderJSON?.Patient?.LeftEye?.Lens?.MaterialNameValue,
    tintOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Tint?.map((_: any) => _?.Value)?.join(","),
    treatmentsOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Treatment?.map(
      (_: any) => _?.Value
    )?.join(","),
    minThicknessOD: orderJSON?.CalculationVisualization?.CalculationRight?.MinThickness,
    maxThicknessOD: orderJSON?.CalculationVisualization?.CalculationRight?.MaxThickness,
    thicknessCenterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CenterThickness,
    weightOD: orderJSON?.CalculationVisualization?.CalculationRight?.Weight,
    baseOD: orderJSON?.CalculationVisualization?.CalculationRight?.Base,
    minThicknessOS: orderJSON?.CalculationVisualization?.CalculationLeft?.MinThickness,
    maxThicknessOS: orderJSON?.CalculationVisualization?.CalculationLeft?.MaxThickness,
    thicknessCenterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CenterThickness,
    weightOS: orderJSON?.CalculationVisualization?.CalculationLeft?.Weight,
    baseOS: orderJSON?.CalculationVisualization?.CalculationLeft?.Base,
    calculationOD: orderJSON?.CalculationVisualization?.CalculationRight?.DiameterCalculationSource,
    minDiameterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CalculatedMinimumDiameter,
    maxDiameterOD: orderJSON?.CalculationVisualization?.CalculationRight?.CommercialDiameter,
    physicalDiameterOD: orderJSON?.Patient?.RightEye?.LensDetail?.Diameter?.PhysicalDiameter?.toString(),
    selectedDiameterOD: orderJSON?.Patient?.RightEye?.LensDetail?.Diameter?.DiameterNameValue?.toString(),
    calculationOS: orderJSON?.CalculationVisualization?.CalculationLeft?.DiameterCalculationSource,
    minDiameterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CalculatedMinimumDiameter,
    maxDiameterOS: orderJSON?.CalculationVisualization?.CalculationLeft?.CommercialDiameter,
    physicalDiameterOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Diameter?.PhysicalDiameter?.toString(),
    selectedDiameterOS: orderJSON?.Patient?.LeftEye?.LensDetail?.Diameter?.DiameterNameValue?.toString(),
    prysmOD: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1,
    precalOD: orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Precal
      ? "DIALOGS_YES"
      : "DIALOGS_NO",
    thicknessStringOD:
      orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessType?.Resource,
    thicknessNumberOD:
      orderJSON?.Patient?.RightEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness ||
      "-",
    frameCurveOD: orderJSON?.Frame?.FrameCurveRight?.toString(),
    prysmOS: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1,
    precalOS: orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Precal
      ? "DIALOGS_YES"
      : "DIALOGS_NO",
    thicknessStringOS:
      orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessType?.Resource,
    thicknessNumberOS:
      orderJSON?.Patient?.LeftEye?.Measurements?.Thickness?.ThicknessValue?.DefaultThickness,
    frameCurveOS: orderJSON?.Frame?.FrameCurveLeft?.toString(),
    OriginalShapeboxAOD: orderJSON?.Shape?.OriginalAboxR?.toString(),
    OriginalShapeboxBOD: orderJSON?.Shape?.OriginalBboxR?.toString(),
    OriginalShapeboxCOD: orderJSON?.Shape?.OriginalDbox?.toString(),
    OriginalShapeboxAOS: orderJSON?.Shape?.OriginalAboxL?.toString(),
    OriginalShapeboxBOS: orderJSON?.Shape?.OriginalBboxL?.toString(),
    OriginalShapeboxCOS: orderJSON?.Shape?.OriginalDbox?.toString(),
    ShapeDetailsboxAOD: orderJSON?.Shape?.AboxR?.toString(),
    ShapeDetailsboxBOD: orderJSON?.Shape?.BboxR?.toString(),
    ShapeDetailsboxCOD: orderJSON?.Shape?.Dbox?.toString(),
    ShapeDetailsboxAOS: orderJSON?.Shape?.AboxL?.toString(),
    ShapeDetailsboxBOS: orderJSON?.Shape?.BboxL?.toString(),
    ShapeDetailsboxCOS: orderJSON?.Shape?.Dbox?.toString(),
    frameType: orderJSON?.Shape?.FrameType?.ResourceName,
    shapeSelection: orderJSON?.Shape?.shapeOriginText,
    shapeQuality: orderJSON?.Shape?.Quality?.toString(),
    jobType: orderJSON?.JobType?.ResourceName.toString(),
    frameTypeOD: orderJSON?.Frame?.TypeName.toString(),
    edgeTypeOD: orderJSON?.Patient?.RightEye?.EdgeFinish.ResourceName,
    edgeFinishingOD: orderJSON?.Patient?.RightEye?.EdgeFinishValue?.ResourceName,
    frontChamferOD: orderJSON?.Patient?.RightEye?.Chamfer?.Front?.ResourceName,
    backChamferOD: orderJSON?.Patient?.RightEye?.Chamfer?.Back?.ResourceName,
    grooveDepthOD: orderJSON?.Patient?.Groove?.Depth,
    grooveWidthOD: orderJSON?.Patient?.Groove?.Width,
    polishOD: orderJSON?.Patient?.Edg,
    frameTypeOS: orderJSON?.Frame?.TypeName,
    edgeTypeOS: orderJSON?.Patient?.LeftEye?.EdgeFinish.ResourceName,
    edgeFinishingOS: orderJSON?.Patient?.LeftEye?.EdgeFinishValue?.ResourceName,
    frontChamferOS: orderJSON?.Patient?.LeftEye?.Chamfer?.Front?.ResourceName,
    backChamferOS: orderJSON?.Patient?.LeftEye?.Chamfer?.Back?.ResourceName,
    grooveDepthOS: orderJSON?.Patient?.Groove?.Depth,
    grooveWidthOS: orderJSON?.Patient?.Groove?.Width,
    polishOS: orderJSON?.Patient?.EdgePolish,
    //DesignNameValue
  };
};

////////// ORDER HISTORY - ORDER DETAILS

export const mapOrderDetailsJSON = (orderJSON: any): OrderHistoryDetailsEssilor => {
  return {
    prescriptionDetails: getPrescriptionDetails(orderJSON),
    prescriptionAdditionalParams: {
      eyecode:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "EyeCode")
          ?.Value || "-",
      cape:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "CAPE")
          ?.Value || "-",
      ercdRight:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "REERCD")
          ?.Value || "-",
      ercdLeft:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "LEERCD")
          ?.Value || "-",
      progressLength:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "PrgLength"
        )?.Value || "-",
      reInset:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "REInset")
          ?.Value || "-",
      leInset:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "LEInset")
          ?.Value || "-",
      wrapAngle:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "WrapAngle"
        )?.Value || "-",
      initials:
        orderJSON.Patient?.Right?.SpecialParameters?.find((_: any) => _?.JavascriptID === "Init")
          ?.Value || "-",
      // useInsets: orderJSON.Patient?.Right?.SpecialParameters?.find(
      //   (_: any) => _?.JavascriptID === "UseInsets"
      // )?.Value
      //   ? "ORDER_HISTORY_YES"
      //   : "ORDER_HISTORY_NO",
      hestCoeff:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "HECoeff" || _?.JavascriptID === "STCoeff"
        )?.Value || "-",
      pantoAngle:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "PantoAngle"
        )?.Value || "-",
      readingDistance:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "ReadingDistance "
        )?.Value || "-",
      dominantEye:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "DominantEye "
        )?.Value || "-",
      nvBehaviour:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "NearVisionBehaviour "
        )?.Value || "-",
      reVertex:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "REVertex  "
        )?.Value || "-",
      leVertex:
        orderJSON.Patient?.Right?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "LEVertex   "
        )?.Value || "-",
    },
    jobInfo: {
      customerReference: (
        (orderJSON?.Patient?.FirstName || "") +
        " " +
        (orderJSON?.Patient?.LastName || "")
      ).trim(),
      jobTypeCode: orderJSON.JobType.Code,
      jobTypeDescription: orderJSON.JobType.ResourceName,
      rxID: orderJSON.OrderIdBase64,
    },
    lensInfo: {
      lensID: orderJSON?.Patient?.RightEye?.Lens?.Code,
      lensDescription:
        orderJSON?.Patient?.RightEye?.Lens?.LensName ||
        "-" +
          " - " +
          orderJSON?.Patient?.RightEye?.Lens?.LensDetail?.Treatment?.map((_: any) => _.Value).join(
            ","
          ) ||
        "-",
      lensTint:
        orderJSON?.Patient?.RightEye?.Lens?.LensDetail?.Tint?.map((_: any) => _.Value).join(",") ||
        "-",
      brand: orderJSON?.Patient?.RightEye?.Lens?.BrandCode || "-",
      thumbnail: orderJSON?.Shape?.Thumbnail,
    },
  };
};

export const getPrescriptionDetails = (orderJSON: any): FocalTypePrescription[] => {
  return [
    {
      parameterName: "ESSILOR_PRESCRIPTION_SPHERE",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Sphere || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Sphere || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_CYLINDER",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Cylinder || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Cylinder || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_AXIS",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Axis || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Axis || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_ADDITION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.Addition || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.Addition || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue1 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue1 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRISM_DIRECTION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis1 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis1 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismValue2 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismValue2 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIRECTION_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Prescription?.PrismAxis2 || "",
      right: orderJSON?.Patient?.RightEye?.Prescription?.PrismAxis2 || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PD",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: orderJSON?.Patient?.LeftEye?.Measurements?.PD?.toString() || "",
      right: orderJSON?.Patient?.RightEye?.Measurements?.PD?.toString() || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_HEIGHT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        (
          orderJSON?.Patient?.LeftEye?.Measurements?.Height ||
          orderJSON?.Patient?.LeftEye?.Measurements?.OC
        )?.toString() || "",
      right:
        (
          orderJSON?.Patient?.RightEye?.Measurements?.Height ||
          orderJSON?.Patient?.RightEye?.Measurements?.OC
        )?.toString() || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_VERTEX_FITTED",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON.Patient?.LeftEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "LEVertex"
        )?.Value || "",
      right:
        orderJSON?.Patient?.RightEye?.SpecialParameters?.find(
          (_: any) => _?.JavascriptID === "REVertex"
        )?.Value || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_FACEFORM_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value || "",
      right:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "WrapAngle")
          ?.Value || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PANTO_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value || "",
      right:
        orderJSON?.Patient?.SpecialParameters?.find((_: any) => _?.JavascriptID === "PantoAngle")
          ?.Value || "",
    },
  ];
};

export const getEssilorPrescriptionValues = (
  prescription: OrderPrescription
): FocalTypePrescription[] => {
  const itemLeft: ItemLeft = prescription.itemLeft;
  const itemRight: ItemRight = prescription.itemRight;

  return [
    {
      parameterName: "ESSILOR_PRESCRIPTION_SPHERE",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.sphere || "",
      right: itemRight?.sphere || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_CYLINDER",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.cylinder || "",
      right: itemRight?.cylinder || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_AXIS",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.axis || "",
      right: itemRight?.axis || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_ADDITION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.add || "",
      right: itemRight?.add || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.prism || "",
      right: itemRight?.prism || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRISM_DIRECTION",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.direction || "",
      right: itemRight?.direction || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIOPTRES_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.prismUD || "",
      right: itemRight?.prismUD || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PRIMS_DIRECTION_2",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.directionUD || "",
      right: itemRight?.directionUD || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PD",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.pd || "",
      right: itemRight?.pd || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_HEIGHT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.ocHt || itemLeft?.mountingHeight || "",
      right: itemRight?.ocHt || itemRight?.mountingHeight || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_VERTEX_FITTED",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.vertexFitted || "",
      right: itemRight?.vertexFitted || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_FACEFORM_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.faceformTilt || "",
      right: itemRight?.faceformTilt || "",
    },
    {
      parameterName: "ESSILOR_PRESCRIPTION_PANTO_TILT",
      prescriptionParameterTranslatedName: "",
      isRequired: false,
      minValue: "",
      maxValue: "",
      stepBetweenMinAndMax: "0.1",
      formatRegex: "",
      left: itemLeft?.pantoTilt || "",
      right: itemRight?.pantoTilt || "",
    },
  ];
};

export const getIsEssilorBrand = (brand: string): boolean => {
  const brandList = ["EL", "VX", "ST", "EY", "TRS", "CRZ", "XPR", "WH", "RI", "HP", "NI", "GW"];
  return brandList.includes(brand);
};

export const isEssilorWithoutSpecialParams = (
  specialParams: OrderDetailsPrescriptionAdditionalParams
): boolean => {
  return (
    !specialParams?.cape &&
    !specialParams?.ercdLeft &&
    !specialParams?.ercdRight &&
    !specialParams?.eyecode &&
    !specialParams?.initials &&
    !specialParams?.leInset &&
    !specialParams?.reInset &&
    !specialParams?.progressLength &&
    !specialParams?.wrapAngle
  );
};
