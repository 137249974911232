import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useBreakpoint } from "../../assets/theme/breakpoint";

import { ReactComponent as Arrow } from "../../assets/icons/arrowhead-down-icon.svg";

type Props = {
  text: string;
  children: JSX.Element;
  disabled?: boolean;
  type?: "light" | "dark";
  tooltipFunctionality?: "onClick" | "onHover";
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void;
  width?: string;
};

const ChipWithTooltip = ({
  text,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  disabled = false,
  type = "light",
  tooltipFunctionality = "onHover",
  width = "65vw",
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoint();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const closeTooltip = () => setShowTooltip(false); // this can be triggered from outside if the children accept "closeTooltip" as prop and call it

  const [leftTooltip, setLeftTooltip] = useState<number>(0);

  useEffect(() => {
    const element = document.getElementById(text);
    if (element) setLeftTooltip(element.offsetLeft);
  }, [breakpoints["md"]]);

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (tooltipFunctionality === "onHover" && !disabled) {
      onMouseEnter?.(e);
      tooltipFunctionality === "onHover" && setShowTooltip(true);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (tooltipFunctionality === "onHover" && !disabled) {
      onMouseLeave?.(e);
      tooltipFunctionality === "onHover" && setShowTooltip(false);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (tooltipFunctionality === "onClick" && !disabled) {
      onClick?.(e);
      tooltipFunctionality === "onClick" && setShowTooltip((old) => !old);
    }
  };

  const handleOnClickOutside = () => {
    if (tooltipFunctionality === "onClick" && !disabled) {
      tooltipFunctionality === "onClick" && setShowTooltip(false);
    }
  };

  useOnClickOutside(ref, handleOnClickOutside);

  return (
    <StyledContainer
      ref={ref}
      id={text}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
    >
      <Chip
        className={clsx(
          "chip",
          // active && "active",
          // fixed && "fixed",
          type === "dark" && "chip-dark",
          // style === "bg-gray" && "bg-gray",
          // letterCase === "uppercase" && "chip--uppercase",
          // letterCase === "capitalize" && "chip--capitalize",
          // size === "sm" && "chip-sm",
          // hasMargin && "has-margin",
          disabled && "disabled"
        )}
        onClick={handleOnClick}
      >
        <StyledText>{text}</StyledText>
        <StyledIcon>
          <Arrow />
        </StyledIcon>
      </Chip>
      {showTooltip && (
        <Tooltip className="tooltip-children" leftTooltip={leftTooltip} width={width}>
          {React.cloneElement(children, { closeTooltip })}
        </Tooltip>
      )}
    </StyledContainer>
  );
};

ChipWithTooltip.displayName = "ChipWithTooltip";

const StyledContainer = styled.div<{ disabled: boolean }>`
  display: inline-block;
  position: relative;
  padding-bottom: 0.75rem;

  /* ${(props) =>
    !props.disabled &&
    `&:hover {
    .tooltip-children {
      visibility: visible;
    }
    .chip {
      border: solid 0.1rem ${props.theme.palette.primary};
    }
  }`} */
`;

const Tooltip = styled.div<{ leftTooltip: any; width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* visibility: hidden; */
  min-width: 16.75rem;
  min-height: 3.375rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -${(props) => props.leftTooltip}px;
  box-shadow: 0 0 64px 0 rgba(3, 20, 52, 0.16);
  padding: 2.375rem;
  box-sizing: border-box;

  width: ${(props) => props.width};

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8.27px;
    left: calc(${(props) => props.leftTooltip}px + 4rem);
    z-index: 300;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(45deg);
    background-color: ${(props) => props.theme.palette.white};
    height: 1.125rem;
    width: 1.125rem;
  }
`;

const Chip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.40625rem;
  border: solid 0.1rem ${(props) => props.theme.palette.gray.medium};
  padding: 0.875rem 1.125rem;
  cursor: pointer;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-left: 0.5rem;

  &.chip-dark {
    border-color: ${(props) => props.theme.palette.bordersBlue};
    background-color: transparent;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    &:not(.chip-dark):hover {
      border-color: ${(props) => props.theme.palette.gray.medium};
    }
  }
`;

const StyledText = styled.p`
  color: ${(props) => props.theme.palette.primary};
  text-overflow: ellipsis;
  max-width: 22.5rem;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledIcon = styled.span`
  margin-left: 0.625rem;
  width: 1rem;
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }
`;

export default ChipWithTooltip;
