import React from "react";
import styled from "styled-components";
import CustomText from "../../CustomText";
import LoaderElement from "../LoaderElement";

export const LoaderMostUsedCatalogue = (): JSX.Element => {
  return (
    <ChipTooltipContainer>
      <LoaderElement height={46} width={150} radius={20} style="default" />
      <LoaderElement height={46} width={155} radius={20} style="default" />
      <LoaderElement height={46} width={220} radius={20} style="default" />
      <LoaderElement height={46} width={230} radius={20} style="default" />
      <LoaderElement height={46} width={420} radius={20} style="default" />
      <LoaderElement height={46} width={115} radius={20} style="default" />
    </ChipTooltipContainer>
  );
};

export const LoadeMostUsedServicesMyAccount = (): JSX.Element => {
  return (
    <ChipTooltipContainer>
      <LoaderElement height={46} width={120} radius={20} style="default" />
      <LoaderElement height={46} width={170} radius={20} style="default" />
      <LoaderElement height={46} width={180} radius={20} style="default" />
      <LoaderElement height={46} width={200} radius={20} style="default" />
      <LoaderElement height={46} width={220} radius={20} style="default" />
      <LoaderElement height={46} width={260} radius={20} style="default" />
      <LoaderElement height={46} width={180} radius={20} style="default" />
      <LoaderElement height={46} width={120} radius={20} style="default" />
      <LoaderElement height={46} width={140} radius={20} style="default" />
      <LoaderElement height={46} width={210} radius={20} style="default" />
      <LoaderElement height={46} width={150} radius={20} style="default" />
      <LoaderElement height={46} width={170} radius={20} style="default" />
    </ChipTooltipContainer>
  );
};

const LoaderCategoriesAndServices = (): JSX.Element => {
  return (
    <>
      <CategorySection>
        <CustomText as="span" font="font-bold" fontSizePx={18} marginBottomPx={24}>
          Categories: (to be translated)
        </CustomText>
        <LoaderMostUsedCatalogue />
      </CategorySection>
      <CategorySection>
        <CustomText as="span" font="font-bold" fontSizePx={18} marginBottomPx={24}>
          Services and My Account: (to be translated)
        </CustomText>
        <LoadeMostUsedServicesMyAccount />
      </CategorySection>
    </>
  );
};

export default LoaderCategoriesAndServices;

const CategorySection = styled.div`
  padding-bottom: 2.5rem;
`;

const ChipTooltipContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;
