import React from "react";
import styled from "styled-components/macro";
import CustomText from "../../components/styled-UI/CustomText";
import IconButton from "../../components/styled-UI/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import clsx from "clsx";

interface Props {
  isOpen: boolean;
  close: () => void;
  children: any;
  title?: string;
  isCentered?: boolean;
  top?: number;
  topPercent?: string;
  left?: number;
  customStyle?:
    | "expanded-tile-modal"
    | "expanded-tile-modal-sm"
    | "warranty-expanded-tile"
    | "payment-policy-document-fullscreen-modal";
  "data-element-id"?: string;
  zIndex?: number;
}

const Modal = ({
  close,
  title,
  isOpen,
  children,
  isCentered = true,
  top,
  topPercent,
  left,
  customStyle,
  zIndex = 1000,
  "data-element-id": dataElementId,
}: Props): JSX.Element => {
  return (
    <>
      {isOpen && (
        <>
          <BackgroundLayer onClick={close} zIndex={zIndex}></BackgroundLayer>
          <Container
            className={clsx(
              "modal",
              isCentered && "modal--centered",
              customStyle === "expanded-tile-modal" && "modal--expanded-tile",
              customStyle === "expanded-tile-modal-sm" && "modal--expanded-tile-sm",
              customStyle === "warranty-expanded-tile" && "modal--warranty-expanded-tile",
              customStyle === "payment-policy-document-fullscreen-modal" &&
                "modal--payment-policy-document-fullscreen"
            )}
            top={top}
            topPercent={topPercent}
            left={left}
            zIndex={zIndex}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          >
            {title && (
              <div className="modal-header">
                <div className="modal-header-text-wrapper">
                  <CustomText as="h1" marginBottomPx={24} fontSizePx={32} lineHeightPx={32}>
                    {title}
                  </CustomText>
                </div>
              </div>
            )}
            <div className="modal-content-container">
              {customStyle !== "warranty-expanded-tile" && (
                <div className="icon-container">
                  <IconButton
                    size="lg"
                    type="white-no-border"
                    icon={<CloseIcon />}
                    onClick={close}
                  />
                </div>
              )}
              <div className="modal-content">{children}</div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

const BackgroundLayer = styled.div<{ zIndex: number }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.82);
  z-index: ${(props) => props.zIndex};
  padding-bottom: 2rem;
`;

const Container = styled.div<{
  top: number | undefined;
  left: number | undefined;
  zIndex: number;
  topPercent?: string;
}>`
  position: absolute;
  max-width: calc(100vw - 5.5rem);
  min-width: 20vw;
  top: ${(props) =>
    props.topPercent
      ? props.topPercent
      : props.top || props.top === 0
      ? `${props.top / props.theme.baseFontSize}rem`
      : "6.25rem"};
  left: ${(props) =>
    props.left || props.left === 0 ? `${props.left / props.theme.baseFontSize}rem` : "50%"};
  border-radius: 0.25rem;
  transform: translate(-50%, 0);
  background-color: ${(props) => props.theme.palette.white};
  z-index: ${(props) => props.zIndex};
  padding: 3rem;

  &.modal--centered {
    transform: translate(-50%, -50%);
    top: 50%;
  }

  &.modal--expanded-tile {
    padding: 0;
    width: 83rem;
    max-width: inherit;
    left: 51%;
  }

  &.modal--expanded-tile-sm {
    padding: 0;
    width: 61.25rem;
    max-width: inherit;
    left: 50%;
  }

  &.modal--warranty-expanded-tile {
    padding: 0;
    width: 20.31rem;
    max-width: inherit;
    min-width: unset;
  }

  &.modal--payment-policy-document-fullscreen {
    padding: 0;
    width: 83rem;
    left: 50%;
  }

  .modal-header-text-wrapper {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: ${(props) => props.theme.palette.primary};
  }

  .icon-container {
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    z-index: 202;
  }

  .modal-content-container {
    max-width: calc(100vw - 1.25rem);
  }

  .modal-content {
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    line-break: anywhere; */
  }
`;

export default Modal;
