import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { getPagePath } from "../../../../routing/routesUtils";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import { selectGetMenuStatus } from "../../../../store/store/storeSlice";
import { getMostUsedContent } from "../../../../store/user/userSagas";
import { selectMostUsedContent, selectMostUsedLoading } from "../../../../store/user/userSlice";
import useNavbarSectionLinkHandler from "../../../layouts/header/navbar/useNavbarSectionLinkHandler";
import Chip from "../../../styled-UI/Chip";
import CustomText from "../../../styled-UI/CustomText";
import IconButton from "../../../styled-UI/IconButton";
import LoaderHomepageMostUsed from "../../../styled-UI/loader/most-used/LoaderHomepageMostUsed";
import CMSMostUsed from "./CMSMostUsed";
import CustomizeHomeModal from "./CustomizeHomeModal";
import useSelectedMostUsed, { ChipsHandler } from "./useSelectedMostUsed";

import { ReactComponent as SettingsIcon } from "../../../../assets/icons/edit-icon.svg";
import LinkButton from "../../../styled-UI/LinkButton";

const PersonalizedChips = ({ chipsHandler }: { chipsHandler: ChipsHandler }): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();
  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();

  const handleRedirect = (chip: MostUsedChipLocal) => {
    if (chip.type === "Services&MyAccount")
      handleNavbarSectionLinkClick({
        identifier: chip.name,
        title: chip.label,
        url: chip.url,
        routes: [],
      });
    else history.push(getPagePath(chip.url));

    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  if (chipsHandler.selectedChips.length === 0) return <></>;
  return (
    <>
      {chipsHandler.selectedChips.map((chip) => (
        <Chip
          letterCase="uppercase"
          key={chip.name + chip.label}
          text={translateLabel(chip.label)}
          onClick={() => handleRedirect(chip)}
          hideIcon={true}
          noMaxWidth // maxWidth={"12rem"}
          showTitle
        />
      ))}
    </>
  );
};

const MostUsed = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const [customizeOpen, setCustomizeOpen] = useState<boolean>(false);

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// PERSONAL MOST USED //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const chipsHandler = useSelectedMostUsed();

  const mostUsedData = useSelector(selectMostUsedContent);
  const mostUsedLoading = useSelector(selectMostUsedLoading);
  const getMenuStatus = useSelector(selectGetMenuStatus);

  const isLoading =
    mostUsedLoading.get === "LOADING" ||
    mostUsedLoading.update === "LOADING" ||
    getMenuStatus === "LOADING" ||
    chipsHandler.isSelectionLoading;

  useEffect(() => {
    dispatch(getMostUsedContent());
  }, []);

  const renderPersonalMostUsed = () => {
    if (isLoading) return <LoaderHomepageMostUsed />;
    if (chipsHandler.selectedChips.length === 0)
      return (
        <CustomText fontSizePx={16} as="span" color="primary" lineHeightPx={16} wordBreak="normal">
          {translateLabel("MOST_USED_NO_CONFIGURATION")}
        </CustomText>
      );

    return <PersonalizedChips chipsHandler={chipsHandler} />;
  };

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// RENDER ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const showPersonalMostUsed = !mostUsedData.unused;

  return (
    <>
      <SectionContainer data-element-id="MostUsed">
        <Section>
          <SectionContent>
            <TextContainer>
              <CustomText
                fontSizePx={30}
                as="span"
                color="primary"
                lineHeightPx={32}
                wordBreak="normal"
              >
                {translateLabel("MOST_USED")}
              </CustomText>
            </TextContainer>
            <Chips data-element-id="MostUsed_Tag">
              {showPersonalMostUsed ? (
                renderPersonalMostUsed()
              ) : (
                <CMSMostUsed
                  // hasPreselectedChips={chipsHandler.selectChip.length > 0}
                  preselectedChips={<PersonalizedChips chipsHandler={chipsHandler} />}
                />
              )}
            </Chips>
          </SectionContent>
          <Settings data-element-id="MostUsed_Settings">
            <IconButton
              icon={<SettingsIcon />}
              onClick={() => setCustomizeOpen(true)}
              disabled={!mostUsedData.canEdit}
              type={"no-background-primary"}
              size={"lg"}
            />
            <LinkButton
              disabled={!mostUsedData.canEdit}
              onClick={() => setCustomizeOpen(true)}
              underline
            >
              {translateLabel("MOST_USED_EDIT_SETTINGS_LABEL")}
            </LinkButton>
          </Settings>
        </Section>
      </SectionContainer>
      {customizeOpen && (
        <CustomizeHomeModal isOpen={customizeOpen} close={() => setCustomizeOpen(false)} />
      )}
    </>
  );
};

const SectionContainer = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  margin: 0 auto 0;
  position: relative;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;

  @media ${(props) => props.theme.queries.md} {
    max-width: ${(props) => props.theme.breakpoints.md};
    margin: 0 1rem;
  }

  @media ${(props) => props.theme.queries.sm} {
    max-width: ${(props) => props.theme.breakpoints.sm};
  }
`;

const SectionContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  flex-grow: 1;
`;

const Chips = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  gap: 0.5rem;

  @media ${(props) => props.theme.queries.md} {
    margin-left: 2rem;
  }
`;

const TextContainer = styled.div`
  min-width: 12rem;
  max-width: 18rem;
`;

const Settings = styled.div`
  border-left: 1px solid ${(props) => props.theme.palette.gray.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 6rem;
  flex-direction: column;
  padding: 0 0 0 0.9375rem;
  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 2rem;
  }
  button {
    width: max-content;
  }
`;

export default MostUsed;

export const MemoizedMostUsed = React.memo(MostUsed);
